/* eslint-disable guard-for-in */
/* eslint-disable quotes */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, { useEffect, useState, useRef } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Dimensions,
  Modal,
} from 'react-native';
import { TextInput, HelperText, Checkbox } from 'react-native-paper';
import {
  en,
  registerTranslation,
} from 'react-native-paper-dates';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
} from '@mui/material';
import RSTInputs1380 from '../../components/Creating1380/RSTInputs1380';
import AFTPInputs1380 from '../../components/Creating1380/AFTPInputs1380';
import EBDLInputs1380 from '../../components/Creating1380/EBDLInputs1380';
import OtherInputs1380 from '../../components/Creating1380/OtherInputs1380';
import AFTPTrainingPeriod from '../../components/Creating1380/AFTPTrainingPeriod.js';
import { useScale } from '../../components/scale';
import { useColorContext } from '../../components/ColorContext';
import moment from 'moment';
import { useTheme, styled } from '@mui/material/styles';
import { useSnackbar } from '../../components/SnackbarContext';
import FormHelperText from '@mui/material/FormHelperText';
import CustomRadioWithBackground from '../../components/CustomRadioWithBackground';
import {
  upload_file,
  update_file,
  get_single_file,
  get_aftp_information,
} from '../../api_handler/files';
import { create_notification } from '../../api_handler/notifications';
import {
  get_all_users_in_single_unit,
  get_units_for_1380,
} from '../../api_handler/units';
import * as form_1380 from '../../PDFs/1380_new_raw.js';
import * as form_aftp from '../../PDFs/1380_aftp_new.js';
import { pdfIn, decodeBase64, generate_rst, generate_dental, generate_ata, generate_ebdl, generate_mdr, generate_1380, generate_1380_aftp } from '../generatePDF';
import Scrollbars from 'react-custom-scrollbars';
import * as Progress from 'react-native-progress';
registerTranslation('en', en);
import TextField from '@mui/material/TextField';
import ViewShot from 'react-native-view-shot';
import { captureRef } from 'react-native-view-shot';
import { create_new_user_signature } from '../../api_handler/users.js';
import { put_image_s3, get_image_s3, put_s3 } from '../../api_handler/files';
import { storeJsonData } from '../../api_handler/asyncstorage.js';
import AsyncStorage from '@react-native-async-storage/async-storage';

import dayjs from "dayjs";
// import SnackbarAlert from "../../components/SnackbarAlert.js";
import CustomAutocomplete from "../../components/AutocompleteDropdown.js";


function New1380({ route, navigation }) {
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  const scale = useScale();
  const styles = StyleSheet.create({
    container: {
      height: '100%',
      width: '100%',
      backgroundColor: colors.screen_background,
    },
    wrapper: {
      flexDirection: 'row',
      height: '100%',
      width: '100%',
    },
    header: {
      backgroundColor: colors.screen_background,
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingTop: 25,
      fontWeight: 'bold',
    },
    description: {
      fontSize: 18,
      marginHorizontal: 5,
      textAlign: 'Left',
      paddingTop: 25,
      color: colors.bow,
      width: '50%',
      fontFamily: 'Trebuchet MS',
      marginBottom: '2%',
    },
    modal: {
      margin: 20,
      marginTop: '10%',
      backgroundColor: colors.modal_background,
      borderRadius: 20,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 10,
      paddingBottom: 10,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      width: '50%',
      justifyContent: 'center',
      height: 'fit-content',
      marginLeft: '20%',
    },
    modalText: {
      color: colors.text,
      width: '80%',
      fontSize: scale(20),
      fontWeight: 'bold',
      textAlign: 'center',
    },
    signText: {
      fontWeight: 'bold',
      fontSize: scale(14),
      textAlign: 'center',
      margin: '1%',
      paddingLeft: '5%',
      paddingRight: '5%',
      color: colors.bow,
      alignSelf: 'center',
    },
    button: {
      color: colors.text,
      width: '13%',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.grey,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      textAlign: 'center',
      alignItems: 'center',
    },
    cancelButton: {
      color: colors.text,
      width: '13%',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.modal_background,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: 'center',
    },
    signDocButton: {
      color: colors.text,
      width: '13%',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.action_button,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      textAlign: 'center',
      alignItems: 'center',
      marginTop: '1%',
    },
    buttonWider: {
      color: colors.text,
      width: '25%',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.grey,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      textAlign: 'center',
      alignItems: 'center',
    },
    cancelButtonWider: {
      color: colors.text,
      width: '25%',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.modal_background,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: 'center',
    },
    signDocButtonWider: {
      color: colors.text,
      width: '25%',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.action_button,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      textAlign: 'center',
      alignItems: 'center',
      marginTop: '1%',
    },
    textStyle: {
      justifyContent: 'center',
      fontSize: 15,
      fontWeight: 'bold',
      color: colors.text,
    },
    textStyleNormal: {
      justifyContent: 'center',
      fontSize: 15,
      color: colors.text,
    },
    dutyDropDownContainer: {
      marginVertical: '1%',
      width: '95%',
      marginTop: '1.5%',
      minHeight: 78,
      justifyContent: 'center',
    },
    dutyDropDownStyle: {
      borderColor: colors.border_color,
      borderWidth: 1,
      backgroundColor: colors.textfield_background,
      justifyContent: 'center',
    },
    dropDownStyle: {
      borderColor: colors.border_color,
      borderWidth: 1,
      marginHorizontal: 12,
      marginVertical: 6,
      width: '95%',
      backgroundColor: colors.textfield_background,
      justifyContent: 'center',
    },
    dropDownMenu: {
      backgroundColor: colors.bow,
    },
    commentInput: {
      minHeight: 'fit-content',
      borderColor: colors.border_color,
      borderWidth: 1,
      padding: 3,
      borderRadius: 1,
      fontSize: 16,
      backgroundColor: colors.textfield_background,
    },
    contentContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: 1,
    },
    checkboxContainer: {
      flexDirection: 'row',
      fontSize: 18,
      alignItems: 'center',
      flex: 1,
    },
    entryBox: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: 5,
      paddingLeft: 5,
      paddingRight: 5,
      width: '95%',
      maxWidth: '700px',
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    selectProps: {
      MenuProps: {
        sx: {
          '& .MuiMenu-paper': {
            background: colors.textfield_background,
            color: colors.inputted_text,
          },
        },
      },
    },
    reviewerDropdown: {
      backgroundColor: colors.textfield_background,
      color: colors.inputted_text,
      borderColor: colors.border_color,
      borderWidth: 1,
      marginTop: -15,
      marginBottom: -15,
      width: '100%',
      justifyContent: 'left',
      padding: '12px',
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.border_color,
        },
        '&:hover fieldset': {
          borderColor: colors.border_color,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.border_color,
        },
      },
      '& .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
    },
    textInputPropsDisabled: {
      '& .MuiFormLabel-root.Mui-disabled, & label.Mui-focused.Mui-disabled, & .MuiInputBase-input.Mui-disabled': {
        color: colors.disabled_text,
        WebkitTextFillColor: colors.disabled_text,
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: colors.disabled_text,
        },
      },
      '& .MuiFormHelperText-root.Mui-disabled': {
        backgroundColor: colors.screen_background,
        color: colors.placeholder_text,
        margin: 0,
        paddingLeft: 1,
      },
    },
    fileContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
    },
    tableDiv: {
      width: '95%',
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      backgroundColor: colors.modal_background,
    },
    rowContainer: {
      flexDirection: 'row',
      marginTop: 15,
      marginBottom: 10,
      justifyContent: 'center',
    },
    columnContainer: {
      flexDirection: 'column',
      marginTop: 15,
      marginBottom: 10,
      alignItems: 'center',
    },
  });
  const [loaded, setLoaded] = useState(false);
  const [commanders, setCommanders] = useState([]);
  const [unitList, setUnitList] = useState([
    { name: 'No Units', unit_id: '0' },
  ]);
  const [reviewer, setReviewer] = useState('');
  const [reviewerIds, setReviewerIds] = useState([])
  const [validatorIds, setValidatorIds] = useState([]);
  const [reviewerEmptyError, setReviewerEmptyError] = useState(false);

  const [validator, setValidator] = useState('');
  const [validatorEmptyError, setValidatorEmptyError] = useState(false);
  const [validatorSigId, setValidatorSigId] = useState(null);
  const [showValidatorSig, setShowValidatorSig] = useState(false);
  const [validatorOption, setValidatorOption] = useState(route.params?.validatorOption ?? []);

  const [validatorSelectedOption, setValidatorSelectedOption] = useState({ user_id: 0, unit_id: 0, name: '' });
  const [reviewerSelectedOption, setReviewerSelectedOption] = useState({ user_id: 0, unit_id: 0, name: '' })

  const [resetSearchQuery, setResetSearchQuery] = useState(false);

  const [hours, setHours] = useState('4');
  const [hoursEmptyError, setHoursEmptyError] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedTypeEmptyError, setSelectedTypeEmptyError] = useState(false);
  const [mutaMatch, setMutaMatch] = useState(true);
  const [rstMUTA, setRSTMUTA] = useState([]);
  const [pointsOnly, setPointsOnly] = useState(false);
  const [datePicker1Open, setDatePicker1Open] = useState(false);
  const [datePicker2Open, setDatePicker2Open] = useState(false);
  const [datePicker3Open, setDatePicker3Open] = useState(false);
  const [timePicker1Open, setTimePicker1Open] = useState(false);
  const [timePicker2Open, setTimePicker2Open] = useState(false);
  const [timePicker3Open, setTimePicker3Open] = useState(false);
  const [timePicker4Open, setTimePicker4Open] = useState(false);
  const [viewSig, setViewSig] = useState(false);
  const [eventDate, setEventDate] = useState(null);
  const [eventDateEmptyError, setEventDateEmptyError] = useState(false);
  const [eventDateEmptyError2, setEventDateEmptyError2] = useState(false);
  const [eventDate3, setEventDate3] = useState(null);
  const [eventDateEmptyError3, setEventDateEmptyError3] = useState(false);
  const [eventDateEmptyErrorText, setEventDateEmptyErrorText] = useState(' ');
  const [ebdlDates, setEbdlDates] = useState([]);
  const [formattedEventDate, setFormattedEventDate] = useState(null);
  const [iloDate, setIloDate] = useState('');
  const [selectedOtherType, setSelectedOtherType] = useState('');
  const [selectedOtherTypeEmptyError, setSelectedOtherTypeEmptyError] = useState(false);
  const [locked, setLocked] = useState(true);
  const [events, setEvents] = useState([]);
  const [eventsEmptyError, setEventsEmptyError] = useState(false);
  const [points, setPoints] = useState('P1');
  const [pointsEmptyError, setPointsEmptyError] = useState(false);
  const [duties, setDuties] = useState('');
  const [dutiesEmptyError, setDutiesEmptyError] = useState(false);
  const [entryExists, setEntryExists] = useState(false);
  const [checkbox4H, setCheckbox4H] = useState(true);
  const [checkbox8H, setCheckbox8H] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileSelectModal, setFileSelectModal] = useState(false);
  const [failedToRetrieve, setFailedToRetrieve] = useState(false);
  const [pulled, setPulled] = useState(false);
  const [curFileNum, setCurFileNum] = useState(0);
  const [modifying, setModifying] = useState(false);
  const [modifyingFirstEntry, setModifyingFirstEntry] = useState(false);
  const [modifyingID, setModifyingID] = useState(-1);
  const [modifyingDoc, setModifyingDoc] = useState(false);
  const [pulledInfo, setPulledInfo] = useState({});
  const [selected, setSelected] = useState(-1);
  const [reviewerPermission, setReviewerPermission] = useState(29);
  const [addedFormIDs, setAddedFormIDs] = useState([]);

  // If the user can see the HDIP/ACIP checkbox, this way it's checked by default. If not, it's false and will remain false.
  const userHasIncentivePay = global.user_data.incentive_pay !== 'None';
  const [HDIP_ACIP_Authorized, setHDIP_ACIP_Authorized] = useState(false);

  const [AFTPMUTAs, setAFTPMUTAs] = useState(1);
  const [AFTPMUTAsError, setAFTPMUTAsError] = useState(false);
  const [AFTPNumber, setAFTPNumber] = useState('');
  const [AFTPNumberEmptyError, setAFTPNumberEmptyError] = useState(false);
  const [supportNumber, setSupportNumber] = useState('');
  const [supportNumberError, setSupportNumberError] = useState(false);
  const [tailNumber, setTailNumber] = useState('');
  const [tailNumberError, setTailNumberError] = useState(false);
  const [tailNumberErrorText, setTailNumberErrorText] = useState(' ');
  const [FLTHRS, setFLTHRS] = useState('');
  const [FLTHRSError, setFLTHRSError] = useState(false);
  const [FLTHRSErrorText, setFLTHRSErrorText] = useState('');
  const [typeOfTrainingCompletedValue, setTypeOfTrainingCompletedValue] =
    useState('');
  const [typeOfTrainingCompletedValueEmptyError, setTypeOfTrainingCompletedValueEmptyError] =
    useState(false);
  const [typeOfTrainingCompletedOther, setTypeOfTrainingCompletedOther] =
    useState('');
  const [typeOfTrainingCompletedOtherEmptyError, setTypeOfTrainingCompletedOtherEmptyError] =
    useState(false);
  const [groundTrainingDescription, setGroundTrainingDescription] = useState('');
  const [groundTrainingDescription2, setGroundTrainingDescription2] = useState('');
  const [datePerformedAFTP, setDatePerformedAFTP] = useState(
    moment(new Date()).format('MM/DD/YYYY'),
  );
  const [datePerformedAFTP2, setDatePerformedAFTP2] = useState(
    moment(new Date()).format('MM/DD/YYYY'),
  );
  const [AFTPNumber2, setAFTPNumber2] = useState('');
  const [AFTPNumberEmptyError2, setAFTPNumberEmptyError2] = useState(false);
  const [supportNumber2, setSupportNumber2] = useState('');
  const [supportNumberError2, setSupportNumberError2] = useState(false);
  const [timeIn, setTimeIn] = useState('00:00');
  const [formattedTimeIn, setFormattedTimeIn] = useState(dayjs().startOf('day'));
  const [timeInEmptyError, setTimeInEmptyError] = useState(false);
  const [timeOut, setTimeOut] = useState('');
  const [formattedTimeOut, setFormattedTimeOut] = useState(dayjs().startOf('day'));
  const [timeOutEmptyError, setTimeOutEmptyError] = useState(false);
  const [timeOutErrorText, setTimeOutErrorText] = useState(' ');
  const [timeIn2, setTimeIn2] = useState('00:00');
  const [formattedTimeIn2, setFormattedTimeIn2] = useState(dayjs().startOf('day'));
  const [timeOut2, setTimeOut2] = useState();
  const [formattedTimeOut2, setFormattedTimeOut2] = useState(dayjs().startOf('day'));
  const [timeOutEmptyError2, setTimeOutEmptyError2] = useState(false);
  const [timeOutErrorText2, setTimeOutErrorText2] = useState(' ');
  const [userEditedTimeIn, setUserEditedTimeIn] = useState(false);
  const [userEditedTimeOut, setUserEditedTimeOut] = useState(false);
  const [timeInEmptyError2, setTimeInEmptyError2] = useState(false);
  const [userEditedTimeIn2, setUserEditedTimeIn2] = useState(false);
  const [userEditedTimeOut2, setUserEditedTimeOut2] = useState(false);
  const [tailNumber2, setTailNumber2] = useState('');
  const [tailNumberError2, setTailNumberError2] = useState(false);
  const [tailNumberErrorText2, setTailNumberErrorText2] = useState(' ');
  const [FLTHRS2, setFLTHRS2] = useState('');
  const [FLTHRSError2, setFLTHRSError2] = useState(false);
  const [FLTHRSErrorText2, setFLTHRSErrorText2] = useState('');
  const [typeOfTrainingCompletedValue2, setTypeOfTrainingCompletedValue2] =
    useState('');
  const [typeOfTrainingCompletedValueEmptyError2, setTypeOfTrainingCompletedValueEmptyError2] =
    useState(false);
  const [typeOfTrainingCompletedOther2, setTypeOfTrainingCompletedOther2] =
    useState('');
  const [typeOfTrainingCompletedOtherEmptyError2, setTypeOfTrainingCompletedOtherEmptyError2] =
    useState(false);
  const [isGround, setIsGround] = useState(false);
  const [isGround2, setIsGround2] = useState(false);
  const [currentAFTPNumber, setCurrentAFTPNumber] = useState(0);
  const [currentSupportNumber, setCurrentSupportNumber] = useState(0);
  const [bankTime, setBankTime] = useState(null);
  const theme = useTheme();
  const [doesUserHaveSignature, setDoesUserHaveSignature] = useState(false);
  const [signatureName, setSignatureName] = useState('');
  const viewRef = useRef();
  const selectedTypeRef = useRef(selectedType);
  const [isColumn, setIsColumn] = useState(false);
  const [buttonsColumn, setButtonsColumn] = useState(false);

  const { draftFormID, is1380AFTP, updatingSubmission } = route.params;
  const [pulledFromDraft, setPulledFromDraft] = useState(false);
  const [hideDraftButton, setHideDraftButton] = useState(false);

  useEffect(() => {
    console.log("uploadFiles", uploadFiles)
  }, [uploadFiles])

  useEffect(() => {
    if (selectedType === 'RST' || selectedType === '1380' || selectedType === 'aftp') {
      setHDIP_ACIP_Authorized(userHasIncentivePay)
    } else {
      setHDIP_ACIP_Authorized(false);
    }
  }, [selectedType])

  // aqui
  const handleAttachmentSelect = async (value) => {
    setFileSelectModal(false);
    const file = value.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const b64 = reader.result;

        const file_type = file.type.split('/')[1];
        const contentType = file.type.includes('jpg') || file.type.includes('jpeg') 
        ? 'image/jpeg' 
        : file.type.includes('png') 
        ? 'image/png' 
        : file.type.includes('pdf') 
        ? 'application/pdf' 
        : null;
        if (!contentType) {
          showSnackbar('error', 'Unsupported file type.');
          return;
        }

        const form_id = draftFormID ? draftFormID : 0
        const fileData = {
          form_id: curFileNum, 
          form_name: file.name,
          form_type: file_type,
          is_image: file.type.includes('image'),
          base64: b64,
        };


        // Define S3 path
        const path = `users/additional_documentation_1380s/${global.user_data.user_id}/${form_id}/${file.name}`;

        console.log("fileData", fileData);
        console.log("Base64:", b64);
        console.log("fileData", fileData);

        // Upload S3
        try {
          await put_s3(path, b64, contentType, global.token);
          console.log('File uploaded to S3 successfully');

          if (modifyingDoc) {
            const updated = [...uploadFiles];
            updated[modifyingID] = {
              form_id: modifyingID,
              form_name: fileData.form_name,
              form_type: fileData.form_type,
              is_image: fileData.is_image,
              base64: fileData.base64,
            }     
            setUploadFiles(updated);
          } else {
            setUploadFiles((previousFiles) => [...previousFiles, fileData]);
          }

          // Increment file number
          setCurFileNum(curFileNum + 1);
        } catch (error){
          showSnackbar('error', 'Failed to upload the file to S3.');
          console.error('S3 Upload Error:', error);
        }
      };
      reader.readAsDataURL(file);
    } else {
      showSnackbar('error', 'Application Error: Failed to retrieve the selected selection.');
    }
  };

  const handleYourFilesSelect = async () => {
    // nav to page with parameter called 'from1380'
    if (selectedType == '') {
      setFileSelectModal(false);
      navigation.navigate('RstTo1380', { form_id: draftFormID ?? 0 });
    } else {
      setFileSelectModal(false);
      navigation.push('YourFiles', {
        from1380: true,
        formType: selectedType,
        events: events,
        which1380Page: 'new',
        loadingDraft: false,
        addedFormIDs: addedFormIDs,
        uploadFiles: uploadFiles,
        draftFormID: draftFormID,
        modifyDoc: modifyingDoc,
        modifyingID: modifyingID,
        validatorOption: validatorOption,
        validatorIds: validatorIds,
      });
    }
  };

  const generateFromAttachment = async (file) => {
    setLoaded(false);
    let formIDandType;
    if (file.form_type === 'dental') {
      formIDandType = { form_id: file.dental_form_id, form_type: file.form_type };
    } else {
      formIDandType = { form_id: file.rst_form_id, form_type: file.form_type };
    }

    try {
      const fileData = await get_single_file(formIDandType, global.token);
      if (updatingSubmission == true) {
        // We are working with either an amendment to a submission or a recalled denial
        setViewSig(true);
      }

      if (file.form_type.toLowerCase() === "rst" && (Object.keys(fileData.body).length > 0)) {
        await generate_rst(fileData.body.form_id, fileData.body, route.navigation, false, false);
      } else if (file.form_type.toLowerCase() === "dental" && (Object.keys(fileData.body).length > 0)) {
        await generate_dental(fileData.body.form_id, fileData.body, route.navigation, false, false);
      } else if (file.form_type.toLowerCase() === "ebdl" && (Object.keys(fileData.body).length > 0)) {
        await generate_ebdl(fileData.body.form_id, fileData.body, route.navigation, false, false);
      } else if (file.form_type.toLowerCase() === "ata" && (Object.keys(fileData.body).length > 0)) {
        await generate_ata(fileData.body.form_id, fileData.body, route.navigation, false, false);
      } else if (file.form_type.toLowerCase() === "mdr" && (Object.keys(fileData.body).length > 0)) {
        await generate_mdr(fileData.body.form_id, fileData.body, route.navigation, false, false);
      } else if (file.is_image) {
        const pdfWindow = window.open("");
        pdfWindow.document.write("<img src='data:image/" + file.form_type + ";base64," + file.base64.split(',')[1] + "'/>");
      }
    } catch (error) {
      console.error('Error generating from attachment:', error);
    }

    setLoaded(true);
  };

  useEffect(() => {
    if (draftFormID != 'NONE' && draftFormID !== undefined) {
      loadDraftForm();
    }
  }, [draftFormID]);

  useEffect(() => {
    const handleResize = () => {
      setIsColumn(Dimensions.get('window').width <= 1350);
      setButtonsColumn(Dimensions.get('window').width <= 1250);
    };

    handleResize(); // Set initial value
    Dimensions.addEventListener('change', handleResize);

    return () => {
      Dimensions.removeEventListener('change', handleResize);
    };
  }, []);

  useEffect(() => {
    async function getReviewers() {
      if (selectedTypeRef.current === 'aftp') {
        setReviewerPermission(32);
        const userAFTPInfo = await get_aftp_information(
          { user_id: global.user_data.user_id },
          global.token,
        );
        if (userAFTPInfo.status === 200) {
          if (draftFormID == 'NONE' || draftFormID === undefined) {
            setAFTPNumber(
              (parseInt(userAFTPInfo.data.body.current_aftp_number) + 1).toString(),
            );
            setAFTPNumber2(
              (parseInt(userAFTPInfo.data.body.current_aftp_number) + 2).toString(),
            );
            setBankTime(userAFTPInfo.data.body.bank_time);
            setCurrentAFTPNumber(userAFTPInfo.data.body.current_aftp_number);
            setCurrentSupportNumber(userAFTPInfo.data.body.current_support_number);
          }
        } else {
          showSnackbar('error', 'Application Error: Failed to retrieve accounts AFTP Information');
          setFailedToRetrieve(true);
        }
      } else {
        setReviewerPermission(29);
      }

      // const id = global.user_data.unit_id;
      // if (!isNaN(id)) {
      //   let getAllOfficers = [];

      //   if (selectedType === 'aftp') {
      //     getAllOfficers = await get_all_users_in_single_unit({unit_id: id, permission: 32}, global.token);
      //   } else {
      //     getAllOfficers = await get_all_users_in_single_unit({unit_id: id, permission: 29}, global.token);
      //   }

      //   if (getAllOfficers.status === 200) {
      //     const filterOfficers = getAllOfficers.data.body.filter(
      //         (user) => user.user_id != global.user_data.user_id,
      //     );
      //     setReviewers(filterOfficers);
      //   } else {
      //     showSnackbar('error', 'Application Error: Failed to retrieve Review Officers');
      //     setReviewers([]);
      //   }
      // } else {
      //   setReviewers([]);
      // }
    }

    let type = selectedType === 'AFTP' || selectedType === 'OTHER' ? selectedType.toLowerCase() : selectedType;
    selectedTypeRef.current = type;
    setSelectedType(type);
    getReviewers();
  }, [selectedType]);

  const loadDraftEvents = (fileDates, fileHours, filePoints, fileDescriptions) => {
    const events = [];
    for (let i = 0; i < fileDates.length; i++) {
      events.push({
        id: i,
        date: new Date(fileDates[i]),
        formattedDate: fileDates[i],
        hours: fileHours[i],
        points: filePoints[i],
        duties: fileDescriptions[i],
      });
    }
    return events;
  };

  //aqui
  const setValidatorStates = (data, pulledValidator_ids) => {
    if(pulledValidator_ids === false){
      setValidatorOption([...validatorOption,
        {
          user_id: data.validator_id ? data.validator_id : data.validator_ids[0],
          unit_id: data.validator_unit_id,
          name: data.validator_rank + ' ' + data.validator_name + ' - ' + data.validator_unit_name,
          form_id: data.form_id,
          sig_id: data.validator_signature_id,
        }]
      )
    }
    else {
      const validatorOptionArray = data.map(validator => ({
        user_id: validator.reviewer_id ? validator.reviewer_id : validator.reviewer_ids[0],
        unit_id: validator.reviewer_unit_id,
        name: validator.reviewer_rank + ' ' + validator.reviewer_name + ' - ' + validator.reviewer_unit_name,
        form_id: validator.form_id,
        status: validator.status,
        sig_id: validator.reviewer_signature_id,
        selected: pulledValidator_ids.some(selected => selected === validator.reviewer_id) ? true : false,
      }));
      setValidatorOption(validatorOptionArray)
    }
  }

  // function to see if file is in the format of attached file in s3 bucket
  function checkForImage(fileName) {
    if(fileName.includes("png") || fileName.includes("pdf") || fileName.includes("jpg") || fileName.includes("jpeg"))
      return true;
    else 
      return false;
  }

  //AQUI
  async function loadDraftForm() {
    setLoaded(false);
    setPulledFromDraft(true);
    const formIDandType = { form_id: draftFormID, form_type: is1380AFTP ? '1380 aftp' : '1380' };
    try {
      const fileData = await get_single_file(formIDandType, global.token);
      setSelectedType(fileData.body.duty_performed_9.toUpperCase());

      if (fileData.body.duty_performed_9.toUpperCase() === 'RMA') {
        setSelectedType('ATA');
      }
      else if (fileData.body.duty_performed_9.toUpperCase() === 'OTHER') {
        setSelectedType('other')
      }
      setHDIP_ACIP_Authorized(fileData.body.hdip_acip_authorized_9);

      if (fileData.body.reviewer_ids.length > 0) {
        setReviewerIds(fileData.body.reviewer_ids)
        setReviewer(fileData.body.reviewer_ids);
      }

      setValidatorIds(fileData.body.validator_ids ? fileData.body.validator_ids : []);
      if (fileData.body.attachments.length > 0) {
        let validatorData = []
        for (let i = 0; i < fileData.body.attachments.length; i++) {
          // Only for attachments of files made within app (RST,EBDL,DENTAL,MDR,RMA,ATA)
          if(checkForImage(fileData.body.attachments[i]) === false){
            const attach = fileData.body.attachments[i];
            const formType = attach.split(' ')[0];
            const formId = attach.split(' ').filter(part => !isNaN(part))[0];

            const data = { form_id: formId, form_type: formType }
            const file = await get_single_file(data, global.token);
            validatorData.push(file.body);
          }
        }
        setValidatorStates(validatorData, fileData.body.validator_ids);
      }
      else {
        setValidatorStates(fileData.body, false)
      }


      switch (fileData.body.duty_performed_9.toUpperCase()) {
        case 'RST':
          setEvents(loadDraftEvents(fileData.body.date_9a, fileData.body.hours_9b, fileData.body.retirement_points_9c, fileData.body.description_9d));
          break;
        case 'AFTP':

          fileData.body.hours_9b.length > 1 ? handleCheckbox8H() : handleCheckbox4H();
          if (fileData.body?.date_9a[0]) {
            setEventDate(convertToMomentDate(fileData.body?.date_9a[0]));
            setDatePerformedAFTP(fileData.body?.date_9a[0])
          }

          if (fileData.body?.date_9a[1]) {
            setEventDate3(convertToMomentDate(fileData.body.date_9a[1]));
            setDatePerformedAFTP2(fileData.body?.date_9a[1])
          }

          if (fileData.body?.type_of_training_completed_9d[0]) {
            const firstTraining = fileData.body.type_of_training_completed_9d[0].split(": ");
            setTypeOfTrainingCompletedValue(firstTraining[0]);
            if (firstTraining[0] == 'Ground Training') {
              setGroundTrainingDescription(firstTraining[1]);
              setIsGround(true);
            } else if (firstTraining[0] == 'Flight Physical') {
              setIsGround(true);
            }
          }

          if (fileData.body?.type_of_training_completed_9d[1]) {
            const secondTraining = fileData.body.type_of_training_completed_9d[1].split(": ");
            setTypeOfTrainingCompletedValue2(secondTraining[0]);
            if (secondTraining[0] == 'Ground Training') {
              setGroundTrainingDescription2(secondTraining[1]);
              setIsGround2(true);
            } else if (secondTraining[0] == 'Flight Physical') {
              setIsGround2(true);
            }
          }

          if (fileData.body?.time_in_9d[0]) {
            setTimeIn(fileData.body.time_in_9d[0]);
            setFormattedTimeIn(convertToMomentTime(fileData.body.time_in_9d[0]));
          }

          if (fileData.body?.time_in_9d[1]) {
            setTimeIn2(fileData.body.time_in_9d[1]);
            setFormattedTimeIn2(convertToMomentTime(fileData.body.time_in_9d[1]));
          }

          if (fileData.body?.time_out_9d[0]) {
            setTimeOut(fileData.body.time_out_9d[0]);
            setFormattedTimeOut(convertToMomentTime(fileData.body.time_out_9d[0]));
          }

          if (fileData.body?.time_out_9d[1]) {
            setTimeOut2(fileData.body.time_out_9d[1]);
            setFormattedTimeOut2(convertToMomentTime(fileData.body.time_out_9d[1]));
          }

          // AFTP numbers aftp_number_9d
          if (fileData.body?.aftp_number_9d[0]) {
            setAFTPNumber(fileData.body.aftp_number_9d[0]);
          }

          if (fileData.body?.aftp_number_9d[1]) {
            setAFTPNumber2(fileData.body.aftp_number_9d[1])
          };

          // Ground AFTP numbers support_number_9d
          if (fileData.body?.support_number_9d[0]) {
            setSupportNumber(fileData.body.support_number_9d[0]);
          }

          if (fileData.body?.support_number_9d[1]) {
            setSupportNumber2(fileData.body.support_number_9d[1]);
          }

          // Tail Numbers acft_9d
          if (fileData.body?.acft_9d[0]) {
            setTailNumber(fileData.body.acft_9d[0]);
          }

          if (fileData.body?.acft_9d[1]) {
            setTailNumber2(fileData.body.acft_9d[1]);
          }

          // Flight hours flt_hrs_9d
          if (fileData.body?.flt_hrs_9d[0]) {
            setFLTHRS(parseFloat(fileData.body.flt_hrs_9d[0].toString()));
          }

          if (fileData.body?.flt_hrs_9d[1]) {
            setFLTHRS2(parseFloat(fileData.body.flt_hrs_9d[1].toString()));
          }
          break;
        case 'EBDL':
          setEvents(loadDraftEvents(fileData.body.date_9a, fileData.body.hours_9b, fileData.body.retirement_points_9c, fileData.body.description_9d));
          break;
        case 'ATA':
          setEvents(loadDraftEvents(fileData.body.date_9a, fileData.body.hours_9b, fileData.body.retirement_points_9c, fileData.body.description_9d));
          break;
        case 'MDR':
          setEvents(loadDraftEvents(fileData.body.date_9a, fileData.body.hours_9b, fileData.body.retirement_points_9c, fileData.body.description_9d));
          break;
        default: // Other
          setEvents(loadDraftEvents(fileData.body.date_9a, fileData.body.hours_9b, fileData.body.retirement_points_9c, fileData.body.description_9d));
          const prefix = "DA 1380-";
          const requestType = fileData.body.request_type;
          setSelectedOtherType(requestType.startsWith(prefix) ? requestType.substring(prefix.length).toLowerCase() : null);
          break;
      }

      console.log(fileData.body.attachments)
      if (fileData.body.attachments && fileData.body.attachments.length > 0) {
        const newUploadFiles = [];

        let index = 0;
        for (const currentAttachment of fileData.body.attachments) {
          if(checkForImage(currentAttachment)){
            // currentAttachment is an outside image or file
            const form_type = currentAttachment.includes("pdf") ? "pdf" : currentAttachment.includes("png") ? "png" : "jpeg";
            console.log(form_type)
            const path = 'additional_documentation_1380s/' + global.user_data.user_id + '/' + draftFormID + '/' + currentAttachment;
            const content_type = currentAttachment.includes("pdf") ? `application/${form_type}` : `image/${form_type}`;
            const base64 = decodeBase64(await get_image_s3(path, content_type, global.token)).split(',').pop();
            console.log("base64", base64)
            newUploadFiles.push({
              form_id: index,
              form_name: currentAttachment,
              form_type: form_type,
              is_image: false,
              base64: base64,
            });

            index++;
            setCurFileNum(index);

            console.log("path", path)
            console.log("content_type", content_type)
            console.log("base64", base64)
            console.log("newUploadFiles", newUploadFiles)
          } else {
            // currentAttachment format is like "form_type XXXX"
            const [formType, formId] = currentAttachment.split(' ');
            const formIdKey = formType === 'dental' ? 'dental_form_id' : 'rst_form_id';

            newUploadFiles.push({
              form_id: index,
              form_name: `Pulled ${formType} ${formId}`,
              form_type: formType,
              is_image: false,
              [formIdKey]: formId, // Use dental_form_id for 'dental', rst_form_id otherwise
            });
            addedFormIDs.push(parseInt(formId));
            
            index++;
            setCurFileNum(index);
          }
        }
        setUploadFiles(newUploadFiles);
      }

      setHideDraftButton(true);
    } catch (error) {
      console.error('Error loading draft form:', error);
    }
    setLoaded(true);
  };


  const checkEntries = () => {
    let check = true;

    if (selectedType === '') {
      setSelectedTypeEmptyError(true);
      showSnackbar('error', 'Please select a duty');
      return;
    }

    for (const event in events) {
      if (
        events[event].hours === '0' ||
        events[event].points === '0' ||
        events[event].duties === 'NEEDS TO BE FILLED OUT'
      ) {
        check = false;
        break;
      }
    }
    if (check || !viewSig) { // Only case we can't submit is if the form is signed but empty
      onSubmit();
    } else {
      showSnackbar('error', 'Please finish filling out all of your entries');
    }
  };

  function handleCheckbox4H() {
    if (!checkbox4H) {
      setHours('4');
      setPoints(pointsOnly ? 'N1' : 'P1');
      setCheckbox4H(true);
      setCheckbox8H(false);
      setAFTPMUTAs(1);
      setAFTPMUTAsError(false);
    }
  }

  function handleCheckbox8H() {
    if (!checkbox8H) {
      setHours('8');
      setPoints(pointsOnly ? 'N2' : 'P2');
      setCheckbox8H(true);
      setCheckbox4H(false);
      setAFTPMUTAs(2);
      setAFTPMUTAsError(false);
    }
  }

  function handleHDIP() {
    setHDIP_ACIP_Authorized(!HDIP_ACIP_Authorized);
  }

  useEffect(() => {
    if (
      selectedType === 'MDR' ||
      selectedType === 'ATA' ||
      selectedType === 'EBDL' ||
      selectedType === 'dental'
    ) {
      setHours(selectedType === 'dental' ? '1' : '4');
      setPoints(pointsOnly ? 'N1' : 'P1');
      setLocked(true);
    } else if (selectedType === 'RST' || selectedType === 'aftp') {
      setLocked(true);
    } else {
      setLocked(false);
    }
  }, [selectedType]);

  // UNUSED until we receive more direction about points for pay from Sponsors
  // function togglePointsOnly() {
  //   setPointsOnly(!pointsOnly);
  //   setPoints(points == 'N1' ? 'P1' : points == 'N2' ? 'P2' : points === 'P1' ? 'N1' : 'N2');
  // }

  const editEntryCheck = () => {
    let flag = true;
    const isInvalidDate = eventDate === null || !eventDate.isValid();
    if (selectedType === 'RST' && (duties === '' || isInvalidDate)) {
      showSnackbar('error', 'Please correct one or more invalid inputs');
      setDutiesEmptyError(true);
      setEventDateEmptyError(isInvalidDate);
      flag = false;
    } else if ((selectedType !== 'other' && selectedType !== 'RST') && (duties === '' || isInvalidDate)) {
      setEventDateEmptyError(isInvalidDate);
      setDutiesEmptyError(duties === '');
      showSnackbar('error', 'Please correct one or more invalid inputs');
      flag = false;
    } else if (selectedType === 'other' && (duties === '' || points === '' || isInvalidDate || duties === '')) {
      setHoursEmptyError(hours === '');
      setPointsEmptyError(points === '');
      setEventDateEmptyError(isInvalidDate);
      setDutiesEmptyError(duties === '');
      showSnackbar('error', 'Please correct one or more invalid inputs');
      flag = false;
    }

    return flag;
  };

  const modifyFirstEntryEvent = async () => {
    const isInvalidDate = eventDate === null || !eventDate.isValid();
    if (selectedType === 'RST' && (duties === '' || isInvalidDate)) {
      showSnackbar('error', 'Please correct one or more invalid inputs');
      setEventDateEmptyError(isInvalidDate);
      setDutiesEmptyError(true);
    } else if ((selectedType !== 'other' && selectedType !== 'RST') && (duties === '' || isInvalidDate)) {
      setEventDateEmptyError(isInvalidDate);
      setDutiesEmptyError(duties === '');
      showSnackbar('error', 'Please correct one or more invalid inputs');
    } else if (selectedType === 'other' && (duties === '' || points === '' || isInvalidDate || duties === '')) {
      setHoursEmptyError(hours === '');
      setPointsEmptyError(points === '');
      setEventDateEmptyError(isInvalidDate);
      setDutiesEmptyError(duties === '');
      showSnackbar('error', 'Please correct one or more invalid inputs');
    } else {
      events[0] = {
        id: selected,
        date: eventDate,
        formattedDate: formattedEventDate,
        hours: hours,
        points: points,
        duties: duties,
      };

      if (selectedType === 'EBDL') {
        setEbdlDates([...ebdlDates, formattedEventDate]);
      }

      setModifying(false);
      setModifyingFirstEntry(false);
      setEventDate(null);
      setFormattedEventDate('');
      setEventDateEmptyError(false);
      setEventDateEmptyErrorText(' ');
      if (selectedType === 'other') {
        setHours('');
        setPoints('');
      }
      setDuties('');
      setEntryExists(true);
      setSelected(-1);
    }
  };

  const addEvent = async () => {
    let isValid = true;
    if (selectedType === '') {
      isValid = false;
      setSelectedTypeEmptyError(true);
    }
    if (selectedType === 'RST') {
      if (AFTPMUTAs === null) {
        isValid = false;
        setAFTPMUTAsError(true);
      }
    } else {
      if (hours === '') {
        isValid = false;
        setHoursEmptyError(true);
      }
      if (points === '') {
        isValid = false;
        setPointsEmptyError(true);
      }
    }
    if (duties === '') {
      isValid = false;
      setDutiesEmptyError(true);
    }
    if (eventDate === null) {
      isValid = false;
      setEventDateEmptyError(true);
    }
    if (selectedType === 'other' && selectedOtherType === '') {
      isValid = false;
      setSelectedOtherTypeEmptyError(true);
    }

    if (selectedType === 'EBDL' && isValid) {
      const formattedDate = moment(new Date(eventDate)).format('MM/DD/YYYY');
      if (modifying && events[selected].formattedDate === formattedDate) {
        // Skip the duplicate date check if modifying and the date hasn't changed
      } else if (ebdlDates.includes(formattedDate)) {
        isValid = false;
        setEventDateEmptyError(true);
        setEventDateEmptyErrorText('Date has already been selected');
      } else {
        setEbdlDates([...ebdlDates, formattedDate]);
      }
    }

    if (!isValid) {
      showSnackbar('error', 'Please correct one or more invalid inputs');
    } else {
      if (modifying) {
        events[selected] = {
          id: selected,
          date: eventDate,
          formattedDate: formattedEventDate,
          hours: hours,
          points: points,
          duties: duties,
        };
        setModifying(false);
      } else {
        setEventsEmptyError(false);
        events.push({
          id: events.length,
          date: eventDate,
          formattedDate: formattedEventDate,
          hours: hours,
          points: points,
          duties: duties,
        });
      }
      setModifyingFirstEntry(false);
      setEventDate(null);
      setFormattedEventDate('');
      setEventDateEmptyError(false);
      setEventDateEmptyErrorText(' ');
      if (selectedType === 'other') {
        setHours('');
        setPoints('');
      }
      setDuties('');
      setEntryExists(true);
      setSelected(-1);
    }
  };

  function convertToMomentDate(dateString) {
    const month = dateString.substring(0, 2);
    const day = dateString.substring(3, 5);
    const year = dateString.substring(6, 10);
    // Was off by one when we did parseInt without the -1
    const momentDate = moment({ year: parseInt(year), month: parseInt(month) - 1, day: parseInt(day) });
    return momentDate;
  };

  function convertToMomentTime(timeString) {
    if (!timeString || typeof timeString !== 'string') {
      return dayjs().startOf('day');
    }
    const [hours, minutes] = timeString.split(':').map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
      return dayjs().startOf('day');
    }
    return dayjs().hour(hours).minute(minutes).second(0);
  }

  const grabMonthFromDate = async (selectedDate) => {
    const [month, day, year] = selectedDate.split('/');

    const fullMonthMap = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      '10': 'October',
      '11': 'November',
      '12': 'December',
    };

    return fullMonthMap[month];
  };

  const modifyDoc = (id) => {
    setModifyingID(id);
    setModifyingDoc(true);
    setFileSelectModal(true);
  };

  const resetValidatorStates = () => {
    setValidator('');
    setValidatorEmptyError(false);
    setValidatorSigId([]);
    setShowValidatorSig(false);
    setValidatorSelectedOption({ user_id: 0, unit_id: 0, name: '' });
    setValidatorOption([])
  }

  // let updatedUploadFiles = uploadFiles.filter((file) => file.form_name !== formName); 
  // console.log("formName: ", formName);
  // console.log("updatedUploadFiles", updatedUploadFiles);
  // setUploadFiles(updatedUploadFiles);
  // setCurFileNum(curFileNum - 1);

  const deleteDoc = (id, formName) => {
    const filteredFormList = uploadFiles.filter((form) => form.form_id !== id);
    const removedForm = uploadFiles.find((form) => form.form_id === id);
    const newFormList = filteredFormList.map((form) => {
      form.form_id > id ? form.form_id-- : form.form_id;
      return form;
    });

    console.log("removedForm", removedForm)
    if(!checkForImage(removedForm.form_type)){
      let actualFormId;
      if (removedForm.form_type == 'dental')
        actualFormId = Number(removedForm.dental_form_id);
      else
        actualFormId = Number(removedForm.rst_form_id);

      const filteredFormIDs = addedFormIDs.filter((idInList) => idInList !== actualFormId && idInList !== undefined);
      setAddedFormIDs(filteredFormIDs)
      setResetSearchQuery({ reset: true, form_ids: filteredFormIDs })

      if (filteredFormList.length < 1)
        resetValidatorStates();
    }

    setUploadFiles(newFormList);
    setCurFileNum(curFileNum - 1);
  };

  const modifyEntry = (entryID) => {
    const id = (entryID == -1) ? 0 : entryID;
    setEventDate(dayjs(events[id].date));
    setFormattedEventDate(events[id].formattedDate);
    setHours(events[id].hours);
    setPoints(events[id].points);
    setDuties(events[id].duties);
    if (selectedType !== 'other') {
      setCheckbox4H(events[id].hours == 4 ? true : false);
      setCheckbox8H(events[id].hours == 8 ? true : false);
    }
    setPointsOnly((events[id].points == 'N1' || events[id].points == 'N2'));
    setModifying(true);

    setHoursEmptyError(false);
    setHoursEmptyError(false);
    setEventDateEmptyError(false);
    setSelect(id);
  };

  const deleteEntry = (id) => {
    const deletedEvent = events.find((event) => event.id === id);

    const filteredEventList = events.filter((event) => event.id !== id);

    const newEventsList = filteredEventList.map((event) => {
      event.id > id ? event.id-- : event.id;
      return event;
    });

    if (deletedEvent) {
      const deletedDate = deletedEvent.formattedDate;
      const updatedEbdlDates = ebdlDates.filter((date) => date !== deletedDate);
      setEbdlDates(updatedEbdlDates);
    }

    setEvents(newEventsList);
    setEventDate(null);
    setDuties('');
  };

  const handleSignature = () => {
    setViewSig(!viewSig);
  };

  useEffect(() => {
    async function loadOfficers() {
      let filterCommanders = [];
      const getAllCommanders = await get_all_users_in_single_unit(
        { unit_id: global.user_data.unit_id, permission: 29 },
        global.token,
      );

      if (getAllCommanders.status === 200) {
        // Removes logged in user from commanders list
        filterCommanders = getAllCommanders.data.body.filter(
          (user) => user.user_id != global.user_data.user_id,
        );
        setCommanders(filterCommanders);
      } else {
        showSnackbar('error', 'Application Error: Failed to retrieve 1380 Reviewers');
        setCommanders([]);
      }

      if (filterCommanders.length === 0) {
        showSnackbar('error', 'Application Error: There are no 1380 Reviewers to choose from');
        setFailedToRetrieve(true);
      }

      setLoaded(true);
    }

    function checkSignature() {
      if (global.signature_uri == null || global.signature_uri === '') {
        setDoesUserHaveSignature(false);
      } else {
        setDoesUserHaveSignature(true);
      }
    }

    checkSignature();
    loadOfficers();
  }, []);

  const captureTextAsImage = async () => {
    try {
      const fullname = global.user_data.first_name.toLowerCase() + ' ' + global.user_data.last_name.toLowerCase();
      if (signatureName.toLowerCase() === fullname) {
        const uri = await captureRef(viewRef, {
          format: 'png',
          quality: 0.8,
        });

        let ret = await create_new_user_signature({ user_id: global.user_data.user_id }, global.token);
        if (ret.status === 200) {
          ret = ret.data;
          await put_image_s3('signatures/' + ret.body.signature_id, uri, 'image/png', global.token);

          global.signature_uri = uri;

          global.user_data.signature_id = ret.body.signature_id;
          await AsyncStorage.setItem('signature', global.signature_uri);
          storeJsonData('user_data', global.user_data);

          setDoesUserHaveSignature(true);
        } else {
          showSnackbar('error', 'Application Error: Failed to create your signature');
        }
      } else {
        showSnackbar('error', 'The name inputted does not match your name we have on record!');
      }
    } catch (error) {
      console.error('Error capturing image:', error);
    }
  };

  useEffect(() => {
    async function loadUnits() {
      const all_units_test = await get_units_for_1380(
        JSON.stringify({ unit_id: global.user_data.unit_id }),
        global.token,
      );

      if (all_units_test.status === 200) {
        setUnitList(all_units_test.data.body);
      } else {
        showSnackbar('error', 'Application Error: Failed to retrieve all units');
        setFailedToRetrieve(true);
      }
    }
    loadUnits();
  }, []);

  // Auto fill Ground AFTP Number if "Ground Training" or "Flight Physical" is selected
  useEffect(() => {
    if (
      supportNumber === '' &&
      (typeOfTrainingCompletedValue === 'Ground Training' ||
        typeOfTrainingCompletedValue === 'Flight Physical')
    ) {
      if (supportNumber2 != '') {
        setSupportNumber2((parseInt(currentSupportNumber) + 2).toString());
        setSupportNumberError2(false);
      }
      setSupportNumber((parseInt(currentSupportNumber) + 1).toString());
    }
    if (
      supportNumber2 === '' &&
      (typeOfTrainingCompletedValue2 === 'Ground Training' ||
        typeOfTrainingCompletedValue2 === 'Flight Physical')
    ) {
      if (supportNumber === '') {
        setSupportNumber2((parseInt(currentSupportNumber) + 1).toString());
        setSupportNumberError2(false);
      } else {
        setSupportNumber2((parseInt(currentSupportNumber) + 2).toString());
        setSupportNumberError2(false);
      }
    }
    if (
      !(
        typeOfTrainingCompletedValue === 'Ground Training' ||
        typeOfTrainingCompletedValue === 'Flight Physical'
      )
    ) {
      if (supportNumber2 != '') {
        setSupportNumber2((parseInt(currentSupportNumber) + 1).toString());
        setSupportNumberError2(false);
      }
      setSupportNumber('');
    }
    if (supportNumber != '' &&
      !(
        typeOfTrainingCompletedValue2 === 'Ground Training' ||
        typeOfTrainingCompletedValue2 === 'Flight Physical'
      )
    ) {
      setSupportNumber2('');
    }
  }, [typeOfTrainingCompletedValue, typeOfTrainingCompletedValue2]);

  const viewBlank = async () => {
    if (selectedType === 'aftp') pdfIn(form_aftp.pdf());
    else pdfIn(form_1380.pdf());
  };

  const viewFilled = async () => {
    const attachments = [];
    for (let i = 0; i < curFileNum; i++) {
      if (uploadFiles[i].form_type === 'RST') {
        attachments.push('RST ' + uploadFiles[i].rst_form_id);
      } else if (uploadFiles[i].form_type === 'dental') {
        attachments.push('dental ' + uploadFiles[i].dental_form_id);
      } else if (uploadFiles[i].form_type === 'EBDL') {
        attachments.push('EBDL ' + uploadFiles[i].rst_form_id);
      } else if (uploadFiles[i].form_type === 'ATA') {
        attachments.push('ATA ' + uploadFiles[i].rst_form_id);
      } else if (uploadFiles[i].form_type === 'MDR') {
        attachments.push('MDR ' + uploadFiles[i].rst_form_id);
      } else if (checkForImage(uploadFiles[i].form_type)){
        attachments.push(uploadFiles[i].form_name);
      }
    }
    let authorizationText =
      'IAW Reserve Pay Message 22-03, USAR PAM 37-1, AR 140-1, AR 140-185';

    const date = [];
    const hours = [];
    const retirementPoints = [];
    const duties = [];
    for (const event in events) {
      const currentDate = moment(new Date(events[event].date)).format(
        'MM/DD/YYYY',
      );
      date.push(currentDate);
      hours.push(events[event].hours.toString());
      retirementPoints.push(events[event].points.toString());
      duties.push(events[event].duties);
    }

    if (selectedType === 'RST') {
      if (date[0] !== undefined) {
        authorizationText = authorizationText.concat(
          '\nILO ' +
          (await grabMonthFromDate(date[0].toString())) +
          ' Battle Assembly ',
        );
      }
    } else if (selectedType === 'aftp') {
      authorizationText = 'AR 140-1';
    }

    const reviewerName = reviewerSelectedOption?.name && reviewerSelectedOption.name.trim() !== ''
      ? reviewerSelectedOption.name.split(' - ')[0]
      : ' ';

    const validatorNameTemp = validatorSelectedOption?.name && validatorSelectedOption.name.trim() !== ''
      ? validatorSelectedOption.name.split(' - ')[0]
      : ' ';

    const commonData = {
      creation_date_1: moment(new Date()).format('MM/DD/YYYY'),
      from_2: global.user_data.unit_1380_from,
      aniversary_year_3: null, // Yes, it's spelled with one N for some reason
      to_4: global.user_data.unit_1380_to,
      name_5: `${global.user_data.last_name} ${global.user_data.first_name} ${global.user_data.middle_name}`,
      grade_6: global.user_data.grade,
      branch_7: global.user_data.branch,
      individuals_assigned_organization_8: '',
      cite_Authorization_9: authorizationText,
      form_id: draftFormID && draftFormID !== 'NONE' ? draftFormID : 0,
      dod: global.user_data.dod,
      mos: global.user_data.mos,
      rank: global.user_data.rank,
      name_10: reviewerName,
      name_11: validatorNameTemp,
      signature_id_11: null,
      signature_id_12: showValidatorSig && validatorSigId ? validatorSigId : null,
      reviewer_id: null,
      validator_id: showValidatorSig ? validatorSelectedOption?.user_id : null,
      reviewer_ids: processReviewers(Array.isArray(reviewer) ? reviewer : [reviewer]),
      validator_ids: processReviewers(Array.isArray(validator) ? validator : [validator]),
    };

    if (selectedType === 'aftp') {
      let type_of_training_completed_9d = '';
      let new_bank_time = parseFloat(bankTime) + (FLTHRS - 1.5);
      if (typeOfTrainingCompletedValue === 'Other') {
        type_of_training_completed_9d = [typeOfTrainingCompletedOther];
      } else if (typeOfTrainingCompletedValue === 'Ground Training' && groundTrainingDescription) {
        type_of_training_completed_9d = ['Ground Training: ' + groundTrainingDescription];
      } else {
        type_of_training_completed_9d = [typeOfTrainingCompletedValue];
      }

      if (AFTPMUTAs == 2) {
        new_bank_time = new_bank_time + (FLTHRS2 - 1.5);
        if (typeOfTrainingCompletedValue2 === 'Other') {
          type_of_training_completed_9d.push(typeOfTrainingCompletedOther2);
        } else if (typeOfTrainingCompletedValue2 === 'Ground Training' && groundTrainingDescription2) {
          type_of_training_completed_9d.push('Ground Training: ' + groundTrainingDescription2);
        } else {
          type_of_training_completed_9d.push(typeOfTrainingCompletedValue2);
        }
      }
      const data = {
        ...commonData,
        date_9a: AFTPMUTAs == 1 ? [datePerformedAFTP] : [datePerformedAFTP, datePerformedAFTP2],
        hours_9b: AFTPMUTAs == 1 ? ['4'] : ['4', '4'],
        retirement_points_9c: AFTPMUTAs === 1 && pointsOnly === false ? ['P1'] :
          AFTPMUTAs !== 1 && pointsOnly === false ? ['P1', 'P1'] :
            AFTPMUTAs === 1 && pointsOnly === true ? ['N1'] :
              AFTPMUTAs !== 1 && pointsOnly === true ? ['N1', 'N1'] : null,
        description_9d: [],
        aftp_number_9d: AFTPMUTAs == 1 ? [AFTPNumber] : [AFTPNumber, AFTPNumber2],
        support_number_9d: AFTPMUTAs == 1 ? [supportNumber] : [supportNumber, supportNumber2],
        time_in_9d: AFTPMUTAs == 1 ? [timeIn] : [timeIn, timeIn2],
        time_out_9d: AFTPMUTAs == 1 ? [timeOut] : [timeOut, timeOut2],
        acft_9d: AFTPMUTAs == 1 ? [tailNumber] : [tailNumber, tailNumber2],
        flt_hrs_9d: getFltHrs(),
        type_of_training_completed_9d: type_of_training_completed_9d,
        hdip_acip_authorized_9: HDIP_ACIP_Authorized,
        incentive_pay: global.user_data.incentive_pay,
        bank_time: new_bank_time,
      };

      await generate_1380_aftp(data.form_id, data, route.navigation, false, false, attachments ?? [], global.user_data.user_id);
    } else {
      let data = {
        ...commonData,
        date_9a: date,
        hours_9b: hours,
        retirement_points_9c: retirementPoints,
        description_9d: duties,
        duty_performed_9: selectedType,
      };

      if (selectedType === 'RST') {
        data = {
          ...data,
          hdip_acip_authorized_9: HDIP_ACIP_Authorized,
          incentive_pay: global.user_data.incentive_pay,
        }
      }

      await generate_1380(data.form_id, data, route.navigation, false, false, attachments ?? [], global.user_data.user_id);
    }
  };


  const handleLoadDraft = async () => {
    navigation.push('YourFiles', {
      from1380: false, // False because this is used when attaching a supporting document to a 1380
      formType: '1380',
      loadingDraft: true,
      which1380Page: 'new',
      addedFormIDs: addedFormIDs,
      uploadFiles: uploadFiles,
      events: events,
      draftFormID: draftFormID,
      modifyDoc: modifyingDoc,
      modifyingID: modifyingID,
    });
  };

  function setSelect(id) {
    setSelected(id);
  }

  function validate1380Input() {
    if (!viewSig) return true; // If !viewSig then we're saving a draft and ignoring error handling
    if (global.signature_uri == null || global.signature_uri === '') {
      showSnackbar('error', 'You do not have a signature...');
      return false;
    }
    if ((reviewer == null || reviewer === '')) {
      setReviewerEmptyError(true);
      showSnackbar('error', 'Please select a approver');
      return false;
    } else if ((validator == null || validator == '')) {
      setValidatorEmptyError(true);
      showSnackbar('error', 'Please select a validator');
      return false;
    } else if (events.length > 8) {
      showSnackbar('error', 'A maximum of 8 events per form submission');
      return false;
    } else if (events.length == 0) {
      setEventsEmptyError(true);
      showSnackbar('error', 'Please add at least one event');
      return false;
    } else {
      if (viewSig) {
        for (let i = 0; i < events.length; i++) {
          if (events[i].duties === '') {
            showSnackbar('error', 'Each event must have a description');
            return false;
          }
        }
      }
    }
    return true;
  }

  function checkTimeDifference(timeIn, timeOut) {
    const timeDiff = timeOut.diff(timeIn, 'hour');
    if (timeDiff < 0) {
      // Time out is on the next day
      const adjustedTimeDiff = 24 + timeDiff;
      if (adjustedTimeDiff < 4) {
        return {
          hasError: true,
          errorMessage: 'Selected end time must be 4 hours after start time',
        };
      } else {
        return {
          hasError: false,
          errorMessage: '',
        };
      }
    } else if (timeDiff < 4) {
      return {
        hasError: true,
        errorMessage: 'Selected end time must be 4 hours after start time',
      };
    } else {
      return {
        hasError: false,
        errorMessage: '',
      };
    }
  }

  function calculateTimeDifference(startTime, endTime) {
    const start = new Date();
    const [startHours, startMinutes] = startTime.split(':');
    start.setHours(startHours, startMinutes, 0, 0);

    const end = new Date();
    const [endHours, endMinutes] = endTime.split(':');
    end.setHours(endHours, endMinutes, 0, 0);

    let timeDiffInHours = (end - start) / (60 * 60 * 1000);
    if (timeDiffInHours < -14) timeDiffInHours += 24;
    if (timeDiffInHours <= 0) timeDiffInHours += 12;

    return timeDiffInHours;
  }

  function validateAFTPInput() {
    if (!viewSig) return true; // If this is a draft they can put whatever
    let isValid = true;
    if (global.signature_uri == null || global.signature_uri === '') {
      isValid = false;
    }
    if (reviewer == null || reviewer === '') {
      setReviewerEmptyError(true);
      isValid = false;
    }
    if (AFTPMUTAs != 1 && AFTPMUTAs != 2) {
      setAFTPMUTAsError(true);
      isValid = false;
    }

    if (!formattedTimeIn || !formattedTimeIn.isValid()) {
      setTimeInEmptyError(true);
      isValid = false;
    }
    const { hasError, errorMessage } = checkTimeDifference(formattedTimeIn, formattedTimeOut);
    if (hasError) {
      setTimeOutEmptyError(true);
      setTimeOutErrorText(errorMessage);
      isValid = false;
    }

    if (typeOfTrainingCompletedValue === 'Other Flight') {
      if (typeOfTrainingCompletedOther === '') {
        setTypeOfTrainingCompletedOtherEmptyError(true);
        isValid = false;
      }
    }
    if (isGround || typeOfTrainingCompletedValue === "Ground Training" || typeOfTrainingCompletedValue2 === "Ground Training") {
      if (supportNumber === '') {
        setSupportNumberError(true);
        isValid = false;
      }
      if (tailNumber != '') {
        setTailNumberError(true);
        setTailNumberErrorText('Tail number must be blank for ground training');
        isValid = false;
      } else {
        setTailNumberError(false);
        setTailNumberErrorText(' ');
      }
    } else if (typeOfTrainingCompletedValue != '') {
      if (!isGround && (FLTHRS == null || FLTHRS === '')) {
        setFLTHRSError(true);
        setFLTHRSErrorText('Enter flight hours');
        isValid = false;
      } else if (+FLTHRS < 0.1 || 9.9 < +FLTHRS) {
        setFLTHRSError(true);
        setFLTHRSErrorText('Flight hours must be between 0.1 and 9.9');
        isValid = false;
      } else {
        setFLTHRSError(false);
        setFLTHRSErrorText('');
      }
      if (!isGround && tailNumber === '') {
        setTailNumberError(true);
        setTailNumberErrorText('Enter tail number');
        isValid = false;
      } else if (tailNumber.length < 3) {
        setTailNumberError(true);
        setTailNumberErrorText('Tail number must have at least 3 digits');
        isValid = false;
      } else {
        setTailNumberError(false);
        setTailNumberErrorText(' ');
      }
    }
    if (typeOfTrainingCompletedValue === '') {
      setTypeOfTrainingCompletedValueEmptyError(true);
      isValid = false;
    }
    if (AFTPNumber === '') {
      setAFTPNumberEmptyError(true);
      isValid = false;
    }
    if (eventDate === null) {
      setEventDateEmptyError(true);
      isValid = false;
    }

    if (AFTPMUTAs == 2) {
      if (!formattedTimeIn2 || !formattedTimeIn2.isValid()) {
        setTimeInEmptyError2(true);
        isValid = false;
      }

      const { hasError2, errorMessage2 } = checkTimeDifference(formattedTimeIn, formattedTimeOut);
      if (hasError2) {
        setTimeOutEmptyError2(true);
        setTimeOutErrorText2(errorMessage2);
        isValid = false;
      }
      if (!isGround2 && (FLTHRS2 == null || FLTHRS2 === '')) {
        setFLTHRSError2(true);
        isValid = false;
      }
      if (typeOfTrainingCompletedValue2 === '') {
        setTypeOfTrainingCompletedValueEmptyError2(true);
        isValid = false;
      }
      if (AFTPNumber2 === '') {
        setAFTPNumberEmptyError2(true);
        isValid = false;
      }
      if (eventDate3 === null) {
        setEventDateEmptyError3(true);
        isValid = false;
      }
      if (isGround2 && tailNumber2 != '') {
        setTailNumberError2(true);
        setTailNumberErrorText2('Tail number must be blank for ground training');
        isValid = false;
      } else {
        setTailNumberError2(false);
        setTailNumberErrorText2(' ');
      }
      if (typeOfTrainingCompletedValue2 === 'Other Flight') {
        if (typeOfTrainingCompletedOther2 === '') {
          setTypeOfTrainingCompletedOtherEmptyError2(true);
          isValid = false;
        }
      }
      if (isGround2) {
        if (supportNumber2 === '') {
          setSupportNumberError2(true);
          isValid = false;
        }
      } else if (typeOfTrainingCompletedValue2 != '') {
        if (FLTHRS2 == null || FLTHRS2 === '') {
          setFLTHRSError2(true);
          setFLTHRSErrorText2('Enter flight hours');
          isValid = false;
        } else if (+FLTHRS2 < 0.1 || 9.9 < +FLTHRS2) {
          setFLTHRSError2(true);
          setFLTHRSErrorText2('Flight hours must be between 0.1 and 9.9');
          isValid = false;
        } else {
          setFLTHRSError2(false);
          setFLTHRSErrorText2('');
        }
        if (tailNumber2 === '') {
          setTailNumberError2(true);
          setTailNumberErrorText2('Enter tail number');
          isValid = false;
        } else if (tailNumber2.length < 3) {
          setTailNumberError2(true);
          setTailNumberErrorText2('Tail number must have at least 3 digits');
          isValid = false;
        } else {
          setTailNumberError2(false);
          setTailNumberErrorText2(' ');
        }
      }
    }
    if (!isValid) {
      showSnackbar('error', 'Please correct one or more invalid inputs');
      return false;
    } else {
      let ret = [];

      if (!timeIn || !timeOut) {
        showSnackbar('error', 'Start and end times for Period #1 are required');
        return false;
      }

      const timeDiffInHours1 = calculateTimeDifference(timeIn, timeOut)

      if (timeDiffInHours1 < 4) {
        showSnackbar('error', 'Minimum 4 hours required per AR 140-1 for Period #1');
        setTimeOutEmptyError(true);
        setTimeOutErrorText('Selected end time must be at least 4 hours after start time');
        return false;
      }

      ret.push(Math.floor(timeDiffInHours1).toString());

      if (AFTPMUTAs == 2) {
        if (!timeIn2 || !timeOut2) {
          showSnackbar('error', 'Start and end times for Period #2 are required');
          return false;
        }

        const timeDiffInHours2 = calculateTimeDifference(timeIn2, timeOut2);

        if (timeDiffInHours2 < 4) {
          showSnackbar('error', 'Minimum 4 hours required per AR 140-1 for Period #2');
          setTimeOutEmptyError2(true);
          setTimeOutErrorText2('Selected end time must be at least 4 hours after start time');
          return false;
        }

        ret.push(Math.floor(timeDiffInHours2).toString());
      }

      return ret;
    }
  }

  function getFltHrs() {
    if (AFTPMUTAs == 1) {
      if (FLTHRS === '') {
        return [''];
      } else {
        return [parseFloat(FLTHRS).toFixed(1)];
      }
    } else if (AFTPMUTAs == 2) {
      if (FLTHRS === '' && FLTHRS2 === '') {
        return ['', ''];
      } else if (FLTHRS !== '' && FLTHRS2 === '') {
        return [parseFloat(FLTHRS).toFixed(1), ''];
      } else if (FLTHRS === '' && FLTHRS2 !== '') {
        return ['', parseFloat(FLTHRS2).toFixed(1)];
      } else {
        return [parseFloat(FLTHRS).toFixed(1), parseFloat(FLTHRS2).toFixed(1)];
      }
    }
  }

  const onSubmit = async () => {
    let AFTPhours;
    if (selectedType == 'aftp') {
      AFTPhours = validateAFTPInput();
      if (AFTPhours == false) {
        return;
      }
    } else {
      if (validate1380Input() == false) {
        return;
      }
    }
    setLoaded(false);
    const date = [];
    const hours = [];
    const retirementPoints = [];
    const duties = [];

    // eslint-disable-next-line guard-for-in
    for (const event in events) {
      const currentDate = moment(new Date(events[event].date)).format(
        'MM/DD/YYYY',
      );
      date.push(currentDate);
      hours.push(events[event].hours.toString());
      retirementPoints.push(events[event].points.toString());
      duties.push(events[event].duties);
    }
    let authorizationText =
      'IAW Reserve Pay Message 22-03, USAR PAM 37-1, AR 140-1, AR 140-185';

    switch (selectedType) {
      case 'RST':
        if (date[0] !== undefined) {
          authorizationText = authorizationText.concat(
            '\nILO ' +
            (await grabMonthFromDate(date[0].toString())) +
            ' Battle Assembly ',
            iloDate,
          );
        }
        setSelectedType('RST Code 11');
        break;
      case 'aftp':
        authorizationText = "AR 140-1"
        setSelectedType('AFTP Code 31');
        break;
      case 'MDR':
        setSelectedType('MDR Code 61');
        break;
      case 'ATA':
        setSelectedType('RMA Code 91');
        break;
      case 'EBDL':
        setSelectedType('EBDL Code 42');
        break;
      case 'other':
        break;
    }

    const attachments = [];
    const formTypes = ["RST", "EBDL", "RMA", "ATA", "MDR"];
    for (let i = 0; i < uploadFiles.length; i++) {
      if (uploadFiles[i].form_type === 'dental'){
        // Form is Dental
        attachments.push('dental ' + uploadFiles[i].dental_form_id);
      } else if (formTypes.includes(uploadFiles[i].form_type)){
        // Form is of valid types
        attachments.push(uploadFiles[i].form_type + ' ' + uploadFiles[i].rst_form_id);
      } else if (checkForImage(uploadFiles[i].form_type)){      
        // File is PDF, PNG, JPG, JPEG     
        attachments.push(uploadFiles[i].form_name);
      }
    }

    let data = '';
    const reviewerName = reviewerSelectedOption?.name && reviewerSelectedOption.name.trim() !== ''
      ? reviewerSelectedOption.name.split(' - ')[0]
      : '';
    const validatorNameTemp = validatorSelectedOption?.name && validatorSelectedOption.name.trim() !== ''
      ? validatorSelectedOption.name.split(' - ')[0]
      : '';

    if (selectedType === 'aftp') {
      let type_of_training_completed_9d = '';
      let new_bank_time = parseFloat(bankTime) + (FLTHRS - 1.5);
      if (typeOfTrainingCompletedValue === 'Other') {
        type_of_training_completed_9d = [typeOfTrainingCompletedOther];
      } else if (typeOfTrainingCompletedValue === 'Ground Training' && groundTrainingDescription) {
        type_of_training_completed_9d = ['Ground Training: ' + groundTrainingDescription];
      } else {
        type_of_training_completed_9d = [typeOfTrainingCompletedValue];
      }

      if (AFTPMUTAs == 2) {
        new_bank_time = new_bank_time + (FLTHRS2 - 1.5);
        if (typeOfTrainingCompletedValue2 === 'Other') {
          type_of_training_completed_9d.push(typeOfTrainingCompletedOther2);
        } else if (typeOfTrainingCompletedValue2 === 'Ground Training' && groundTrainingDescription2) {
          type_of_training_completed_9d.push('Ground Training: ' + groundTrainingDescription2);
        } else {
          type_of_training_completed_9d.push(typeOfTrainingCompletedValue2);
        }
      }

      // Create JSON array to send to the backend
      data = {
        creator_id: global.user_data.user_id,
        comment: '',
        form_name:
          global.user_data.rank +
          ' ' +
          global.user_data.first_name +
          ' ' +
          global.user_data.middle_name +
          ' ' +
          global.user_data.last_name,
        form_type: '1380 aftp',
        request_type: 'DA 1380-AFTP',
        reviewer_id: null,
        validator_id: null,
        reviewer_ids: processReviewers(Array.isArray(reviewer) ? reviewer : [reviewer]),
        validator_ids: processReviewers(Array.isArray(reviewer) ? reviewer : [reviewer]),
        recommender_id: null,
        status: viewSig ? 1 : 0,
        creation_date_1: moment(new Date()).format('MM/DD/YYYY'),
        from_2: global.user_data.unit_1380_from,
        aniversary_year_3: null,
        to_4: global.user_data.unit_1380_to,
        name_5:
          global.user_data.last_name +
          ' ' +
          global.user_data.first_name +
          ' ' +
          global.user_data.middle_name,
        grade_6: global.user_data.grade,
        branch_7: global.user_data.branch,
        individuals_assigned_organization_8: '',
        duty_performed_9: selectedType,
        cite_Authorization_9: authorizationText,
        date_9a:
          AFTPMUTAs == 1 ?
            [datePerformedAFTP] :
            [datePerformedAFTP, datePerformedAFTP2],
        hours_9b: AFTPMUTAs == 1 ? ['4'] : ['4', '4'],
        retirement_points_9c: AFTPMUTAs === 1 && pointsOnly === false ? ['P1'] :
          AFTPMUTAs !== 1 && pointsOnly === false ? ['P1', 'P1'] :
            AFTPMUTAs === 1 && pointsOnly === true ? ['N1'] :
              AFTPMUTAs !== 1 && pointsOnly === true ? ['N1', 'N1'] : null,
        description_9d: [],
        name_10: reviewerName,
        signature_id_11: null,
        iperms_12: false,
        pay_data_12: '',
        attachments: attachments,
        unit_id: global.user_data.unit_id,
        hdip_acip_authorized_9: HDIP_ACIP_Authorized ?? false,
        aftp_number_9d:
          AFTPMUTAs == 1 ? [AFTPNumber] : [AFTPNumber, AFTPNumber2],
        support_number_9d:
          AFTPMUTAs == 1 ? [supportNumber] : [supportNumber, supportNumber2],
        time_in_9d: AFTPMUTAs == 1 ? [timeIn] : [timeIn, timeIn2],
        time_out_9d: AFTPMUTAs == 1 ? [timeOut] : [timeOut, timeOut2],
        acft_9d: AFTPMUTAs == 1 ? [tailNumber] : [tailNumber, tailNumber2],
        flt_hrs_9d: getFltHrs(),
        type_of_training_completed_9d: type_of_training_completed_9d,
        bank_time: new_bank_time,
      };
      // If submitting a 1380 (not a AFTP) then create JSON array to send to the backend
    } else if (selectedType === 'RST') {
      data = {
        creator_id: global.user_data.user_id,
        comment: '',
        form_name:
          global.user_data.rank +
          ' ' +
          global.user_data.first_name +
          ' ' +
          global.user_data.middle_name +
          ' ' +
          global.user_data.last_name,
        form_type: '1380',
        request_type:
          'DA 1380-' +
          (selectedType === 'other' ?
            selectedOtherType.toUpperCase() :
            selectedType.toUpperCase()),
        recommender_id: null,
        status: viewSig ? 1 : 0,
        creation_date_1: moment(new Date()).format('MM/DD/YYYY'),
        from_2: global.user_data.unit_1380_from,
        aniversary_year_3: null,
        to_4: global.user_data.unit_1380_to,
        name_5:
          global.user_data.last_name +
          ' ' +
          global.user_data.first_name +
          ' ' +
          global.user_data.middle_name,
        grade_6: global.user_data.grade,
        branch_7: global.user_data.branch,
        individuals_assigned_organization_8: '',
        duty_performed_9: selectedType,
        cite_Authorization_9: authorizationText,
        date_9a: date,
        hours_9b: hours,
        retirement_points_9c: retirementPoints,
        description_9d: duties,
        iperms_12: false,
        pay_data_12: '',
        attachments: attachments,
        unit_id: global.user_data.unit_id,
        hdip_acip_authorized_9: HDIP_ACIP_Authorized ?? false,
        // aftp_number_9d: [],
        // support_number_9d: [],
        // time_in_9d: [],
        // time_out_9d: [],
        // acft_9d: [],
        // flt_hrs_9d: [],
        // type_of_training_completed_9d: [],
        // bank_time: null,
        name_10: reviewerName,
        name_11: validatorNameTemp,
        signature_id_11: null,
        signature_id_12: showValidatorSig && validatorSigId ? validatorSigId : null,
        reviewer_id: null,
        validator_id: showValidatorSig ? validatorSelectedOption?.user_id : null,
        reviewer_ids: processReviewers(Array.isArray(reviewer) ? reviewer : [reviewer]),
        validator_ids: processReviewers(Array.isArray(validator) ? validator : [validator]),
      };
    } else {
      let requestType;
      if (selectedType === 'other') {
        requestType = selectedOtherType.toUpperCase()
      } else {
        if (selectedType === 'ATA') {
          requestType = 'RMA'
        } else {
          requestType = selectedType.toUpperCase()
        }
      }

      data = {
        creator_id: global.user_data.user_id,
        comment: '',
        form_name:
          global.user_data.rank +
          ' ' +
          global.user_data.first_name +
          ' ' +
          global.user_data.middle_name +
          ' ' +
          global.user_data.last_name,
        form_type: '1380',
        request_type:
          'DA 1380-' + requestType,
        recommender_id: null,
        status: viewSig ? 1 : 0,
        creation_date_1: moment(new Date()).format('MM/DD/YYYY'),
        from_2: global.user_data.unit_1380_from,
        aniversary_year_3: null,
        to_4: global.user_data.unit_1380_to,
        name_5:
          global.user_data.last_name +
          ' ' +
          global.user_data.first_name +
          ' ' +
          global.user_data.middle_name,
        grade_6: global.user_data.grade,
        branch_7: global.user_data.branch,
        individuals_assigned_organization_8: '',
        duty_performed_9: selectedType,
        cite_Authorization_9: authorizationText,
        date_9a: date,
        hours_9b: hours,
        retirement_points_9c: retirementPoints,
        description_9d: duties,
        iperms_12: false,
        pay_data_12: '',
        attachments: attachments,
        unit_id: global.user_data.unit_id,
        aftp_number_9d: [],
        hdip_acip_authorized_9: HDIP_ACIP_Authorized ?? false,
        support_number_9d: [],
        time_in_9d: [],
        time_out_9d: [],
        acft_9d: [],
        flt_hrs_9d: [],
        type_of_training_completed_9d: [],
        bank_time: null,
        name_10: reviewerName,
        name_11: validatorNameTemp,
        signature_id_11: null,
        signature_id_12: showValidatorSig && validatorSigId ? validatorSigId : null,
        reviewer_id: null,
        validator_id: showValidatorSig ? validatorSelectedOption?.user_id : null,
        reviewer_ids: processReviewers(Array.isArray(reviewer) ? reviewer : [reviewer]),
        validator_ids: processReviewers(Array.isArray(validator) ? validator : [validator]),
      };
    }
    let res;
    if (pulledFromDraft) {
      const updatedFormData = { ...data, form_type: selectedType == 'aftp' ? '1380 aftp' : '1380', form_id: draftFormID };
      res = await update_file(updatedFormData, global.token); // They are now submitting a loaded draft
    } else res = await upload_file(data, global.token); // They created and submitted a new form in one go


    if ((!pulledFromDraft && res.status !== 200) || (pulledFromDraft && !res.body.includes('successfully updated'))) {
      showSnackbar('error', 'Application Error: Failed to upload the file');
      setLoaded(true);
      return;
    } else {
      console.log(res)
      console.log(pulledFromDraft)
      if (viewSig /*&& !pulledFromDraft */) await sendNotifToReviewer(pulledFromDraft ? draftFormID : res.data.body.form_id);
      // Once everything is updated we need to modify update_file to return the same  structure as upload_file so that we can get res.data.body.form_id and notify the approver when update_file is called too
      setLoaded(true);
      showSnackbar('success', `Form ${viewSig ? 'submitted' : 'drafted'} successfully.`);
    }

    // Add all attachments: jpegs, pngs, or pdfs (no pulled RSTs/dental forms) to S3 bucket
    const form_id = pulledFromDraft ? route.params.draftFormID : res.data.body.form_id;
    for (let i = 0; i < uploadFiles.length; i++) {
      console.log(uploadFiles[i]);
      let contentType = '';
      if (uploadFiles[i].form_type === 'jpg' || uploadFiles[i].form_type === 'jpeg') {
        contentType = 'image/jpeg';
      } else if (uploadFiles[i].form_type === 'png') {
        contentType = 'image/png';
      } else if (uploadFiles[i].form_type === 'pdf') {
        contentType = 'application/pdf';
      } else {
        continue;
      }
      // Define S3 path
      const path = `users/additional_documentation_1380s/${global.user_data.user_id}/${form_id}/${uploadFiles[i].form_name}`;
      const uri = uploadFiles[i].base64;
      await put_s3(path, uri, contentType, global.token);
    }
    navigation.navigate('Home');

    setLoaded(true);
  };

  const sendNotifToReviewer = async (form_id) => {
    // Send notif to approver
    for (let i = 0; i < reviewer?.length; i++) {
      const data = {
        title: '1380 Form',
        body: 'You have one new 1380 form to review',
        receiver_id: reviewer[i].user_id,
        sender_id: global.user_data.user_id,
        notification_type: 'file',
        object_type: 'review 1380 form',
        form_events_id: form_id,
      };
      const res = await create_notification(data, global.token);
      if (res.status !== 200) {
        showSnackbar('error', `Application Error: Failed to send notification to ${reviewer[i].name}`);
      }
    }
    // Send notif to validator
    if(!showValidatorSig){
      for (let i = 0; i < validator?.length; i++) {
        const data = {
          title: '1380 Form',
          body: 'You have one new 1380 form to validate',
          receiver_id: validator[i].user_id,
          sender_id: global.user_data.user_id,
          notification_type: 'file',
          object_type: 'review 1380 form',
          form_events_id: form_id,
        };
        const res = await create_notification(data, global.token);
        if (res.status !== 200) {
          showSnackbar('error', `Application Error: Failed to send notification to ${validator[i].name}`);
        }
      }
    }

    showSnackbar('success', '1380 Form successfully submitted', 'top', 'right', 6000, 400);
  };

  // "Handle" functions just used to change dropdown values

  const handleDutyChange = (event) => {
    setHoursEmptyError(false);
    setPointsEmptyError(false);
    setSelectedTypeEmptyError(false);
    setReviewerEmptyError(false);
    setEventDateEmptyError(false);
    setEventDateEmptyError2(false);
    setEventDateEmptyError3(false);
    setDutiesEmptyError(false);
    setTimeInEmptyError(false);
    setTimeOutEmptyError(false);
    setTimeInEmptyError2(false);
    setTimeOutEmptyError2(false);
    setTypeOfTrainingCompletedValueEmptyError(false);
    setTypeOfTrainingCompletedValueEmptyError2(false);
    setAFTPMUTAsError(false);
    setEvents([]);
    setCheckbox4H(true);
    setCheckbox8H(false);
    setHours('4');
    setPoints('P1');
    setAFTPMUTAs(1);
    if (tailNumber === '') {
      setTailNumberError(false);
      setTailNumberErrorText('');
    }
    if (tailNumber2 === '') {
      setTailNumberError2(false);
      setTailNumberErrorText2('');
    }
    if (FLTHRS === '') {
      setFLTHRSError(false);
      setFLTHRSErrorText('');
    }
    if (FLTHRS2 === '') {
      setFLTHRSError2(false);
      setFLTHRSErrorText2('');
    }
    if (supportNumber === '') {
      setSupportNumberError(false);
    }
    if (supportNumber2 === '') {
      setSupportNumberError2(false);
    }
    setSelectedType(event.target.value);
  };

  const handleReviewerChange = (newValue) => {
    setReviewer(newValue);
    setReviewerEmptyError(newValue === null);
  };

  const onCancel = () => {
    navigation.navigate('Home');
  };

  const loadingStyles = {
    animation: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      paddingVertical: 20,
      background: colors.screen_background,
    },
  };

  // Loading animation
  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
        <Progress.CircleSnail
          color={colors.loading_circle}
          indeterminate={true}
          size={275}
          thickness={8}
        />
      </View>
    );
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${TableCell.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    '&:hover': {
      backgroundColor: colors.textfield_background_hover,

    },

    '& td, & th': {
      borderColor: events.length !== 0 ? colors.border_color : 'none',
    },
  }));

  const StyledTableHeaderRow = styled(TableRow)(() => ({
    '& td, & th': {
      border: 'none',
    },
  }));

  function processReviewers(group) {
    if (group.length > 1) {
      return group.filter(reviewer => typeof reviewer.user_id !== 'string').map(reviewer => reviewer.user_id)
    } else if (group.length == 1) {
      return [group[0].user_id]
    } else {
      return []
    }
  }

  // 1380-RST has the most props common with the other types so it's serving as the base collection of props
  // AFTP and Other then add on a few more useStates as needed, but this saves a lot of lines
  const RSTFormProps = {
    navigation,
    eventDate,
    setEventDate,
    checkbox4H,
    handleCheckbox4H,
    checkbox8H,
    handleCheckbox8H,
    HDIP_ACIP_Authorized,
    handleHDIP,
    duties,
    setDuties,
    modifying,
    setModifying,
    addEvent,
    modifyFirstEntryEvent,
    AFTPMUTAsError,
    selected,
    modifyingFirstEntry,
    eventDateEmptyError,
    datePicker1Open,
    eventDateEmptyErrorText,
    setEventDateEmptyError,
    setEventDateEmptyErrorText,
    setDatePicker1Open,
    dutiesEmptyError,
    setDutiesEmptyError,
    setFormattedEventDate,
    locked,
    setHours,
    hours,
    hoursEmptyError,
    setHoursEmptyError,
    setPoints,
    pointsEmptyError,
    setPointsEmptyError,
    points,
    events,
  };

  return (
    <View style={styles.container}>
      <View style={{ height: '100%', paddingLeft: '3%', paddingRight: '3%', width: selectedType === '' ? '95%' : '100%' }}>
        <Scrollbars
          autoHide
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <View
              style={{
                flexDirection: 'column',
                alignSelf: 'center',
                width: '100%',
              }}
            >
              <View style={{ alignText: 'center', flex: 3 }}>
                <Text style={styles.pageTitle}>New 1380</Text>
                <Text style={styles.description}>
                  Fill out a 1380 form. Additionally, you can attach additional substantiating documents to your 1380
                  and view a blank 1380 form for reference.
                </Text>
              </View>

              {/* Actual form stuff begins here */}
              <View style={{ flexDirection: (isColumn || selectedType === 'aftp' && hours === '8') ? 'column' : 'row', justifyContent: 'center' }} >
                <View style={[styles.contentContainer, { justifyContent: selectedType !== 'aftp' ? 'center' : '', marginBottom: selectedType === '' ? '3%' : '' }]}>
                  <View style={styles.entryBox}>
                    <View style={[styles.dutyDropDownContainer]}>
                      <FormControl
                        fullWidth
                        style={styles.dutyDropDownStyle}
                        sx={{ ...styles.textInputProps, justifyContent: 'center' }}
                      >
                        <InputLabel id="duty_checkbox">Duty*</InputLabel>
                        <Select
                          inputProps={styles.selectProps}
                          label="Duty*"
                          labelId="duty_checkbox"
                          id="duty-dropdown"
                          value={selectedType}
                          onChange={handleDutyChange}
                          onBlur={() => {
                            setSelectedTypeEmptyError(selectedType === '');
                          }}
                          error={selectedTypeEmptyError}
                          display={"true"}
                        >
                          <MenuItem value={'RST'}>RST</MenuItem>
                          <MenuItem value={'aftp'}>AFTP</MenuItem>
                          <MenuItem value={'MDR'}>MDR</MenuItem>
                          <MenuItem value={'ATA'}>RMA/ATA</MenuItem>
                          <MenuItem value={'EBDL'}>EBDL</MenuItem>
                          <MenuItem value={'other'}>Other</MenuItem>
                        </Select>

                      </FormControl>
                      <View>
                        <FormHelperText style={{ color: 'red', alignSelf: 'center', backgroundColor: colors.screen_background }}>{selectedTypeEmptyError ? 'Select duty type' : ' '}</FormHelperText>
                      </View>
                    </View>

                    {selectedType === 'RST' || selectedType === 'aftp' || selectedType === 'ATA' || selectedType == 'EBDL' || selectedType == 'MDR' || selectedType === 'other' ? (
                      <>
                        {selectedType === 'RST' && <RSTInputs1380 id="RST Inputs" {...RSTFormProps} />}
                        {/* ^ RST fields input */}
                        {selectedType === 'aftp' && <AFTPInputs1380 id="AFTP Inputs" {...RSTFormProps} // This is the AFTP fields input
                          currentAFTPNumber={currentAFTPNumber}
                          currentSupportNumber={currentSupportNumber}
                          bankTime={bankTime}
                          datePicker2Open={datePicker2Open}
                          datePicker3Open={datePicker3Open}
                          eventDate={eventDate}
                          setEventDate={setEventDate}
                          eventDateEmptyError={eventDateEmptyError}
                          setEventDateEmptyError={setEventDateEmptyError}
                          eventDateEmptyError3={eventDateEmptyError3}
                          setEventDateEmptyError3={setEventDateEmptyError3}
                          setDatePicker2Open={setDatePicker2Open}
                          setDatePicker3Open={setDatePicker3Open}
                          eventDate3={eventDate3}
                          setEventDate3={setEventDate3}
                          setDatePerformedAFTP={setDatePerformedAFTP}
                          setDatePerformedAFTP2={setDatePerformedAFTP2}
                        />}
                        {(selectedType === 'ATA' || selectedType === 'EBDL' || selectedType == 'MDR') && <EBDLInputs1380 id="EBDL Inputs" {...RSTFormProps} // This is the EBDL fields input
                        // This form type uses all of the same props as RST so we are just passing the RST props object again for simplicity
                        />}
                        {selectedType === 'other' && <OtherInputs1380 id="1380 Inputs" {...RSTFormProps} // This is the Other fields input
                          selectedOtherType={selectedOtherType}
                          setSelectedOtherType={setSelectedOtherType}
                          selectedOtherTypeEmptyError={selectedOtherTypeEmptyError}
                          setSelectedOtherTypeEmptyError={setSelectedOtherTypeEmptyError}
                          pulled={pulled}
                        />}
                      </>
                    ) : null}
                  </View>
                </View>

                {selectedType !== '' && (
                  <View style={styles.contentContainer}>
                    <View style={{ width: selectedType === 'aftp' && hours === '8' ? '49%' : '100%', height: '47px', marginBottom: '2.5%', zIndex: 4000, marginTop: selectedType === 'aftp' && hours === '8' ? '2%' : '0%' }}>
                      <CustomAutocomplete
                        placeholderText={selectedType === 'aftp' ? 'AFTP Supervisor (Approver/Validator)*' : 'Officer Knowledge Duties Performed*'}
                        permission={selectedType == 'AFTP' || selectedType === 'aftp' ? 32 : 29}
                        selectedOption={Array.isArray(reviewer) ? reviewer : [reviewer]}
                        setSelectedOption={setReviewer}
                        error={reviewerEmptyError}
                        setError={setReviewerEmptyError}
                        reviewerIds={reviewerIds}
                        validatorIds={[]}
                        manualOption={null}
                        setReviewerOption={setReviewerSelectedOption}
                        isValidator={false}
                        resetSearchQuery={false}
                        from={"New1380"}
                        zIndex={3000}
                      />
                    </View>
                    <View style={{ backgroundColor: colors.screen_background, zIndex: 1000 }}>
                      <FormHelperText style={{
                        color: 'red',
                        marginTop: reviewerEmptyError ? '-15%' : '',
                        marginBottom: reviewerEmptyError ? '5%' : '',
                      }}>
                        {reviewerEmptyError ? "Select a Approver" : ""}</FormHelperText>
                    </View>
                    {selectedType !== 'aftp' && (
                      <View style={{ width: selectedType === 'aftp' && hours === '8' ? '49%' : '100%', height: '47px', marginBottom: '2.5%', zIndex: 2000, marginTop: selectedType === 'aftp' && hours === '8' ? '2%' : '0%' }}>
                        <CustomAutocomplete
                          placeholderText={`Validator of Approved Additional Training*`}
                          permission={41}
                          selectedOption={Array.isArray(validator) ? validator : [validator]}
                          setSelectedOption={setValidator}
                          error={validatorEmptyError}
                          setError={setValidatorEmptyError}
                          reviewerIds={[]}
                          validatorIds={validatorIds}
                          setManualOption={setValidatorOption}
                          manualOption={validatorOption.name !== "" && uploadFiles.length > 0  && !uploadFiles[0]?.form_name.includes('dental') ? validatorOption : null}
                          setReviewerOption={setValidatorSelectedOption}
                          setValidatorSig={setValidatorSigId}
                          setShowValidatorSig={setShowValidatorSig}
                          isValidator={true}
                          resetSearchQuery={resetSearchQuery}
                          from={"New1380"}
                          zIndex={1000}
                        />
                      </View>
                    )}
                    <View style={{ backgroundColor: colors.screen_background, zIndex: 1000 }}>
                      <FormHelperText style={{
                        color: 'red',
                        marginTop: validatorEmptyError ? '-15%' : '',
                        marginBottom: validatorEmptyError ? '5%' : '',
                      }}>
                        {validatorEmptyError ? "Select a Validator" : ""}</FormHelperText>
                    </View>

                    {/* Events table for if it's not an AFTP */}
                    {selectedType !== 'aftp' && selectedType !== '' ? (
                      <View style={{ width: '100%', alignItems: 'center' }}>
                        <View style={[styles.tableDiv]}>
                          <TableContainer
                            component={Paper}
                            style={{
                              height: 'fit-content',
                              paddingTop: '1%',
                              backgroundColor: colors.textfield_background,
                            }}
                          >
                            <Table>
                              <TableHead>
                                <StyledTableHeaderRow>
                                  <StyledTableCell
                                    style={{
                                      color: colors.inputted_text,
                                      fontSize: '.8rem',
                                    }}
                                  >
                                    Event Date
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      color: colors.inputted_text,
                                      fontSize: '.8rem',
                                    }}
                                  >
                                    Number of Hours
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      color: colors.inputted_text,
                                      fontSize: '.8rem',
                                    }}
                                  >
                                    Retirement Points
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      color: colors.inputted_text,
                                      fontSize: '.8rem',
                                    }}
                                  >
                                    Description
                                  </StyledTableCell>
                                  <StyledTableCell></StyledTableCell>
                                </StyledTableHeaderRow >
                              </TableHead>
                              <TableBody>
                                {events.map((event, index) => {
                                  return (
                                    <StyledTableRow key={event.id} style={{ backgroundColor: selected === index ? colors.textfield_background_hover : 'transparent' }}>
                                      <StyledTableCell style={{ color: colors.text }}>
                                        {event.formattedDate}
                                      </StyledTableCell>
                                      <StyledTableCell style={{ color: colors.text }}>
                                        {event.hours}
                                      </StyledTableCell>
                                      <StyledTableCell style={{ color: colors.text }}>
                                        {event.points}
                                      </StyledTableCell>
                                      <StyledTableCell style={{ color: colors.text }}>
                                        {event.duties}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <View style={{ flexDirection: 'row' }}>
                                          <TouchableOpacity
                                            onPress={() => {
                                              if (selected === index) {
                                                if (editEntryCheck()) {
                                                  setSelected(-1);
                                                  setEventDate(null);
                                                  setFormattedEventDate('');
                                                  setDuties('');
                                                  if (selectedType === 'other') {
                                                    setHours('');
                                                    setPoints('');
                                                  }
                                                  setEventDateEmptyError(false);
                                                  setDutiesEmptyError(false);
                                                  setModifying(false);
                                                  setModifyingFirstEntry(false);
                                                }
                                              } else {
                                                modifyEntry(event.id);
                                                setSelect(event.id);
                                              }
                                            }}
                                            style={{ marginRight: 30 }}
                                          >
                                            <Text style={{ color: colors.text }}>
                                              Edit
                                            </Text>
                                          </TouchableOpacity>

                                          <TouchableOpacity
                                            onPress={() => {
                                              deleteEntry(event.id);
                                            }}
                                          >
                                            <Text
                                              style={{ color: colors.delete }}
                                            >
                                              Delete
                                            </Text>
                                          </TouchableOpacity>
                                        </View>

                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </View>
                        <HelperText style={{ color: 'red', borderWidth: 0 }}>{eventsEmptyError ? 'Enter at least one event' : ' '}</HelperText>
                      </View>

                    ) : null}

                    {/* If this is an AFTP, show the Training Period inputs instead of the events table */}
                    {selectedType === 'aftp' && (
                      <View style={{ width: '100%', flexDirection: (hours === '8') ? 'row' : 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                        <AFTPTrainingPeriod
                          periodNumber={'1'}
                          typeOfTrainingCompletedValue={typeOfTrainingCompletedValue}
                          typeOfTrainingCompletedValueEmptyError={typeOfTrainingCompletedValueEmptyError}
                          setTypeOfTrainingCompletedValueEmptyError={setTypeOfTrainingCompletedValueEmptyError}
                          typeOfTrainingCompletedOther={typeOfTrainingCompletedOther}
                          setTypeOfTrainingCompletedOther={setTypeOfTrainingCompletedOther}
                          typeOfTrainingCompletedOtherEmptyError={typeOfTrainingCompletedOtherEmptyError}
                          setTypeOfTrainingCompletedOtherEmptyError={setTypeOfTrainingCompletedOtherEmptyError}
                          groundTrainingDescription={groundTrainingDescription}
                          setGroundTrainingDescription={setGroundTrainingDescription}
                          formattedTimeIn={formattedTimeIn}
                          setTimeIn={setTimeIn}
                          timeInEmptyError={timeInEmptyError}
                          setTimeInEmptyError={setTimeInEmptyError}
                          formattedTimeOut={formattedTimeOut}
                          setTimeOut={setTimeOut}
                          setTypeOfTrainingCompletedValue={setTypeOfTrainingCompletedValue}
                          setIsGround={setIsGround2}
                          timeOutEmptyError={timeOutEmptyError}
                          setTimeOutEmptyError={setTimeOutEmptyError}
                          timeOutErrorText={timeOutErrorText}
                          setFormattedTimeOut={setFormattedTimeOut}
                          setUserEditedTimeIn={setUserEditedTimeIn}
                          setUserEditedTimeOut={setUserEditedTimeOut}
                          AFTPNumber={AFTPNumber}
                          setAFTPNumber={setAFTPNumber}
                          AFTPNumberEmptyError={AFTPNumberEmptyError}
                          setAFTPNumberEmptyError={setAFTPNumberEmptyError}
                          supportNumber={supportNumber}
                          setSupportNumber={setSupportNumber}
                          supportNumberError={supportNumberError}
                          setSupportNumberError={setSupportNumberError}
                          isGround={isGround}
                          tailNumber={tailNumber}
                          setTailNumber={setTailNumber}
                          tailNumberError={tailNumberError}
                          setTailNumberError={setTailNumberError}
                          tailNumberErrorText={tailNumberErrorText}
                          setTailNumberErrorText={setTailNumberErrorText}
                          FLTHRS={FLTHRS}
                          setFLTHRS={setFLTHRS}
                          FLTHRSError={FLTHRSError}
                          setFLTHRSError={setFLTHRSError}
                          FLTHRSErrorText={FLTHRSErrorText}
                          setFLTHRSErrorText={setFLTHRSErrorText}
                          timePicker1Open={timePicker1Open}
                          setTimePicker1Open={setTimePicker1Open}
                          timePicker2Open={timePicker2Open}
                          setTimePicker2Open={setTimePicker2Open}
                          setFormattedTimeIn={setFormattedTimeIn}
                          setTimeOutErrorText={setTimeOutErrorText}
                          locked={locked}
                          pulled={pulled}
                          isColumn={isColumn}
                          hours={hours}
                          eventDate={eventDate}
                          eventDate3={eventDate3}
                          timeIn={timeIn}
                          timeOut={timeOut}
                          timeIn2={timeIn2}
                          timeOut2={timeOut2}
                          setTimeIn2={setTimeIn2}
                          setTimeOut2={setTimeOut2}
                          setFormattedTimeIn2={setFormattedTimeIn2}
                          setFormattedTimeOut2={setFormattedTimeOut2}
                          formattedTimeIn2={formattedTimeIn2}
                          formattedTimeOut2={formattedTimeOut2}
                          setUserEditedTimeIn2={setUserEditedTimeIn2}
                          setUserEditedTimeOut2={setUserEditedTimeOut2}
                        />
                        {hours === '8' && (
                          // 2nd training period input, only appears if 2 MUTAs selected. Has the "2" Use States instead
                          <AFTPTrainingPeriod
                            periodNumber={'2'}
                            typeOfTrainingCompletedValue={typeOfTrainingCompletedValue2}
                            typeOfTrainingCompletedValueEmptyError={typeOfTrainingCompletedValueEmptyError2}
                            setTypeOfTrainingCompletedValueEmptyError={setTypeOfTrainingCompletedValueEmptyError2}
                            typeOfTrainingCompletedOther={typeOfTrainingCompletedOther2}
                            setTypeOfTrainingCompletedOther={setTypeOfTrainingCompletedOther2}
                            typeOfTrainingCompletedOtherEmptyError={typeOfTrainingCompletedOtherEmptyError2}
                            setTypeOfTrainingCompletedOtherEmptyError={setTypeOfTrainingCompletedOtherEmptyError2}
                            groundTrainingDescription={groundTrainingDescription2}
                            setGroundTrainingDescription={setGroundTrainingDescription2}
                            setTypeOfTrainingCompletedValue={setTypeOfTrainingCompletedValue2}
                            setIsGround={setIsGround2}
                            formattedTimeIn={formattedTimeIn}
                            setTimeIn={setTimeIn}
                            timeInEmptyError={timeInEmptyError2}
                            setFormattedTimeOut={setFormattedTimeOut}
                            setTimeInEmptyError={setTimeInEmptyError2}
                            formattedTimeOut={formattedTimeOut}
                            setTimeOut={setTimeOut}
                            timeOutEmptyError={timeOutEmptyError2}
                            setFormattedTimeIn2={setFormattedTimeIn2}
                            setTimeOutEmptyError={setTimeOutEmptyError2}
                            timeOutErrorText={timeOutErrorText2}
                            setUserEditedTimeIn={setUserEditedTimeIn}
                            setUserEditedTimeOut={setUserEditedTimeOut}
                            setTailNumberErrorText={setTailNumberErrorText2}
                            AFTPNumber={AFTPNumber2}
                            setAFTPNumber={setAFTPNumber2}
                            AFTPNumberEmptyError={AFTPNumberEmptyError2}
                            setAFTPNumberEmptyError={setAFTPNumberEmptyError2}
                            supportNumber={supportNumber2}
                            setSupportNumber={setSupportNumber2}
                            supportNumberError={supportNumberError2}
                            setSupportNumberError={setSupportNumberError2}
                            isGround={isGround2}
                            tailNumber={tailNumber2}
                            setTailNumber={setTailNumber2}
                            tailNumberError={tailNumberError2}
                            setTailNumberError={setTailNumberError2}
                            tailNumberErrorText={tailNumberErrorText2}
                            FLTHRS={FLTHRS2}
                            setFLTHRS={setFLTHRS2}
                            FLTHRSError={FLTHRSError2}
                            setFLTHRSError={setFLTHRSError2}
                            FLTHRSErrorText={FLTHRSErrorText2}
                            setFLTHRSErrorText={setFLTHRSErrorText2}
                            timePicker1Open={timePicker3Open}
                            setTimePicker1Open={setTimePicker3Open}
                            timePicker2Open={timePicker4Open}
                            setTimePicker2Open={setTimePicker4Open}
                            setFormattedTimeIn={setFormattedTimeIn}
                            setTimeOutErrorText={setTimeOutErrorText2}
                            locked={locked}
                            pulled={pulled}
                            isColumn={isColumn}
                            hours={hours}
                            eventDate={eventDate}
                            eventDate3={eventDate3}
                            timeIn={timeIn}
                            timeOut={timeOut}
                            timeIn2={timeIn2}
                            timeOut2={timeOut2}
                            setTimeIn2={setTimeIn2}
                            setTimeOut2={setTimeOut2}
                            setFormattedTimeOut2={setFormattedTimeOut2}
                            formattedTimeIn2={formattedTimeIn2}
                            formattedTimeOut2={formattedTimeOut2}
                            setUserEditedTimeIn2={setUserEditedTimeIn2}
                            setUserEditedTimeOut2={setUserEditedTimeOut2}
                          />
                        )}
                      </View>
                    )}
                  </View>
                )}
              </View>

              {/* List of Attachments */}
              <View style={{ width: '60%', marginTop: '2%', textAlign: 'center', alignSelf: 'center' }}>
                <Text style={styles.textStyle}>
                  Attachments (Click to view): {'\n'}
                </Text>
                {uploadFiles.map((file) => (
                  <View key={file.form_id} style={styles.fileContainer}>
                    <View style={styles.fileInfo}>
                      <TouchableOpacity
                        onPress={() => generateFromAttachment(file)}
                      >
                        <Text
                          style={[
                            styles.textStyleNormal,
                            { textAlign: 'center', marginRight: 10 },
                          ]}
                        >
                          File Name: {file.form_name}
                          {'\n'}
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      <TouchableOpacity
                        onPress={() => modifyDoc(file.rst_form_id || file.dental_form_id)}
                      >
                        <Text
                          style={[
                            styles.textStyleNormal,
                            { marginRight: 10 },
                          ]}
                        >
                          Modify{' '}
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => deleteDoc(file.form_id, file.form_name)}
                      >
                        <Text
                          style={[
                            styles.textStyleNormal,
                            { marginRight: 10, color: colors.delete },
                          ]}
                        >
                          Delete
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                ))}
              </View>
              <Text style={[styles.signText, { width: '60%', marginTop: '2%', marginBottom: '2%' }]}>
                By clicking on the &quot;Submit&quot; button below, you consent to your
                digital signature being affixed to the document. Your signature can
                be viewed below after clicking the &quot;Sign Document&quot; button. If you
                don&apos;t have a signature, please add one below.
              </Text>

              {/* Placing their signature, or creating a new one */}
              <View style={{ width: '100%', alignItems: 'center', maringTop: '1%' }}>
                {doesUserHaveSignature == true ? (
                  <>
                    <TouchableOpacity
                      style={buttonsColumn ? styles.signDocButtonWider : styles.signDocButton}
                      onPress={handleSignature}
                    >
                      <Text style={{ justifyContent: 'center', fontSize: 15, color: colors.text, fontWeight: 'bold' }}>
                        {viewSig ? 'Document Signed' : 'Sign Document'}
                      </Text>
                    </TouchableOpacity>
                    {viewSig && (
                      <>
                        <Image
                          resizeMode={'contain'}
                          style={{ width: 200, height: 100, backgroundColor: 'white', marginRight: '.9%', marginTop: '1%' }}
                          source={{ uri: global.signature_uri }}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <View style={{ width: '25%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={[styles.textStyle, { padding: 15 }]}>No signature found</Text>

                    <TextInput
                      style={[styles.commentInput, { width: '95%' }]}
                      theme={{
                        colors: {
                          text: colors.inputted_text,
                          accent: colors.textfield_background,
                          primary: colors.inputted_text,
                          placeholder: colors.placeholder_text,
                        },
                      }}
                      label="Enter your first and last name"
                      placeholder=""
                      value={signatureName}
                      onChange={(data) => setSignatureName(data.target.value)}
                    />

                    <ViewShot ref={viewRef} style={{ padding: 10, marginVertical: '5%', backgroundColor: 'white' }} options={{ format: 'png', quality: 1 }}>
                      <Text style={{ color: 'black' }}>{signatureName}</Text>
                    </ViewShot>

                    <TouchableOpacity style={[buttonsColumn ? styles.cancelButtonWider : styles.cancelButton, { backgroundColor: colors.action_button }]} onPress={captureTextAsImage}>
                      <Text style={styles.textStyle}>Create Signature</Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
              {(selectedType == 'RST' || selectedType == 'aftp') && userHasIncentivePay && (<View style={[styles.checkboxContainer, { marginTop: '15px', marginBottom: '15px', justifyContent: 'center', textAlign: 'center' }]}>
                <CustomRadioWithBackground
                  id="HDIP/ACIP Button"
                  checked={HDIP_ACIP_Authorized == true}
                  onChange={handleHDIP}
                  onClick={handleHDIP}
                  label="Incentive Pay"
                />
              </View>)}

              {/* The four buttons at the bottom (Cancel View Blank, Add Document, Submit)*/}
              <View style={buttonsColumn ? styles.columnContainer : styles.rowContainer}>
                <TouchableOpacity style={[buttonsColumn ? styles.cancelButtonWider : styles.cancelButton, { backgroundColor: colors.button_cancel, marginLeft: '2%', marginRight: '2%' }]} onPress={onCancel}>
                  <Text style={styles.textStyle}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[buttonsColumn ? styles.cancelButtonWider : styles.cancelButton, !isColumn && { marginLeft: '2%', marginRight: '2%' }]}
                  onPress={viewBlank}
                >
                  <Text style={styles.textStyle}>View Blank 1380</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[buttonsColumn ? styles.cancelButtonWider : styles.cancelButton, !isColumn && { marginLeft: '2%', marginRight: '2%' }]}
                  onPress={viewFilled}
                >
                  <Text style={styles.textStyle}>View Filled 1380</Text>
                </TouchableOpacity>
                {(!pulledFromDraft && !hideDraftButton) && (<TouchableOpacity
                  style={[
                    buttonsColumn ? styles.cancelButtonWider : styles.cancelButton,
                    { marginLeft: '2%', marginRight: '2%' },
                  ]}
                  onPress={handleLoadDraft}
                >
                  <Text style={styles.textStyle}>Load Draft</Text>
                </TouchableOpacity>)}

                <TouchableOpacity
                  style={[buttonsColumn ? styles.cancelButtonWider : styles.cancelButton, !isColumn && { marginLeft: '2%', marginRight: '2%' }]}
                  onPress={() => setFileSelectModal(true)}
                >
                  <Text style={styles.textStyle}>
                    Add Document
                  </Text>
                </TouchableOpacity>

                {mutaMatch && !failedToRetrieve && (
                  <TouchableOpacity
                    style={[buttonsColumn ? styles.buttonWider : styles.button, { backgroundColor: viewSig ? colors.button_submit : colors.button_draft, marginLeft: '2%', marginRight: '2%' }]}
                    onPress={checkEntries}
                  >
                    <Text style={styles.textStyle}>{viewSig ? "Submit" : "Save Draft"}</Text>
                  </TouchableOpacity>
                )}
                {!mutaMatch && (
                  <Text style={styles.textStyle}>
                    There is an issue with the MUTAs you are doing and how
                    many are selected. Make sure both are equal{' '}
                  </Text>
                )}

                {/* Modal for adding an attachment */}
                <View>
                  <Modal
                    animationType="fade"
                    transparent={true}
                    visible={fileSelectModal}
                    onRequestClose={() => {
                      setFileSelectModal(false);
                    }}
                  >
                    <View style={{ height: '100%', width: '100%', paddingLeft: '14%', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                      <View
                        style={{
                          position: 'absolute',
                          backgroundColor: colors.modal_background,
                          borderColor: colors.border_color,
                          borderWidth: 1,
                          borderRadius: 20,
                          alignItems: 'center',
                          shadowColor: '#000',
                          shadowOffset: { width: 0, height: 2 },
                          shadowOpacity: 0.25,
                          shadowRadius: 4,
                          elevation: 5,
                          width: '30%',
                          justifyContent: 'center',
                          alignSelf: 'center',
                          height: rstMUTA.length > 5 ? '60%' : rstMUTA.length > 3 ? '50%' : rstMUTA.length < 3 ? '35%' : '40%',
                          paddingHorizontal: 15,
                          paddingVertical: 10,
                        }}
                      >
                        <Text style={styles.modalText}>
                          Select A File{'\n'}
                        </Text>
                        <Text
                          style={[styles.textStyleNormal, { marginBottom: 20 }]}
                        >
                          File types can be .jpg, .jpeg, .png and .pdf
                        </Text>

                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png, .pdf"
                          onChange={handleAttachmentSelect}
                          style={{ color: colors.text, marginBottom: 5 }}
                        />

                        {selectedType !== 'aftp' && (<TouchableOpacity
                          style={[styles.button, { backgroundColor: colors.content_box, width: '12vw' }]}
                          onPress={handleYourFilesSelect}
                        >
                          <Text style={styles.textStyleNormal}>
                            {' '}
                            Select from Your Files{' '}
                          </Text>
                        </TouchableOpacity>)}

                        <TouchableOpacity
                          style={[buttonsColumn ? styles.cancelButtonWider : styles.cancelButton, { backgroundColor: colors.modal_background, width: '12vw' }]}
                          onPress={() => {
                            if (modifyingID !== -1) {
                              setModifyingID(-1);
                              setModifyingDoc(false);
                            }

                            setFileSelectModal(!fileSelectModal);
                          }}
                        >
                          <Text style={styles.textStyleNormal}>Cancel</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </Modal>
                </View>
              </View>
            </View>
          </View>
        </Scrollbars>
      </View>
    </View>
  );
}

export default New1380;