/* eslint-disable block-spacing */
/* eslint-disable linebreak-style */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable space-before-blocks */
/* eslint-disable brace-style */
/* eslint-disable no-trailing-spaces */
/* eslint-disable linebreak-style */
/* eslint-disable new-cap */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-spacing */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import React, { useEffect, useState, useRef } from "react";
import { useRoute } from '@react-navigation/native';
import { ClickAwayListener } from '@mui/base';
import { generate_ata, generate_ebdl, generate_mdr } from '../generatePDF';
import dayjs from "dayjs";
import { HelperText } from "react-native-paper";
import { useSnackbar } from '../../components/SnackbarContext';
import * as Progress from 'react-native-progress';
import { useFocusEffect } from '@react-navigation/native';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
} from "react-native";
import { TextInput } from "react-native-paper";

import {
  en,
  registerTranslation,
} from "react-native-paper-dates";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import moment from "moment";

import { useScale } from "../../components/scale";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useColorContext } from "../../components/ColorContext";
import { upload_file, update_file, get_single_file } from "../../api_handler/files";
import { create_notification } from "../../api_handler/notifications";
import {
  get_all_users_in_single_unit,
  get_forms_related_info_of_a_unit,
} from "../../api_handler/units";
import { pdfIn } from "../generatePDF.js";
import * as form_ebdl from "../../PDFs/EBDL_request_form_raw";
import * as form_ata from "../../PDFs/ATA_request_form_raw";
import * as form_mdr from "../../PDFs/MDR_request_form_raw";
import NavSideBar from "../../components/NavSideBar";
import Scrollbars from "react-custom-scrollbars";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ViewShot from 'react-native-view-shot';
import { captureRef } from 'react-native-view-shot';
import { create_new_user_signature } from "../../api_handler/users.js";
import { put_image_s3 } from "../../api_handler/files";
import { storeJsonData } from '../../api_handler/asyncstorage.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import PropTypes from 'prop-types';

registerTranslation("en", en);

function CreateNewEBDL({ navigation }) {
  const { colors } = useColorContext();
  const scale = useScale();
  const { showSnackbar } = useSnackbar();
  const styles = {
    container: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      backgroundColor: colors.screen_background,
    },
    wrapper: {
      flexDirection: "row",
      height: "100%",
      width: '100%'
    },
    scrollView: {
      height: '100%',
      width: '100%'
    },
    mainWrapper: {
      flexDirection: "column",
      alignSelf: "center",
    },
    header: {
      marginTop: "0%",
      height: 110,
      backgroundColor: colors.screen_background,
    },
    tableDiv: {
      width: '95%',
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      backgroundColor: colors.textfield_background,
    },
    rowBeingEdited: {
      backgroundColor: colors.textfield_background_hover,
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25
    },
    signText: {
      fontWeight: 'bold',
      fontSize: scale(14),
      textAlign: 'center',
      margin: '1%',
      paddingLeft: '5%',
      paddingRight: '5%',
      color: colors.bow
    },
    denialHeader: {
      fontWeight: 'bold',
      fontSize: scale(14),
      textAlign: 'center',
      margin: '1%',
      paddingLeft: '5%',
      paddingRight: '5%',
      color: colors.bow
    },
    denialText: {
      fontSize: scale(13),
      textAlign: 'center',
      margin: '1%',
      color: colors.file_Denied
    },
    description: {
      fontFamily: 'Trebuchet MS',
      fontSize: scale(18),
      color: colors.text,
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    button: {
      color: colors.text,
      width: "10vw",
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: submitted ? colors.grey : colors.content_box,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      textAlign: "center",
      alignItems: "center",
    },
    trainingButton: {
      color: colors.text,
      width: "10vw",
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: submitted ? colors.grey : colors.content_box,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      textAlign: "center",
      alignItems: "center",
    },
    cancelButton: {
      color: colors.text,
      width: "10vw",
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.modal_background,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: "center",
    },
    textStyle: {
      justifyContent: "center",
      fontSize: 15,
      fontWeight: "bold",
      color: colors.text,
    },
    textStyleNormal: {
      justifyContent: "center",
      fontSize: 15,
      color: colors.text,
    },
    dropDownStyle: {
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      width: "95%",
      backgroundColor: colors.textfield_background,
    },
    commentInput: {
      minHeight: "fit-content",
      borderWidth: 1,
      borderRadius: 5,
      borderColor: colors.border_color,
      backgroundColor: colors.textfield_background,
      width: "95%",
    },
    contentContainer: {
      borderColor: colors.border_color,
      flexDirection: "column",
      flex: 1,
    },
    checkboxContainer: {
      flexDirection: "row",
      fontSize: 18,
      alignItems: "center",
      flex: 1,
      padding: 10,
    },
    dateInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.border_color,
        },
        '&:hover fieldset': {
          borderColor: global.user_data.theme === 'dark' ? colors.form_border : '#1876d2',
        },
      },
      '&  .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.border_color,
        },
        '&:hover fieldset': {
          borderColor: colors.border_color,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.border_color,
        },
      },
      '& .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
    },
    entryBox: {
      borderColor: colors.border_color,
      borderRadius: 5,
      borderWidth: 1.5,
      justifyContent: "center",
      alignItems: "center",
      padding: 5,
      paddingLeft: 5,
      paddingRight: 5,
      margin: 10,
      marginTop: 0,
      width: '95%'
    },
    denialComponent: {
      borderColor: colors.border_color,
      borderRadius: 5,
      borderWidth: 1.5,
      justifyContent: "center",
      alignSelf: "center",
      padding: 5,
      paddingLeft: 5,
      paddingRight: 5,
      margin: 10,
      marginTop: 0,
      marginBottom: '1%',
      width: '50%'
    },
    selectProps: {
      bordercolor: colors.border_color,
      MenuProps: {
        sx: {
          '& .MuiMenu-paper': {
            background: colors.textfield_background,
            color: colors.inputted_text,
            bordercolor: colors.border_color,
          },
        }
      }
    }
  };

  const theme = useTheme();
  const route = useRoute();

  const requestTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });
  const loadingStyles = {
    animation: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      paddingVertical: 20,
      background: colors.screen_background,
    }
  };

  const [loaded, setLoaded] = useState(false);
  const [ebdlOfficers, setEbdlOfficers] = useState([]);
  const [ataOfficers, setAtaOfficers] = useState([]);
  const [mdrOfficers, setMdrOfficers] = useState([]);
  const [approver, setApprover] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [trainings, setTrainings] = useState([]);
  const [trainingsEmptyError, setTrainingsEmptyError] = useState(false);
  const [dates, setDates] = useState([]);
  const [amountOfDates, setAmountOfDates] = useState(0);
  const [amountOfTrainings, setAmountOfTrainings] = useState(0);
  const [trimmedTrainings, setTrimmedTrainings] = useState([]);
  const { selectedType, form_id, form_type, updatingSubmission } = route.params;
  const dateToday = moment().format("MM/DD/YY");
  const datePickerToday = (moment().format("DDMMMYYYY")).toUpperCase();
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [viewSig, setViewSig] = useState(false);
  const [pulledFromDraft, setPulledFromDraft] = useState(false);

  const [unitInfo, setUnitInfo] = useState();

  const [doesUserHaveSignature, setDoesUserHaveSignature] = useState(false);
  const [signatureName, setSignatureName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [trainingBeingAdded, setTrainingBeingAdded] = useState("");
  const [dateBeingAdded, setDateBeingAdded] = useState(null);
  const [formattedDateBeingAdded, setFormattedDateBeingAdded] = useState("");

  const [indexBeingEdited, setIndexBeingEdited] = useState(-1);

  const [taskTypeEmptyError, setTaskTypeEmptyError] = useState(false);
  const [noDateError, setNoDateError] = useState(false);
  const [noApproverError, setNoApproverError] = useState(false);
  const [didUserSignDocument, setDidUserSignDocument] = useState(false);
  const [failedToRetrieve, setFailedToRetrieve] = useState(false);
  const [duplicateDateError, setDuplicateDateError] = useState(false);

  const [reasonForDenial, setReasonForDenial] = useState(''); // Only set if you're resubmitting a denied form

  const viewRef = useRef();
  const datePickerRef = useRef(null);

  useFocusEffect(
    React.useCallback(() => {
      // This effect is used to prevent navigation instantly if date picker is open
      const beforeRemoveListener = (e) => {
        if (!datePickerOpen) {
          return;
        }
        e.preventDefault();

        setDatePickerOpen(false);
        // navigation.dispatch(e.data.action);
        setTimeout(() => {
          navigation.dispatch(e.data.action);
        }, 200);
      };

      navigation.addListener('beforeRemove', beforeRemoveListener);

      return () => {
        navigation.removeListener('beforeRemove', beforeRemoveListener);
      };
    }, [datePickerOpen])
  );

  useEffect(() => {
    async function loadOfficers() {
      let errorFlag = false;
      let filterEbdlOfficers = [];
      let filterAtaOfficers = [];
      let filterMdrOfficers = [];

      const ebdlOfficers = await get_all_users_in_single_unit(
        { unit_id: global.user_data.unit_id, permission: 30 },
        global.token
      );

      if (ebdlOfficers.status === 200) {
        filterEbdlOfficers = ebdlOfficers.data.body.filter(
          (user) => user.user_id != global.user_data.user_id
        );
        setEbdlOfficers(filterEbdlOfficers);
      } else {
        errorFlag = true;
      }

      const ataOfficers = await get_all_users_in_single_unit(
        { unit_id: global.user_data.unit_id, permission: 31 },
        global.token
      );

      if (ataOfficers.status === 200) {
        filterAtaOfficers = ataOfficers.data.body.filter(
          (user) => user.user_id != global.user_data.user_id
        );
        setAtaOfficers(filterAtaOfficers);
      } else {
        errorFlag = true;
      }


      const mdrOfficers = await get_all_users_in_single_unit(
        { unit_id: global.user_data.unit_id, permission: 31 },
        global.token
      );

      if (mdrOfficers.status === 200) {
        filterMdrOfficers = mdrOfficers.data.body.filter(
          (user) => user.user_id != global.user_data.user_id
        );
        setMdrOfficers(filterMdrOfficers);
      } else {
        errorFlag = true;
      }

      const unit_info = await get_forms_related_info_of_a_unit(
        { unit_id: global.user_data.unit_id },
        global.token
      );

      if (unit_info.status === 200) {
        setUnitInfo(unit_info.data.body);
      } else {
        errorFlag = true;
      }

      if (errorFlag) {
        showSnackbar("error", "Application Error: Failed to retrieve required form information");
        setFailedToRetrieve(true);
      } else {
        if (filterEbdlOfficers.length === 0 && selectedType === 'EBDL') {
          showSnackbar("error", "You do not have any EBDL Reviewers to choose from");
        } else if (filterAtaOfficers.length === 0 && selectedType === 'ATA') {
          showSnackbar("error", "You do not have any ATA Reviewers to choose from");
        } else if (filterMdrOfficers.length === 0 && selectedType === 'MDR') {
          showSnackbar("error", "You do not have any MDR Reviewers to choose from");
        }

        setFailedToRetrieve(true);
      }

      setIsLoading(false);
    }

    function checkSignature() {
      if (global.signature_uri == null || global.signature_uri === '') {
        setDoesUserHaveSignature(false);
      } else {
        setDoesUserHaveSignature(true);
      }
    }

    checkSignature();
    loadOfficers();
  }, []);

  const loadDraftForm = async () => {
    const formIDandType = { form_id, form_type };
    try {
      const fileData = await get_single_file(formIDandType, global.token);
      if (updatingSubmission) {
        // We are working with either an amendment to a submission or a recalled denial
        if (fileData.body.approver_comment !== '') setReasonForDenial(fileData.body.approver_comment);
      }
      
      if (fileData.body.reviewer_ids !== '') {
        if (route.params.selectedType === 'EBDL') setApprover(ebdlOfficers.find((officer) => officer.user_id === fileData.body.reviewer_ids[0]));
        else if (route.params.selectedType === 'ATA') setApprover(ataOfficers.find((officer) => officer.user_id === fileData.body.reviewer_ids[0]));
        else if (route.params.selectedType === 'MDR') setApprover(mdrOfficers.find((officer) => officer.user_id === fileData.body.reviewer_ids[0]));
      }

      if (fileData.body.dates_of_training !== '' && fileData.body.training_to_be_completed !== '' && trainings.length === 0) {
        const datesOfTraining = fileData.body.dates_of_training.split(', ');
        const trainingToBeCompleted = fileData.body.training_to_be_completed.split(', ');

        setAmountOfDates(datesOfTraining.length);
        setAmountOfTrainings(trainingToBeCompleted.length);
        setDates(datesOfTraining);
        setTrainings(trainingToBeCompleted);
        setTrimmedTrainings(splitTrainingsWithinLimit(trainingToBeCompleted, 40));
      }

      setPulledFromDraft(true);
    } catch (error) {
      console.error('Error loading form:', error);
    }
  };

  useEffect(() => {
    if (form_id !== undefined && form_type !== '') {
      loadDraftForm();
    }
  }, [form_id, form_type, ebdlOfficers]);

  function formatDate(inputDate) {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    // Parse the input date string
    const dateObject = new Date(inputDate);

    // Get day, month, and year
    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = months[dateObject.getMonth()];
    const year = dateObject.getFullYear();

    // Concatenate the parts to form the desired format
    const formattedDate = `${day}${month}${year}`;

    return formattedDate;
  }

  function unformatDate(formattedDate) {
    // Extract day, month abbreviation, and year parts from the formatted date string
    const day = formattedDate.substring(0, 2);
    const monthAbbreviation = formattedDate.substring(2, 5);
    const year = formattedDate.substring(5, 9);

    // Map month abbreviation to month number
    const months = {
      'JAN': '01',
      'FEB': '02',
      'MAR': '03',
      'APR': '04',
      'MAY': '05',
      'JUN': '06',
      'JUL': '07',
      'AUG': '08',
      'SEP': '09',
      'OCT': '10',
      'NOV': '11',
      'DEC': '12'
    };

    // Get the month number from the abbreviation
    const monthNumber = months[monthAbbreviation];

    // Concatenate the parts to form the YYYY-MM-DD format
    const unformattedDate = `${year}-${monthNumber}-${day}`;

    return unformattedDate;
  }


  const captureTextAsImage = async () => {
    try {
      const fullname = global.user_data.first_name.toLowerCase() + " " + global.user_data.last_name.toLowerCase();
      if (signatureName.toLowerCase() === fullname) {
        const uri = await captureRef(viewRef, {
          format: 'png',
          quality: 0.8
        });

        let ret = await create_new_user_signature({ user_id: global.user_data.user_id }, global.token);
        if (ret.status === 200) {
          ret = ret.data;

          await put_image_s3('signatures/' + ret.body.signature_id, uri, 'image/png', global.token);

          global.signature_uri = uri;

          global.user_data.signature_id = ret.body.signature_id;
          await AsyncStorage.setItem('signature', global.signature_uri);
          storeJsonData('user_data', global.user_data);

          setDoesUserHaveSignature(true);
        } else {
          showSnackbar("error", "Application Error: Failed to create your signature");
        }
      } else {
        showSnackbar('error', 'Input Error: The name inputted does not match your name we have on record!');
      }
    } catch (error) {
      console.error('Error capturing image:', error);
    }
  };

  const viewBlank = async () => {
    if (selectedType === "EBDL") { pdfIn(form_ebdl.pdf()); }
    else if (selectedType === "ATA") { pdfIn(form_ata.pdf()); }
    else if (selectedType === "MDR") { pdfIn(form_mdr.pdf()); }
  };

  const viewFilled = async () => {
    const data = {
      commanding_officer: unitInfo.unit_rst_commanding_officer,
      date: dateToday,
      dates_of_training: [...new Set(dates)].slice(0, 4).join(', '),
      field_memo: unitInfo.unit_rst_field_memo,
      form_id: (form_id && form_id !== undefined && form_id !== '') ? form_id : '',
      header: unitInfo.unit_rst_header,
      memorandum: unitInfo.unit_rst_memorandum,
      name_and_rank: await getRankName(),
      num_of_trainings: trainings.slice(0, 4).length,
      request_type: selectedType,
      training_to_be_completed: trainings.slice(0, 4).join(', ')
    };

    if (selectedType === "EBDL") {
      await generate_ebdl((form_id && form_id !== undefined && form_id !== '') ? form_id : '', data, route.navigation, false, false);
    } else if (selectedType === "ATA") {
      await generate_ata((form_id && form_id !== undefined && form_id !== '') ? form_id : '', data, route.navigation, false, false);
    } else if (selectedType === "MDR") {
      await generate_mdr((form_id && form_id !== undefined && form_id !== '') ? form_id : '', data, route.navigation, false, false);
    } else {
      console.log("viewFilled error! SelectedType is ", selectedType);
    }
  };


  const handleLoadDraft = async () => {
    navigation.navigate('YourFiles', {
      from1380: false,
      formType: selectedType,
      loadingDraft: true,
    });
  };

  const getRankName = async () => {
    if (
      global.user_data.middle_name == null ||
      global.user_data.middle_name == ""
    ) {
      return (
        global.user_data.rank +
        " " +
        global.user_data.first_name +
        " " +
        global.user_data.last_name
      );
    } else {
      return (
        global.user_data.rank +
        " " +
        global.user_data.first_name +
        " " +
        global.user_data.middle_name +
        " " +
        global.user_data.last_name
      );
    }
  };

  const getNth4Dates = (n, uniqueDates) => {
    // n=0 for the first 4 dates, n=1 for the 5th through 8th dates, etc.
    return uniqueDates.substring((n * 42) + 2 * n, ((n + 1) * 42) + 2 * n);
  };

  function splitTrainingsWithinLimit(trainings, limit) {
    const resultArray = [];

    let currentString = '';
    for (const training of trainings) {
      if (currentString.length + training.length <= limit) {
        currentString += (currentString.length === 0 ? '' : ', ') + training;
      } else {
        resultArray.push(currentString);
        currentString = training;
      }
    }

    if (currentString.length > 0) {
      resultArray.push(currentString);
    }

    return resultArray;
  }

  const handleAddTraining = () => {
    const isValid = (!isNaN(dateBeingAdded) && dateBeingAdded !== null) && dateBeingAdded?.toString() !== 'Invalid Date'
    let validEntry = true;

    if (trainingBeingAdded === "") {
      validEntry = false;
      setTaskTypeEmptyError(true);
    }

    if (!isValid) {
      validEntry = false;
      setNoDateError(true);
    }

    if (duplicateDateError) {
      validEntry = false;
    }

    if (!validEntry) {
      showSnackbar("error", "Please correct one or more invalid inputs");
    } else {
      setTaskTypeEmptyError(false);
      setTrainingsEmptyError(false);
      setNoDateError(false);
      setAmountOfTrainings(amountOfTrainings + 1);
      setAmountOfDates(amountOfDates + 1);

      const newTraining = trainingBeingAdded;
      const newDate = formatDate(dateBeingAdded);

      // Create a temporary array of objects combining dates and trainings
      const combinedArray = [...dates.map((date, index) => ({ date, training: trainings[index] }))];
      combinedArray.push({ date: newDate, training: newTraining });

      // Sort the combined array
      combinedArray.sort((a, b) => new Date(a.date) - new Date(b.date));

      // Separate the sorted array back into dates and trainings
      const sortedDates = combinedArray.map(item => item.date);
      const sortedTrainings = combinedArray.map(item => item.training);

      // Update the state with sorted arrays
      setDates(sortedDates);
      setTrainings(sortedTrainings);

      setTrimmedTrainings(splitTrainingsWithinLimit(sortedTrainings, 40));

      setTrainingBeingAdded("");
      setDateBeingAdded(null);
      setFormattedDateBeingAdded("");
    }
  };

  const handleModifyTraining = () => {
    const isValid = !isNaN(dateBeingAdded) && dateBeingAdded.toString() !== 'Invalid Date'
    let validEntry = true;
  
    if (trainingBeingAdded === "") {
      validEntry = false;
      setTaskTypeEmptyError(true);
    }
  
    if (!isValid) {
      validEntry = false;
      setNoDateError(true);
    }
  
    if (duplicateDateError) {
      validEntry = false;
    }
  
    if (!validEntry) {
      showSnackbar("error", "Please correct one or more invalid inputs");
    } else {
      setTaskTypeEmptyError(false);
      setTrainingsEmptyError(false);
      setNoDateError(false);
  
      // Create a temporary array of objects combining dates and trainings
      const combinedArray = trainings.map((training, index) => ({
        date: dates[index],
        training: training
      }));
  
      // Update the entry being modified
      combinedArray[indexBeingEdited] = {
        date: formatDate(dateBeingAdded),
        training: trainingBeingAdded
      };
  
      // Sort the combined array
      combinedArray.sort((a, b) => new Date(a.date) - new Date(b.date));
  
      // Separate the sorted array back into dates and trainings
      const sortedDates = combinedArray.map(item => item.date);
      const sortedTrainings = combinedArray.map(item => item.training);
  
      // Update the state with sorted arrays
      setDates(sortedDates);
      setTrainings(sortedTrainings);
  
      setTrimmedTrainings(splitTrainingsWithinLimit(sortedTrainings, 40));
  
      setIndexBeingEdited(-1);
      setTrainingBeingAdded("");
      setDateBeingAdded(null);
      setFormattedDateBeingAdded("");
    }
  };

  const checkFields = () => {
    if (viewSig) {
      if (approver === "") {
        setNoApproverError(true);
        showSnackbar("error", "Please select an Approver");
        setSubmitted(false);
        setIsLoading(false);
      } else if (global.user_data.signature_id == null || global.signature_uri == null) {
        showSnackbar("error", "You do not have a signature");
        setSubmitted(false);
        setIsLoading(false);
      }
      else if (trainings.length === 0 || dates.length === 0) {
        showSnackbar("error", "Please add at least one event");
        setTrainingsEmptyError(true);
        setSubmitted(false);
        setIsLoading(false);
      }
      else onSubmit(); // Actually submitting, with no errors
    }
    else onSubmit(); // Saving as a draft, possibly incomplete
  };

  const onSubmit = async () => {
    const joinedTrainings = trainings.join(', ')
    setTrimmedTrainings(splitTrainingsWithinLimit(trainings, 40));
    setIsLoading(true);
    const uniqueDatesArray = dates.map((date) => date.trim()).filter((date, index, self) => {
      return index === self.indexOf(date);
    });
    const uniqueDatesCount = uniqueDatesArray.length;
    const uniqueDates = uniqueDatesArray.join(', ');
    try {
      let currentDatesPortion = "";
      if (uniqueDatesCount >= 1) {
        for (let n = 0; n <= uniqueDatesCount / 4; n++) {
          currentDatesPortion = getNth4Dates(n, uniqueDates);
          const form_data = {
            creator_id: null,
            comment: "",
            form_name: "",
            form_type: "",
            request_type: "",
            reviewer_id: null,
            reviewer_ids: null,
            recommender_id: null,
            status: 1,
            timestamp: null,
            timestamp_string: "",
            unit_id: global.user_data.unit_id,
            name_and_rank: "",
            date: null,
            training_to_be_completed: joinedTrainings,
            num_of_trainings: amountOfTrainings,
            dates_of_training: currentDatesPortion,
            soldier_signature_id: null,
            approver_signature_id: null,
            approver_comment: "",
            request_is_approved: null,
            request_is_approved_date: null,
            ebdl_id: null,
            unit_rst_header: unitInfo.unit_rst_header,
            unit_rst_field_memo: unitInfo.unit_rst_field_memo,
            unit_rst_memorandum: unitInfo.unit_rst_memorandum,
            unit_rst_commanding_officer: unitInfo.unit_rst_commanding_officer,
          };

          form_data.creator_id = global.user_data.user_id;
          form_data.name_and_rank = await getRankName();
          form_data.date = dateToday;
          form_data.comment = "";

          // soldier signature image
          form_data.soldier_signature_id = viewSig ? global.user_data.signature_id : null;

          form_data.form_type = selectedType;
          form_data.request_type = selectedType;
          if (approver !== undefined && approver !== "") {
            form_data.reviewer_id = approver.user_id
            form_data.reviewer_ids = [approver.user_id];
          }
          form_data.form_name = await getRankName();
          form_data.status = viewSig ? 1 : 0; // Save as a draft if not signed, this is here for redundancy. We can still have dates added and save as a draft.

          let res;
          if (pulledFromDraft) {
            const updatedFormData = { ...form_data, attachments: null, form_type: form_type, form_id: form_id };
            console.log(updatedFormData)
            res = await update_file(updatedFormData, global.token); // They are now submitting a loaded draft
            console.log(res)
          }
          else {
            console.log(form_data)
            res = await upload_file(form_data, global.token);
            console.log(res)
          } // They created and submitted a new form in one go
          if ((!pulledFromDraft && res.status !== 200) || (pulledFromDraft && !res.body.includes("successfully updated"))) {
            showSnackbar("error", "Application Error: Failed to upload the file");
            setSubmitted(false);
            setIsLoading(false);
            return;
          }
          else {
            if (viewSig == true) await sendNotifToApprover(approver.user_id, res.data.body.form_id);
            setSubmitted(true);
            setIsLoading(false);
          }
        }
        setSubmitted(true);
        setIsLoading(false);
      } else { // If we have nothing added
        const form_data = {
          creator_id: null,
          comment: "",
          form_name: "",
          form_type: "",
          request_type: "",
          reviewer_id: null,
          reviewer_ids: null,
          recommender_id: null,
          status: 0,
          timestamp: null,
          timestamp_string: "",
          unit_id: global.user_data.unit_id,
          name_and_rank: "",
          date: null,
          training_to_be_completed: "",
          num_of_trainings: 0,
          dates_of_training: "",
          soldier_signature_id: null,
          approver_signature_id: null,
          approver_comment: "",
          request_is_approved: null,
          request_is_approved_date: null,
          ebdl_id: null,
          unit_rst_header: unitInfo.unit_rst_header,
          unit_rst_field_memo: unitInfo.unit_rst_field_memo,
          unit_rst_memorandum: unitInfo.unit_rst_memorandum,
          unit_rst_commanding_officer: unitInfo.unit_rst_commanding_officer,
        };

        form_data.creator_id = global.user_data.user_id;
        form_data.name_and_rank = await getRankName();
        form_data.date = dateToday;
        form_data.comment = "";

        // soldier signature image
        form_data.soldier_signature_id = null; // There's nothing added so this must be a draft

        form_data.form_type = selectedType;
        form_data.request_type = selectedType;
        if (approver !== undefined && approver !== "") {
          form_data.reviewer_ids = [approver.user_id];
          form_data.recommender_id = approver.user_id;
        }
        form_data.form_name = await getRankName();
        form_data.status = 0; // There's nothing added so this must be a draft

        let res;
        if (pulledFromDraft) {
          const updatedFormData = { ...form_data, attachments: null, form_type: form_type, form_id: form_id };
          res = await update_file(updatedFormData, global.token); // This would only be if they loaded a draft and then emptied it, very unlikely but possible
        }
        else res = await upload_file(form_data, global.token); // They created an empty draft

        if ((!pulledFromDraft && res.status !== 200) || (pulledFromDraft && !res.body.includes("successfully updated"))) {
          showSnackbar("error", "Application Error: Failed to upload the file");
          setSubmitted(false);
          setIsLoading(false);
          return;
        }
        // No approver notification because this is an empty draft
        setSubmitted(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error during form submission:', error);
      setIsLoading(false);
      // Handle or log the error as needed
    }

    showSnackbar("success", `${selectedType} Form ${viewSig ? 'submitted' : 'drafted'} successfully.`);
    setTrainingBeingAdded("");
    setDateBeingAdded(null);
    setFormattedDateBeingAdded("");
    setTrainings([]);
    setDates([]);
    navigation.navigate("Home");
  };

  /* -----------------------------------------------*/


  const sendNotifToApprover = async (approver_id, form_id) => {
    const data = {
      title: selectedType + " Form",
      body: "You have one new " + selectedType + " form to review",
      receiver_id: approver_id,
      sender_id: global.user_data.user_id,
      notification_type: "file",
      object_type: "review " + selectedType + " form",
      form_events_id: form_id,
    };

    let reviewer;
    if (selectedType === "EBDL") {
      reviewer = ebdlOfficers.filter((user) => approver_id === user.user_id);
    } else if (selectedType === "ATA") {
      reviewer = ataOfficers.filter((user) => approver_id === user.user_id);
    } else if (selectedType === 'MDR') {
      reviewer = mdrOfficers.filter((user) => approver_id === user.user_id);
    }

    const res = await create_notification(data, global.token);
    if (res.status !== 200) {
      showSnackbar(
        "success",
        selectedType + `Form submitted successfully`,
        "top",
        "right",
        6000,
        400,
        () => {
          showSnackbar("error", `Application Error: Failed to send notification`);
        }
      );
    } else {
      showSnackbar("error", `Application Error: Failed to send notification`);
    }
  };

  const handleApproverChange = (event) => {
    setApprover(event.target.value);
    setNoApproverError(false);
  };

  const onCancel = () => {
    setTrainingBeingAdded("");
    setDateBeingAdded(null);
    setFormattedDateBeingAdded("");
    setTrainings([]);
    setDates([]);
    navigation.navigate('Home');
  };

  if (isLoading) {
    return (
      <View style={loadingStyles.animation}>
        <Progress.CircleSnail
          color={colors.loading_circle}
          indeterminate={true}
          size={275}
          thickness={8}
        />
      </View>
    );
  }

  return (
    <ThemeProvider theme={requestTheme}>
      <View style={[styles.container, { cursor: isLoading ? 'wait' : 'auto' }]}>
        {/* <NavSideBar /> */}
        <Scrollbars style={styles.scrollView}>
          <View style={styles.mainWrapper}>
            {selectedType === "" && <Text style={styles.pageTitle}>New EBDL/ATA/MDR</Text>}
            {selectedType === "" && <Text style={styles.description}>Fill out an EBDL/ATA/MDR form for approval</Text>}
            {selectedType !== "" && <Text style={styles.pageTitle}>New {selectedType}</Text>}
            {selectedType !== "" && <Text style={styles.description}>Fill out an {selectedType} form for approval</Text>}

            <View style={{ flexDirection: "column", paddingLeft: '5%', paddingRight: '5%', marginTop: '2%' }}>
              {reasonForDenial !== '' && (
                <View>
                  <View style={styles.denialComponent}>
                    <Text style={[styles.denialHeader]}>
                      Reason for Denial:
                    </Text>
                    <Text style={[styles.denialText]}>
                      {reasonForDenial}
                    </Text>
                  </View>
                </View>
              )}
              <View style={{ flexDirection: 'row' }}>
                <View style={styles.contentContainer}>
                  <FormControl fullWidth>
                    <View>
                      {selectedType !== "" && (
                        <View style={styles.entryBox}>
                          <View style={{ alignSelf: "center", marginVertical: '3%' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <ThemeProvider theme={requestTheme}>
                                <ClickAwayListener
                                  onClickAway={(event) => {
                                    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                                      setDatePickerOpen(false);
                                    }
                                  }}
                                  mouseEvent="onMouseDown"
                                  disableRestoreFocus={true}
                                >
                                  <DatePicker
                                    label="To be Performed On"
                                    onChange={(value) => {
                                      // Extract the date components from the adapterDayJs object
                                      const year = value.$y;
                                      const month = value.$M;
                                      const day = value.$D;

                                      const dateValue = new Date(year, month, day);
                                      const momentValue = moment(dateValue);
                                      const formattedDate = momentValue.format("DDMMMYYYY").toUpperCase();
                                      const isValidDate = !isNaN(dateValue.getTime());

                                      setNoDateError(!isValidDate);
                                      setDateBeingAdded(value);

                                      if (isValidDate) {
                                        const isDuplicate = dates.some((date, index) => {
                                          if (index === indexBeingEdited) {
                                            return false;
                                          }
                                          return date === formattedDate;
                                        });

                                        if (isDuplicate) {
                                          setDuplicateDateError(true);
                                        } else {
                                          setFormattedDateBeingAdded(formattedDate);
                                          setDuplicateDateError(false);
                                        }
                                      }
                                    }}
                                    value={dateBeingAdded}
                                    ampm={"false"}
                                    error={noDateError || duplicateDateError}
                                    onClose={() => setDatePickerOpen(false)}
                                    onOpen={() => setDatePickerOpen(true)}
                                    open={datePickerOpen}
                                    slotProps={{
                                      textField: {
                                        style: { alignSelf: 'center' },
                                        sx: styles.dateInputProps,
                                        onFocus: () => {
                                          setNoDateError(false);
                                          setDuplicateDateError(false);
                                        },
                                        onBlur: () => {
                                          const isValidDate = !isNaN(dateBeingAdded) && dateBeingAdded?.toString() !== 'Invalid Date'
                                          setNoDateError(!isValidDate);

                                          setDuplicateDateError(duplicateDateError);
                                        },
                                        error: noDateError || duplicateDateError,
                                      }
                                    }}
                                    PopperProps={{
                                      modifiers: [
                                        {
                                          name: 'eventListeners',
                                          options: {
                                            scroll: false,
                                            resize: true,
                                          },
                                        },
                                      ],
                                      ref: (popperRef) => {
                                        if (popperRef && popperRef.node) {
                                          popperRef.node.id = 'calendar-element';
                                          datePickerRef.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                                        }
                                      },
                                    }}
                                  />
                                </ClickAwayListener>
                              </ThemeProvider>
                            </LocalizationProvider>

                            {duplicateDateError || noDateError ? (
                              <HelperText style={{ color: 'red', alignSelf: 'center' }} visible={duplicateDateError || noDateError}>{duplicateDateError ? "Entry already exists for this date" : "Enter performed on date(s)"}</HelperText>
                            ) : null}
                          </View>

                          <View style={{ width: '100%', alignItems: 'center' }}>
                            <TextInput
                              style={styles.commentInput}
                              theme={{
                                colors: {
                                  text: colors.inputted_text,
                                  accent: colors.textfield_background,
                                  primary: colors.inputted_text,
                                  placeholder: colors.placeholder_text,
                                },
                              }}
                              selectionColor={"black"}
                              onChangeText={(value) => setTrainingBeingAdded(value)}
                              onBlur={() => {
                                setTaskTypeEmptyError(trainingBeingAdded === '');
                              }}
                              onFocus={() => setTaskTypeEmptyError(false)}
                              activeOutlineColor={"black"}
                              value={trainingBeingAdded}
                              label={`Location/Nature of duties, training, or instruction`}
                              placeholder=""
                              error={taskTypeEmptyError}
                              multiline={true}
                            />
                            {taskTypeEmptyError ? (
                              <HelperText style={{ color: 'red' }} visible={taskTypeEmptyError}>{"Enter location/nature of duties performed"}</HelperText>
                            ) : null}
                          </View>
                          <View style={{ alignSelf: 'center', marginVertical: '3%' }}>
                            <TouchableOpacity
                              style={styles.trainingButton}
                              onPress={() => {
                                if (indexBeingEdited >= 0) handleModifyTraining();
                                else handleAddTraining();
                              }}
                            >
                              <Text style={styles.textStyleNormal}>
                                {indexBeingEdited >= 0 ? "Modify Training" : "Add Training"}
                              </Text>
                            </TouchableOpacity>

                            {trainingsEmptyError ? (
                              <HelperText style={{ color: 'red', alignSelf: 'center' }} visible={trainingsEmptyError}>{"Enter a task(s)"}</HelperText>
                            ) : null}
                          </View>
                        </View>
                      )}
                    </View>
                  </FormControl>
                </View>

                {selectedType !== "" &&
                  <View style={styles.contentContainer}>
                    <FormControl fullWidth style={styles.dropDownStyle} sx={{ ...styles.textInputProps, justifyContent: 'center' }}>
                      <InputLabel id="approver_checkbox"
                        style={{ color: colors.inputted_text }}>
                        Select an Approver
                      </InputLabel>
                      <Select
                        inputProps={styles.selectProps}
                        label="Select an Approver"
                        labelId="approver_checkbox"
                        id="approver-dropdown"
                        value={approver || ''}
                        onChange={handleApproverChange}
                        error={noApproverError}
                        display={'true'}
                      >
                        {selectedType === "EBDL" && ebdlOfficers.map((value) => {
                          return (
                            <MenuItem key={value} value={value}>{value.name}</MenuItem>
                          );
                        })}
                        {selectedType === "ATA" && ataOfficers.map((value) => {
                          return (
                            <MenuItem key={value} value={value}>{value.name}</MenuItem>
                          );
                        })}
                        {selectedType === "MDR" && mdrOfficers.map((value) => {
                          return (
                            <MenuItem key={value} value={value}>{value.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <HelperText style={{ color: 'red', backgroundColor: colors.screen_background, }} visible={noApproverError}>{"Select an Approver"}</HelperText>
                    {amountOfTrainings >= 1 &&

                      <View style={{ width: '100%', alignItems: 'flext-start' }}>
                        <View style={[styles.tableDiv]}>
                          <TableContainer
                            component={Paper}
                            style={{
                              height: 'fit-content',
                              paddingTop: '1%',
                              backgroundColor: colors.textfield_background,
                            }}
                          >
                            <Table style={{ backgroundColor: colors.textfield_background }}>
                              <TableHead style={{ borderBottom: '0px' }}>
                                <TableRow style={{ color: colors.text, borderBottom: '0px' }}>
                                  <TableCell style={{ color: colors.text, borderBottom: '0px' }}>
                                    Training
                                  </TableCell>
                                  <TableCell style={{ color: colors.text, borderBottom: '0px' }}>
                                    Date
                                  </TableCell>
                                  <TableCell style={{ borderBottom: '0px' }}>
                                    Actions
                                  </TableCell>
                                  <TableCell style={{ borderBottom: '0px' }}></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {trainings.map((training, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell style={{ backgroundColor: index === indexBeingEdited ? styles.rowBeingEdited.backgroundColor : 'transparent', color: colors.text }}>
                                        {training}
                                      </TableCell>
                                      <TableCell style={{ backgroundColor: index === indexBeingEdited ? styles.rowBeingEdited.backgroundColor : 'transparent', color: colors.text }}>
                                        {dates.at(index)}
                                      </TableCell>
                                      <TableCell style={{ backgroundColor: index === indexBeingEdited ? styles.rowBeingEdited.backgroundColor : 'transparent', color: colors.text }}>
                                        <TouchableOpacity
                                          onPress={() => {
                                            if (indexBeingEdited == index) {
                                              setIndexBeingEdited(-1);
                                              setDateBeingAdded(null);
                                              setFormattedDateBeingAdded("");
                                              setTrainingBeingAdded("");
                                            }
                                            else {
                                              setIndexBeingEdited(index);
                                              setDateBeingAdded(dayjs(dates[index]));
                                              setFormattedDateBeingAdded(formatDate(dates[index]));
                                              setTrainingBeingAdded(trainings[index]);
                                            }
                                          }}
                                        >
                                          <Text style={{ color: colors.text }}>
                                            Edit
                                          </Text>
                                        </TouchableOpacity>
                                      </TableCell>
                                      <TableCell style={{ backgroundColor: index === indexBeingEdited ? styles.rowBeingEdited.backgroundColor : 'transparent', color: colors.text }}>
                                        <TouchableOpacity
                                          onPress={() => {
                                            // Create copies of the trainings and dates arrays
                                            const updatedTrainings = [...trainings];
                                            const updatedDates = [...dates];

                                            // Remove the training at the specified index
                                            updatedTrainings.splice(index, 1);

                                            // Remove the corresponding date at the same index
                                            updatedDates.splice(index, 1);

                                            // Update the state with the new arrays
                                            setTrainings(updatedTrainings);
                                            setDates(updatedDates);


                                            // Update the amount of dates and trainings
                                            setAmountOfDates(updatedDates.length);
                                            setAmountOfTrainings(updatedTrainings.length);

                                            console.log("AFTER DELETION:");
                                            console.log(dates, trainings);
                                            console.log(amountOfDates, amountOfTrainings);
                                            console.log(splitTrainingsWithinLimit(trainings, 40));
                                          }}
                                        >
                                          <Text
                                            style={{ color: colors.delete }}
                                          >
                                            Delete
                                          </Text>
                                        </TouchableOpacity>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </View>
                      </View>
                    }
                  </View>
                }
              </View>

            </View>

            <br></br>
            <Text style={styles.signText}>
              By clicking on the "Submit" button below, you consent to your
              digital signature being affixed to the document. Your signature can
              be viewed below after clicking the "Sign Document" button. If you
              don't have a signature, please add one below.
            </Text>
            <View style={{ width: '100%', alignItems: 'center', maringTop: '1%' }}>
              {doesUserHaveSignature == true ? (
                <>
                  <TouchableOpacity
                    style={styles.button}
                    onPress={() => setViewSig(!viewSig)}
                  >
                    <Text style={styles.textStyle}>Sign Document</Text>
                  </TouchableOpacity>
                  {viewSig && (
                    <>
                      <Image
                        resizeMode={"contain"}
                        style={{ width: 200, height: 100, backgroundColor: 'white' }}
                        source={{ uri: global.signature_uri }}
                      />
                    </>
                  )}
                </>
              ) : (
                <View style={{ width: '25%', justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={[styles.textStyle, { padding: 15 }]}>No signature found</Text>

                  <TextInput
                    style={styles.commentInput}
                    theme={{
                      colors: {
                        text: colors.inputted_text,
                        accent: colors.textfield_background,
                        primary: colors.inputted_text,
                        placeholder: colors.placeholder_text,
                      },
                    }}
                    label="Enter your first and last name"
                    placeholder=""
                    value={signatureName}
                    onChange={(data) => setSignatureName(data.target.value)}
                  />

                  <ViewShot ref={viewRef} style={{ padding: 10, marginVertical: 10, backgroundColor: 'white' }} options={{ format: 'png', quality: 1 }}>
                    <Text style={{ color: "black" }}>{signatureName}</Text>
                  </ViewShot>

                  <TouchableOpacity style={styles.button} onPress={captureTextAsImage}>
                    <Text style={styles.textStyle}>Create Signature</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>

            <View style={{ flexDirection: "row", justifyContent: "space-evenly", marginTop: '3%' }}>
              {selectedType !== "" &&
                <>
                  <TouchableOpacity
                    style={[
                      styles.cancelButton,
                      { backgroundColor: colors.button_cancel },
                    ]}
                    onPress={onCancel}
                  >
                    <Text style={styles.textStyle}>Cancel</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.cancelButton}
                    onPress={viewBlank}
                  >
                    <Text style={styles.textStyle}>View Blank Form</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.cancelButton}
                    onPress={viewFilled}
                  >
                    <Text style={styles.textStyle}>View Filled</Text>
                  </TouchableOpacity>
                  {!pulledFromDraft && <TouchableOpacity
                    style={styles.cancelButton}
                    onPress={handleLoadDraft}>
                    <Text style={styles.textStyle}>Load Draft</Text>
                  </TouchableOpacity>}
                </>
              }
              <TouchableOpacity
                style={[styles.button, { backgroundColor: viewSig ? colors.button_submit : colors.button_draft }]}
                onPress={() => {
                  if (!submitted) {
                    checkFields();
                  }
                }}
              >
                <Text style={styles.textStyle}>{viewSig ? "Submit" : "Save Draft"}</Text>
              </TouchableOpacity>

            </View>
          </View>
        </Scrollbars>
      </View>
    </ThemeProvider>
  );
}

CreateNewEBDL.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default CreateNewEBDL;
