/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable brace-style */
/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable linebreak-style */
/* eslint-disable require-jsdoc */
import React, { useEffect, useState, useCallback } from "react";
import { Button, Searchbar } from "react-native-paper";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Modal,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { useScale } from "../components/scale";

import { AntDesign } from "@expo/vector-icons";
import moment from "moment";

import { get_all_user_files, delete_single_file } from "../api_handler/files";
import {
  Pagination,
  Box,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import { useColorContext } from "../components/ColorContext";
import { generatePDF } from "./generatePDF.js";

import {
  getFileDate,
  formatRstDisplayDate,
  formatDateToMilDate,
} from "../components/FileDataFunctions.js";
import Scrollbars from "react-custom-scrollbars";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { Dimensions } from 'react-native';
import { useSnackbar } from "../components/SnackbarContext.js";
import * as Progress from 'react-native-progress';

function YourFiles({ route, navigation }) {
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  const scale = useScale();
  const { width, height } = Dimensions.get('window');
  const styles = {
    container: {
      height: "100%",
      width: "100%",
      flexDirection: "row",
      backgroundColor: colors.screen_background,
    },
    header: {
      marginTop: "0%",
      height: 110,
      backgroundColor: colors.screen_background,
    },
    main: {
      flex: 10,
      height: "100%",
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: "Trebuchet MS",
      flex: 1,
      color: colors.text,
      paddingLeft: "5%",
      paddingTop: 25,
    },
    description: {
      fontSize: 20,
      textAlign: "Left",
      paddingTop: 25,
      color: colors.bow,
      width: "50%",
      fontFamily: "Trebuchet MS",
      marginBottom: "2%",
    },
    contentBox: {
      backgroundColor: colors.content_box,
      height: "100%",
      width: "75%",
      marginTop: "2%",
      borderRadius: 10,
      justifyContent: "center",
      padding: "2.5%",
      borderColor: colors.border_color,
      borderRadius: 5,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    button: {
      height: 120,
      borderRadius: 10,
      padding: 30,
      paddingLeft: 40,
      paddingRight: 40,
      backgroundColor: colors.wob,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      flexDirection: "row",
      justifyContent: "center",
      flex: 1,
    },
    modalButton: {
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.modal_background,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: "center",
    },
    cancelButton: {
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.button,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: "center",
    },
    textStyle: {
      width: '65%',
      fontSize: 15,
      textAlign: "center",
      fontWeight: "bold",
      color: colors.text,
    },
    commentStyle: {
      width: '65%',
      fontSize: 15,
      color: colors.file_Denied,
      textAlign: "center",
      fontWeight: "bold",
    },
    textStyleNormal: {
      justifyContent: "center",
      fontSize: 15,
      color: colors.text,
    },
    backText: {
      justifyContent: "center",
      fontSize: 15,
      fontWeight: "bold",
      color: colors.text,
    },
    backButton: {
      height: 40,
      width: 160,
      borderRadius: 10,
      alignItems: "center",
      justifyContent: "center",
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      marginTop: "5%",
      backgroundColor: colors.button,
    },
    searchBar: {
      width: width < 1000 ? (width < 885 ? "100%" : "90%") : "75%",
      fontSize: 15,
      borderColor: colors.border_color,
      borderRadius: 5,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      marginLeft: width < 1000 ? (width < 885 ? "0%" : "10%") : "25%",
      backgroundColor: colors.modal_background,
      color: colors.text,
    },
    selected: {
      backgroundColor: colors.gold,
    },
    doc: {
      width: 40,
      height: 40,
    },
    checkbox: {
      backgroundColor: colors.modal_background,
      color: colors.gold_button_text,
      fontFamily: "Trebuchet MS",
      borderRadius: 10,
      justifyContent: "center",
      padding: "2.5%",
      width: "95%",
      borderColor: colors.border_color,
      borderRadius: "5px",
      borderWidth: "1px",
      borderStyle: "solid",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    },
    formControlLabel: {
      marginLeft: width < 1373 ? (width < 840 ? "0%" : "5%") : "10%",
      fontFamily: "Trebuchet MS",
      color: colors.gold_button_text,
    },
    formControLabelText: {
      fontFamily: "Trebuchet MS",
      fontSize: width < 1587 ? (width < 840 ? "x-small" : "small") : "medium",
    },
    filterButton: {
      width: "60%",
      fontFamily: "Trebuchet MS",
    },
    applyButton: {
      backgroundColor: colors.action_button,
      color: colors.gold_button_text,
      marginRight: width < 1440 ? "0%" : "5%",
      fontFamily: "Trebuchet MS",
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    clearButton: {
      backgroundColor: colors.modal_background,
      color: colors.gold_button_text,
      marginLeft: width < 1440 ? "0%" : "5%",
      fontFamily: "Trebuchet MS",
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    modalContent: {
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
    },
    modalBody: {
      height: "120px",
      marginTop: "11%",
      backgroundColor: colors.modal_background,
      borderRadius: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 30,
      paddingBottom: 30,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      width: "52.5%",
      justifyContent: "center",
      height: "fit-content",
      marginLeft: "21.75%",
    },

  };

  const [files, setFiles] = useState([]);
  const [selected, setSelected] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [searchedFiles, setSearchedFiles] = useState([]);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedFileInfo, setSelectedFileInfo] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsOpen] = useState({});
  const defaultFilters = [
    { id: 'Date Ascending', isChecked: false },
    { id: 'Date Descending', isChecked: false },
    { id: 'Form ID Ascending', isChecked: false },
    { id: 'Form ID Descending', isChecked: false },
    { id: 'Drafted', isChecked: false },
    { id: 'Submitted', isChecked: false },
    { id: 'Approved', isChecked: false },
    { id: 'Denied', isChecked: false },
    { id: 'Paid', isChecked: false },
    { id: 'Awaiting Review', isChecked: false },
    { id: 'Must Resubmit', isChecked: false },
    { id: 'dental', isChecked: false, isForm: true },
    { id: 'RST', isChecked: false, isForm: true },
    { id: '1380', isChecked: false, isForm: true },
    { id: '1380 aftp', isChecked: false, isForm: true },
    { id: 'ATA', isChecked: false, isForm: true },
    { id: 'EBDL', isChecked: false, isForm: true },
    { id: 'MDR', isChecked: false, isForm: true },
  ];
  const [modalFilters, setModalFilters] = useState(defaultFilters);
  const tempAllFiles = [];

  const toggleListItem = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the state of the list item
    }));
  };

  const toggleChecked = (id) => {
    setModalFilters((prevData) => {
      return prevData.map((entry) => {
        if (entry.id === id) {
          return { ...entry, isChecked: !entry.isChecked };
        }
        return entry;
      });
    });
  };

  const handleExclusiveCheck = (id) => {
    setModalFilters((prevFilters) => {
      const isChecked = prevFilters.find((filter) => filter.id === id)?.isChecked;

      return prevFilters.map((filter) => {
        if (
          filter.id === 'Date Ascending' ||
          filter.id === 'Date Descending' ||
          filter.id === 'Form ID Ascending' ||
          filter.id === 'Form ID Descending'
        ) {
          if (filter.id === id) {
            return {
              ...filter,
              isChecked: !isChecked,
            };
          } else {
            return {
              ...filter,
              isChecked: false,
            };
          }
        }
        return filter;
      });
    });
  };

  const checkItem = (id) => {
    return modalFilters.some((filter) => filter.id === id && filter.isChecked);
  };

  const theme = useTheme();
  const reviewTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });

  // For pagination
  const ITEM_LIMIT = 5;
  const [currentPage, setCurrentPage] = useState(1);
  // file filter menu

  async function getFiles() {
    setFiles([]);
    setLoaded(false);

    let files = await get_all_user_files(
      global.user_data.user_id,
      global.token,
    );

    if (files.status === 200) {
      files = files.data;
      const parsedFiles = files.body
        .map((file) => ({
          ...file,
          parsedDate: getFileDate(file),
        }))
        .sort((a, b) => b.form_id - a.form_id);
      let sortedFiles;

      if (route.params.loadingDraft && route.params.loadingDraft == true) {
        if (route.params.formType === '1380') sortedFiles = parsedFiles.filter((file) => file.status === 0 && file.form_type.includes("1380") && (!route.params.addedFormIDs || !route.params.addedFormIDs.includes(file.form_id))); // Lets us get 1380 and 1380 AFTP
        else sortedFiles = parsedFiles.filter((file) => file.status === 0 && file.form_type === route.params.formType && (!route.params.addedFormIDs || !route.params.addedFormIDs.includes(file.form_id)));
      } else if (route.params.from1380 == true || route.params.from1380 == "true") {
        const formTypeFilter = route.params.formType === '' ?
          (file) => !file.form_type.includes("1380") && file.request_type !== 'RST-EXCUSEDABSENCE' && file.request_type !== 'RST-RMA' && file.request_type !== 'RST-ATA' && (file.status === 1 || file.status === 4) && (!route.params.addedFormIDs || !route.params.addedFormIDs.includes(file.form_id)) :
          (file) => ((file.form_type == route.params.formType) || (route.params.formType === 'dental' && file.form_type === 'MDR')) && !file.form_type.includes("1380") && file.request_type !== 'RST-EXCUSEDABSENCE' && file.request_type !== 'RST-RMA' && file.request_type !== 'RST-ATA' && (file.status === 1 || file.status === 4) && (!route.params.addedFormIDs || !route.params.addedFormIDs.includes(file.form_id));

        sortedFiles = parsedFiles.filter(formTypeFilter);
      } else if (route.params.formType && route.params.formType !== '' && route.params.loadingDraft === false) {
        const formTypeFilter = (file) => ((file.form_type === route.params.formType) || (route.params.formType === 'dental' && file.form_type === 'MDR')) && !file.form_type.includes("1380") && file.request_type !== 'RST-EXCUSEDABSENCE' && (file.status === 1 || file.status === 4) && (!route.params.addedFormIDs || !route.params.addedFormIDs.includes(file.form_id));

        sortedFiles = parsedFiles.filter(formTypeFilter);
      } else {
        sortedFiles = parsedFiles;
      }

      if (sortedFiles.length === 0) {
        showSnackbar("warning", "You have no files");
      }

      setFiles(sortedFiles);
      setAllFiles(sortedFiles);
      setSelectedFileInfo({});
      setSearch("");
      setLoaded(true);
    } else {
      showSnackbar("error", "Application Error: Failed to retrieve your files");
      goBack();
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      async function getPageInfo() {
        await getFiles();
      }

      getPageInfo();
    }, []),
  );

  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredSearchResults, setFilteredSearchResults] = useState([]);

  useEffect(() => {
    getCurrentFiles();
  }, [currentPage, allFiles, filteredSearchResults]);

  const getCommentForFormType = useCallback((form) => {
    switch (form.form_type) {
      case 'RST': return form.rst_approver_comment;
      case 'EBDL': return form.ebdl_approver_comment;
      case '1380': 
        if (form.approver_comment_1380) {
          return form.approver_comment_1380;
        } else {
          return form.validator_comments;
        }
      case '1380 aftp': return form.approver_comment_1380;
      case 'ATA': return form.ata_approver_comment;
      case 'MDR': return form.mdr_approver_comment;
      default: return '';
    }
  }, []);

  const updateSearch = (search) => {
    setSearch(search);
    if (search === "") {
      if (isFiltered) {
        setFilteredSearchResults([]);
      } else {
        setAllFiles(files);
      }
    } else {
      const searchFiles = isFiltered ? allFiles : files;
      const searchResults = searchFiles.filter((file) => {
        return (
          file.form_type.toLowerCase().includes(search.toLowerCase()) ||
          file.request_type.toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredSearchResults(searchResults);
      setIsFiltered(true);
    }
  };

  const getCurrentFiles = () => {
    const offset = (currentPage - 1) * ITEM_LIMIT;
    const filesToDisplay = search ? filteredSearchResults : allFiles;
    const currentFiles = filesToDisplay.slice(offset, offset + ITEM_LIMIT);
    setSearchedFiles(currentFiles);
  };

  const editDraft = async () => {
    setShowModal(false);
    const ebdlData = {
      selectedType: selectedFileInfo.filetype,
      form_id: selectedFileInfo.id,
      form_type: selectedFileInfo.filetype,
    };
    if (selectedFileInfo.filetype == 'ATA' || selectedFileInfo.filetype == 'EBDL' || selectedFileInfo.filetype == 'MDR') {
      navigation.navigate('CreateNewEBDL', ebdlData);
    }
    else if (selectedFileInfo.filetype == 'RST') navigation.navigate('CreateNewRST', { draftFormID: selectedFileInfo.id });
    else if (selectedFileInfo.filetype == '1380' || selectedFileInfo.filetype == '1380 aftp') navigation.push('New1380', { draftFormID: selectedFileInfo.id, is1380AFTP: selectedFileInfo.filetype == '1380 aftp' });
  };

  const amendForm = async () => {
    setShowModal(false);
    if (selectedFileInfo.filetype === 'EBDL' || selectedFileInfo.filetype === 'ATA' || selectedFileInfo.filetype === 'MDR') {
      const ebdlData = {
        selectedType: selectedFileInfo.filetype,
        form_id: selectedFileInfo.id,
        form_type: selectedFileInfo.filetype,
        updatingSubmission: true,
      };
      navigation.navigate('CreateNewEBDL', ebdlData);
    }

    else if (selectedFileInfo.filetype === 'RST') {
      navigation.navigate('CreateNewRST', { draftFormID: selectedFileInfo.id, updatingSubmission: true });
    }

    else if (selectedFileInfo.filetype === '1380' || selectedFileInfo.filetype === '1380 aftp') {
      navigation.push('New1380', { draftFormID: selectedFileInfo.id, is1380AFTP: selectedFileInfo.filetype === '1380 aftp', updatingSubmission: true });
    }
  };

  const deleteForm = async () => {
    try {
      setLoaded(false);
      const res = await delete_single_file({ form_id: selectedFileInfo.id }, global.token);
      if (res.status !== 200) {
        showSnackbar("error", "Application Error: Failed to delete the selected form");
        setLoaded(true);
        return;
      }

      const updatedFiles = files.filter(
        (file) => file.form_id !== selectedFileInfo.id,
      );
      setFiles(updatedFiles);

      if (isFiltered) {
        const updatedAllFiles = allFiles.filter(
          (file) => file.form_id !== selectedFileInfo.id,
        );
        setAllFiles(updatedAllFiles);

        const updatedFilteredSearchResults = filteredSearchResults.filter(
          (file) => file.form_id !== selectedFileInfo.id,
        );
        setFilteredSearchResults(updatedFilteredSearchResults);

        const offset = (currentPage - 1) * ITEM_LIMIT;
        const currentFiles = updatedFilteredSearchResults.slice(
          offset,
          offset + ITEM_LIMIT,
        );
        setSearchedFiles(currentFiles);
      } else {
        setAllFiles(updatedFiles);

        const offset = (currentPage - 1) * ITEM_LIMIT;
        const currentFiles = updatedFiles.slice(offset, offset + ITEM_LIMIT);
        setSearchedFiles(currentFiles);
      }
      setLoaded(true);
    } catch (error) {
      console.error("Error Deleting File: " + error);
      setLoaded(true);
    }
  };

  const goBack = () => {
    navigation.navigate("Home");
  };

  const handlePage = (event, page) => {
    setCurrentPage(page);
  };


  const loadingStyles = {
    animation: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      paddingVertical: 20,
      background: colors.screen_background,
    },
  };

  if (!loaded) {
    return (
      <View style={loadingStyles.animation}>
        <Progress.CircleSnail
          color={colors.loading_circle}
          indeterminate={true}
          size={275}
          thickness={8}
        />
      </View>
    );
  };

  const updateFilter = () => {
    setSearch('');
    let filteredFiles = [...files];

    // AND search for date and form ID filters
    const dateFilter = modalFilters.find(
      (item) =>
        (item.id === 'Date Ascending' || item.id === 'Date Descending') &&
        item.isChecked,
    );
    const formIDFilter = modalFilters.find(
      (item) =>
        (item.id === 'Form ID Ascending' || item.id === 'Form ID Descending') &&
        item.isChecked,
    );

    if (dateFilter) {
      filteredFiles = filteredFiles
        .map((file) => ({
          ...file,
          parsedDate: getFileDate(file),
        }))
        .sort((a, b) =>
          dateFilter.id === 'Date Ascending' ?
            a.parsedDate - b.parsedDate :
            b.parsedDate - a.parsedDate,
        );
    }

    if (formIDFilter) {
      filteredFiles = filteredFiles.sort((a, b) =>
        formIDFilter.id === 'Form ID Ascending' ?
          a.form_id - b.form_id :
          b.form_id - a.form_id,
      );
    }

    // OR search for status filters
    const statusFilters = modalFilters.filter(
      (item) =>
        [
          'Drafted',
          'Submitted',
          'Awaiting Review',
          'Must Resubmit',
          'Approved',
          'Denied',
          'Paid',
        ].includes(item.id) && item.isChecked,
    );

    if (statusFilters.length > 0) {
      filteredFiles = filteredFiles.filter((file) =>
        statusFilters.some((filter) => {
          switch (filter.id) {
            case 'Drafted':
              return file.status === 0;
            case 'Submitted':
              return file.status === 1;
            case 'Awaiting Review':
              return file.status === 2;
            case 'Must Resubmit':
              return file.status === 3;
            case 'Approved':
              return file.status === 4;
            case 'Denied':
              return file.status === 5;
            case 'Paid':
              return file.status === 6;
            default:
              return false;
          }
        }),
      );
    }

    // OR search for form type filters
    const formTypeFilters = modalFilters.filter(
      (item) => item.isForm && item.isChecked,
    );

    if (formTypeFilters.length > 0) {
      filteredFiles = filteredFiles.filter((file) =>
        formTypeFilters.some((filter) => file.form_type === filter.id),
      );
    }
    setCurrentPage(1);
    setAllFiles(filteredFiles);
    setIsFiltered(true);
  };

  const clearFilter = () => {
    setModalFilters(defaultFilters);
    setAllFiles(files);
    setFilteredSearchResults([]);
    setIsFiltered(false);
    setSearch('');
    setCurrentPage(1);
  };

  return (
    <ThemeProvider theme={reviewTheme}>
      <View style={styles.container}>
        {/* <NavSideBar /> */}
        <Scrollbars style={styles.main}>
          <View style={{ flexDirection: "column" }}>
            <Text style={styles.pageTitle}>{(route.params.from1380 || route.params.loadingDraft) ? 'Add Document' : 'Your Files'}</Text>
            <View
              style={{
                flexDirection: "row",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              <Text style={{ ...styles.description }}>
                {route.params.from1380 ? 'Add a document to the 1380.' : route.params.loadingDraft ? 'Select a draft form to load.' : 'View/Delete RST, Dental, 1380, ATA, EBDL, and MDR forms.'}
              </Text>

              <View
                style={{
                  flex: 2,
                  marginRight: "2.5%",
                  paddingTop: width < 1368 ? "20px" : "12.5px",
                }}
              >

                {((!route.params.loadingDraft || route.params.loadingDraft === false || (route.params.loadingDraft === true && route.params.which1380Page)) && (!route.params.formType)) && <Searchbar
                  style={styles.searchBar}
                  placeholder="Search by File Type"
                  onChangeText={updateSearch}
                  placeholderTextColor={colors.bow}
                  iconColor={colors.bow}
                  inputStyle={{ color: colors.bow }}
                  value={search}
                  onClear={updateSearch}
                />}
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              <View style={styles.contentBox}>
                {searchedFiles.map((file, index) => {
                  return (
                    <FileSelect
                      key={index}
                      id={file.form_id}
                      comment={getCommentForFormType(file)}
                      name={file.form_name}
                      date={file.timestamp}
                      excusedAbsenceBADate={file.excused_absence_ba_date}
                      rstPerformedOn={file.rst_performed_on}
                      rmaDate={file.rma_date}
                      ataDate={file.ata_date}
                      selected={selected}
                      status={file.status}
                      style={styles.item}
                      filename={file.form_name}
                      filetype={file.form_type}
                      navigation={navigation}
                      requestType={file.request_type}
                      timestamp_msg={file.timestamp_string}
                      attachments={file.attachments}
                      date_9a={file.date_9a}
                      ata_dates={file.ata_dates}
                      ebdl_dates={file.ebdl_dates}
                      mdr_dates={file.mdr_dates}
                    />
                  )
                })}
              </View>
              <View
                style={{
                  flexDirection: "column",
                  flex: 2,
                  marginLeft: "2.5%",
                  marginRight: "2.5%",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "2%",
                }}
              >
                <List style={styles.checkbox}>
                  <ListItemButton onClick={() => toggleListItem("Date Submitted")}>
                    <ListItemText
                      primary="Date Submitted"
                      primaryTypographyProps={{
                        fontFamily: "Trebuchet MS",
                        fontSize: width < 973 ? (width < 840 ? 'small' : 'medium') : 'large',
                      }}
                    />
                    {isOpen["Date Submitted"] ? (<ExpandLess />) : (<ExpandMore />)}
                  </ListItemButton>
                  <Collapse
                    in={isOpen["Date Submitted"]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("Date Ascending")}
                              onChange={() =>
                                handleExclusiveCheck("Date Ascending")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              Date Ascending
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("Date Descending")}
                              onChange={() =>
                                handleExclusiveCheck("Date Descending")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              Date Descending
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                      </FormGroup>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={() => toggleListItem("form_id")}>
                    <ListItemText
                      primary="Form ID"
                      primaryTypographyProps={{
                        fontFamily: "Trebuchet MS",
                        fontSize: width < 973 ? (width < 840 ? 'small' : 'medium') : 'large',
                      }}
                    />
                    {isOpen["form_id"] ? (<ExpandLess />) : (<ExpandMore />)}
                  </ListItemButton>
                  <Collapse
                    in={isOpen["form_id"]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("Form ID Ascending")}
                              onChange={() =>
                                handleExclusiveCheck("Form ID Ascending")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              Form ID Ascending
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkItem("Form ID Descending")}
                              onChange={() =>
                                handleExclusiveCheck("Form ID Descending")
                              }
                            />
                          }
                          label={
                            <Typography style={styles.formControLabelText}>
                              Form ID Descending
                            </Typography>
                          }
                          style={styles.formControlLabel}
                        />
                      </FormGroup>
                    </List>
                  </Collapse>
                  {!route.params.formType && route.params.formType !== '' && !route.params.from1380 && (<><ListItemButton onClick={() => toggleListItem("status")}>
                    <ListItemText
                      primary="Status"
                      primaryTypographyProps={{
                        fontFamily: "Trebuchet MS",
                        fontSize: width < 973 ? (width < 840 ? 'small' : 'medium') : 'large',
                      }} />
                    {isOpen["status"] ? (<ExpandLess />) : (<ExpandMore />)}
                  </ListItemButton><Collapse
                    in={isOpen["status"]}
                    timeout="auto"
                    unmountOnExit
                  >
                      <List>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("Drafted")}
                              onChange={() => toggleChecked("Drafted")} />}
                            label={<Typography style={styles.formControLabelText}>
                              Drafted
                            </Typography>}
                            style={styles.formControlLabel} />
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("Submitted")}
                              onChange={() => toggleChecked("Submitted")} />}
                            label={<Typography style={styles.formControLabelText}>
                              Submitted
                            </Typography>}
                            style={styles.formControlLabel} />
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("Approved")}
                              onChange={() => toggleChecked("Approved")} />}
                            label={<Typography style={styles.formControLabelText}>
                              Approved
                            </Typography>}
                            style={styles.formControlLabel} />
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("Denied")}
                              onChange={() => toggleChecked("Denied")} />}
                            label={<Typography style={styles.formControLabelText}>
                              Denied
                            </Typography>}
                            style={styles.formControlLabel} />
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("Paid")}
                              onChange={() => toggleChecked("Paid")} />}
                            label={<Typography style={styles.formControLabelText}>
                              Paid
                            </Typography>}
                            style={styles.formControlLabel} />
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("Must Resubmit")}
                              onChange={() => toggleChecked("Must Resubmit")} />}
                            label={<Typography style={styles.formControLabelText}>
                              Must Resubmit
                            </Typography>}
                            style={styles.formControlLabel} />
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("Awaiting Review")}
                              onChange={() => toggleChecked("Awaiting Review")} />}
                            label={<Typography style={styles.formControLabelText}>
                              Awaiting Review
                            </Typography>}
                            style={styles.formControlLabel} />
                        </FormGroup>
                      </List>
                    </Collapse><ListItemButton onClick={() => toggleListItem("form_type")}>
                      <ListItemText
                        primary="Form Type"
                        primaryTypographyProps={{
                          fontFamily: "Trebuchet MS",
                          fontSize: width < 973 ? (width < 840 ? 'small' : 'medium') : 'large',
                        }} />
                      {isOpen["form_type"] ? (<ExpandLess />) : (<ExpandMore />)}
                    </ListItemButton><Collapse
                      in={isOpen["form_type"]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("dental")}
                              onChange={() => toggleChecked("dental")} />}
                            label={<Typography style={styles.formControLabelText}>
                              Dental
                            </Typography>}
                            style={styles.formControlLabel} />
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("RST")}
                              onChange={() => toggleChecked("RST")} />}
                            label={<Typography style={styles.formControLabelText}>
                              RST
                            </Typography>}
                            style={styles.formControlLabel} />
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("1380")}
                              onChange={() => toggleChecked("1380")} />}
                            label={<Typography style={styles.formControLabelText}>
                              1380
                            </Typography>}
                            style={styles.formControlLabel} />
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("1380 aftp")}
                              onChange={() => toggleChecked("1380 aftp")} />}
                            label={<Typography style={styles.formControLabelText}>
                              1380 AFTP
                            </Typography>}
                            style={styles.formControlLabel} />
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("ATA")}
                              onChange={() => toggleChecked("ATA")} />}
                            label={<Typography style={styles.formControLabelText}>
                              ATA
                            </Typography>}
                            style={styles.formControlLabel} />
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("EBDL")}
                              onChange={() => toggleChecked("EBDL")} />}
                            label={<Typography style={styles.formControLabelText}>
                              EBDL
                            </Typography>}
                            style={styles.formControlLabel} />
                          <FormControlLabel
                            control={<Checkbox
                              checked={checkItem("MDR")}
                              onChange={() => toggleChecked("MDR")} />}
                            label={<Typography style={styles.formControLabelText}>
                              MDR
                            </Typography>}
                            style={styles.formControlLabel} />
                        </FormGroup>
                      </List>
                    </Collapse></>
                  )}
                </List>
                <View
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: "5%",
                    marginBottom: "5%",
                    justifyContent: "center",
                    flexWrap: width < 1440 ? "wrap" : "nowrap",
                  }}
                >
                  <Button
                    color={colors.gold_button_text}
                    style={[styles.filterButton, styles.applyButton]}
                    onPress={updateFilter}
                    titleStyle
                  >
                    <Text style={{ fontSize: width < 972 ? 9 : 15 }}>
                      Apply
                    </Text>
                  </Button>
                  <Button
                    color={colors.gold_button_text}
                    style={[styles.filterButton, styles.clearButton]}
                    onPress={clearFilter}
                  >
                    <Text style={{ fontSize: width < 972 ? 9 : 15 }}>
                      Clear
                    </Text>
                  </Button>
                </View>
              </View>
            </View>

            <Box
              display="flex"
              justifyContent="center"
              marginTop={2}
              paddingRight="20%"
            >
              <Pagination
                count={Math.ceil(
                  search ? filteredSearchResults.length / ITEM_LIMIT : allFiles.length / ITEM_LIMIT,
                )}
                shape="rounded"
                variant="outlined"
                page={currentPage}
                onChange={handlePage}
                color="primary"
              />
            </Box>
          </View>
        </Scrollbars>
      </View>
    </ThemeProvider>
  );

  function FileSelect({
    id,
    filetype,
    filename,
    requestType,
    date,
    comment,
    timestamp_msg,
    status,
    navigation,
    attachments,
    excusedAbsenceBADate,
    rstPerformedOn,
    rmaDate,
    ataDate,
    date_9a,
    ata_dates,
    ebdl_dates,
    mdr_dates,
  }) {
    const docs = {
      0: require("../assets/doc_blue.png"),
      1: require("../assets/doc_yellow.png"),
      2: require("../assets/approved_rec_doc.png"),
      3: require("../assets/disapproved_rec_doc.png"),
      4: require("../assets/doc_green.png"),
      5: require("../assets/doc_red.png"),
      6: require("../assets/doc_paid.png"),
      7: require("../assets/corrupted_doc.png"),
      8: require("../assets/recommend_doc.png"),
    };

    const status_names = {
      0: "\nStatus: Drafted ",
      1: "\nStatus: Submitted ",
      2: "\nStatus: Awaiting Review ",
      3: "\nStatus: Must Resubmit ",
      4: "\nStatus: Approved ",
      5: "\nStatus: Denied ",
      6: "\nStatus: Paid ",
      7: "\nFile Corrupted as of ",
      8: "\nStatus: Awaiting Recommendation ",
    };

    const statusDate = formatDateToMilDate(new Date(date[0]).toLocaleDateString());
    // Decide which date to use
    let displayDate;
    if (filetype.includes("RST")) {
      if (excusedAbsenceBADate === '' && rmaDate === '' && ataDate === '') {
        const dates = rstPerformedOn.split(', ').map((dateStr) => {
          // Extract the date part "DDMMMYY"
          const match = dateStr.match(/\d{2}[A-Z]{3}\d{2,4}/);
          if (match) {
            const datePart = match[0];
            if (dateStr.length === 7) {
              dateStr = dateStr.slice(0, 5) + 20 + dateStr.slice(5);
            }
            // Pass the date part to formatRstDisplayDate and convert to moment object
            const formattedDate = moment(formatRstDisplayDate(datePart), 'DDMMMYYYY').format('DDMMMYYYY').toUpperCase();
            // Reconstruct the date string with the formatted date and quantity
            return formattedDate;
          } else {
            // If the date format is not matched, return the original date string
            return dateStr;
          }
        });
        displayDate = dates.join(', ');
      } else {
        displayDate = moment(formatRstDisplayDate(excusedAbsenceBADate || rmaDate || ataDate), 'DDMMMYYYY').format('DDMMMYYYY').toUpperCase();
      }
    } else {
      displayDate = moment(new Date(date[date.length - 1]))
        .format("DDMMMYYYY")
        .toUpperCase();
    }

    if (filetype.includes("1380")) {
      if (date_9a.length > 0) {
        console.log(date_9a)
        if (filetype.includes("aftp")){
          // split the received dates by commas
          const numDates = date_9a.length;

          const aftp_date_1 = date_9a[0] !== null ? date_9a[0] : "TBD";
          const aftp_date_2 = numDates > 1 && date_9a[1] !== null ? date_9a[1] : "TBD";
          let formattedDates = [];

          if(aftp_date_1 !== 'TBD'){
            formattedDates.push(
              moment(new Date(aftp_date_1)).format("DDMMMYYYY").toUpperCase()
            );
          } else {
            formattedDates.push(aftp_date_1)
          }

          if(numDates > 1){
            if(aftp_date_2 !== 'TBD'){
              formattedDates.push(
                moment(new Date(aftp_date_2)).format("DDMMMYYYY").toUpperCase()
              );
            } else {
              formattedDates.push(aftp_date_2)
            }
          }

          // set displayDates to the joining of formattedDates with commas
          displayDate = formattedDates.join(", ");
      } else {
        // split the received dates by commas
        const dates = date_9a.map((dateStr) => dateStr.trim().split(","));
        let formattedDates = [];

        for (let i = 0; i < dates.length; i++) {
          // add formatted dates to array
          formattedDates.push(
            moment(new Date(dates[i])).format("DDMMMYYYY").toUpperCase()
          );
        }

        // set displayDates to the joining of formattedDates with commas
        displayDate = formattedDates.join(", ");
      }
    } else {
      displayDate = "";
    }
  }

    if (filetype.includes("ATA")) {
      displayDate = ata_dates;
    }

    if (filetype.includes("EBDL")) {
      displayDate = ebdl_dates;
    }

    if (filetype.includes("MDR")) {
      displayDate = mdr_dates;
    }

    // Generates the Base64 data to return back to 1380 page
    const onPressHandler = async () => {
      // Generate the PDF
      setLoaded(false);
      const generatedData = await generatePDF(
        id,
        filetype,
        navigation,
        true,
        false,
        attachments,
        global.user_data.user_id,
      );
      setLoaded(true);

      const data = {
        form_id: id,
        form_type: filetype,
        from1380: false,
        events: route.params.events,
        addedFormIDs: route.params.addedFormIDs,
        uploadFiles: route.params.uploadFiles,
        form_id_1380: draftFormID
      };

      const ebdlData = {
        selectedType: route.params.formType,
        form_id: id,
        form_type: filetype,
      };

      const draftFormID = route.params.draftFormID ?? '';
      const is1380AFTP = filetype == '1380 aftp';

      if (generatedData !== null && route.params.from1380 == true) {
        navigation.push('Combined1380', { data: data, form_id: id, form_type: filetype, form_id_1380: draftFormID, draftFormID: draftFormID, is1380AFTP: is1380AFTP, events: route.params.events, addedFormIDs: route.params.addedFormIDs, uploadFiles: route.params.uploadFiles, modifyDoc: route.params.modifyDoc, modifyingID: route.params.modifyingID, validatorIds: route.params.validatorIds ,validatorOption: route.params.validatorOption });
      } else if (generatedData !== null && route.params.loadingDraft == true) {
        if (route.params.formType == 'ATA' || route.params.formType == 'EBDL' || route.params.formType == 'MDR') {
          navigation.navigate('CreateNewEBDL', ebdlData);
        }
        else if (route.params.formType == 'RST') navigation.navigate('CreateNewRST', { draftFormID: id });
        else if (route.params.formType == '1380') {
          if (route.params.which1380Page == 'new') navigation.push('New1380', { draftFormID: id, is1380AFTP: is1380AFTP });
          // else if (route.params.which1380Page == 'combined') navigation.navigate('Combined1380', {draftFormID: id, is1380AFTP: is1380AFTP});
        }
      } else {
        showSnackbar("error", "Application Error: Failed to retrieve the selected file");
        navigation.push('Combined1380', data);
      }
    };

    return (
      <View>
        <TouchableOpacity
          style={[
            styles.button,
            {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            },
          ]}
          onPress={() => {
            if (route.params.from1380 == true || route.params.loadingDraft) {
              onPressHandler();
            } else {
              setSelectedFileInfo({
                id: id,
                filetype: filetype,
                attachments: attachments,
                navigation: navigation,
                status: status,
                author: global.user_data.user_id,
              });
              setShowModal(true);
            }
          }}
        >
          <Image style={styles.doc} defaultSource={docs[displayDate === "Invalid Date" ? 7 : status]} />
          <Text style={styles.textStyle}>
            {filename + " (" + id + ")" + "\n" + requestType + "\n Date(s): " + displayDate}
            {comment?.includes("PAY ADMIN")  
              ? "\n Status: Returned For Correction " + statusDate + "\n" 
              : status_names[displayDate === "Invalid Date" ? 7 : status] + statusDate + "\n"}
            {(comment && comment !== undefined && comment !== "") && (
              <Text style={status != 5 ? styles.textStyle : styles.commentStyle}>
                Comment: {comment}
              </Text>
            )}

          </Text>
          <AntDesign name="right" size={14} color={colors.bow} />
        </TouchableOpacity>

        <Modal
          animationType="none"
          transparent={true}
          visible={showModal}
          onRequestClose={() => {
            setShowModal(false);
          }}
        >
          <TouchableWithoutFeedback
            onPress={() => {
              setShowModal(false);
            }}
          >
            <View style={{ flex: 1, backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
              <View style={styles.modalBody}>
                {deleteConfirmation ? (
                  <View style={styles.modalContent}>
                    <Text style={[styles.textStyle, { width: '100%' }]}>
                      {" "}
                      Are you sure you want to delete?{" "}
                    </Text>
                    <Text style={[styles.textStyle, { width: '100%' }]}>
                      {" "}
                      This cannot be undone{" "}
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <TouchableOpacity
                        style={styles.modalButton}
                        onPress={() => {
                          deleteForm(id);
                          setShowModal(!showModal);
                          setDeleteConfirmation(false);
                        }}
                      >
                        <Text style={styles.textStyleNormal}>Yes</Text>
                      </TouchableOpacity>

                      <TouchableOpacity
                        style={styles.cancelButton}
                        onPress={() => {
                          setShowModal(!showModal);
                          setDeleteConfirmation(false);
                        }}
                      >
                        <Text style={styles.textStyleNormal}>No</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                ) : (
                  <View style={styles.modalContent}>
                    <Text style={[styles.textStyle, { width: '100%' }]}>
                      {" "}
                      Select Action for this File{" "}
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <TouchableOpacity
                        style={[styles.modalButton, { backgroundColor: colors.content_box }]}
                        onPress={() => {
                          generatePDF(
                            selectedFileInfo.id,
                            selectedFileInfo.filetype,
                            route.navigation,
                            false,
                            false,
                            selectedFileInfo.attachments,
                            global.user_data.user_id,
                          );
                          setShowModal(!showModal);
                        }}
                      >
                        <Text style={styles.textStyleNormal}>View PDF</Text>
                      </TouchableOpacity>

                      {
                        // only drafted/pending/submitted forms can be deleted
                        selectedFileInfo.status === 0 ||
                          selectedFileInfo.status === 1 ||
                          selectedFileInfo.status === 2 ? (
                          <TouchableOpacity
                            style={styles.modalButton}
                            onPress={() => {
                              setDeleteConfirmation(true);
                            }}
                          >
                            <Text style={styles.textStyleNormal}>
                              Delete File
                            </Text>
                          </TouchableOpacity>
                        ) : null
                      }

                      {
                        // Only submitted and denied forms can be amended
                        selectedFileInfo.status === 1 || selectedFileInfo.status === 2 || selectedFileInfo.status === 3 || selectedFileInfo.status === 5 ? (
                          <TouchableOpacity
                            style={styles.modalButton}
                            onPress={amendForm}
                          >
                            <Text style={styles.textStyleNormal}>
                              Amend File
                            </Text>
                          </TouchableOpacity>
                        ) : null
                      }

                      {
                        // Only submitted and denied forms can be amended
                        selectedFileInfo.status === 0 ? (
                          <TouchableOpacity
                            style={styles.modalButton}
                            onPress={editDraft}
                          >
                            <Text style={styles.textStyleNormal}>
                              Edit Draft
                            </Text>
                          </TouchableOpacity>
                        ) : null
                      }

                      <TouchableOpacity
                        style={styles.cancelButton}
                        onPress={() => {
                          setShowModal(!showModal);
                        }}
                      >
                        <Text style={styles.textStyleNormal}>Cancel</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </View>
    );
  }
}

export default YourFiles;
