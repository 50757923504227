/* eslint-disable guard-for-in */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable require-jsdoc */
import React, { useEffect, useState, useRef } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, Modal, Dimensions } from 'react-native';
import { TextInput, Checkbox, HelperText } from 'react-native-paper';
import { ClickAwayListener } from '@mui/base';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import moment from 'moment';

import { useScale } from '../../components/scale';
import { upload_file, update_file, get_single_file, get_image_s3, put_s3 } from '../../api_handler/files';
import { create_notification } from '../../api_handler/notifications';
import { get_all_users_in_single_unit, get_units_for_1380 } from '../../api_handler/units';
import { pdfIn, decodeBase64, generate_rst, generate_dental, generate_ata, generate_ebdl, generate_mdr, generate_1380 } from '../generatePDF';
import * as form_1380 from '../../PDFs/1380_form_raw';
import { Alert } from 'react-native-web';
import { useIsFocused } from '@react-navigation/native';
import { useColorContext } from '../../components/ColorContext';
import NavSideBar from '../../components/NavSideBar';
import Scrollbars from 'react-custom-scrollbars';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider, useTheme, styled } from '@mui/material/styles';
import { useSnackbar } from '../../components/SnackbarContext';
import FormHelperText from '@mui/material/FormHelperText';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import TextField from '@mui/material/TextField';
import ViewShot from 'react-native-view-shot';
import { captureRef } from 'react-native-view-shot';
import { create_new_user_signature } from '../../api_handler/users.js';
import { put_image_s3 } from '../../api_handler/files';
import { storeJsonData } from '../../api_handler/asyncstorage.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SetMeal, Upload } from '@mui/icons-material';
import dayjs from 'dayjs';
import { formatDateToMilDate } from '../../components/FileDataFunctions.js';
import * as Progress from 'react-native-progress';
import CustomRadioWithBackground from '../../components/CustomRadioWithBackground';
import CustomAutocomplete from "../../components/AutocompleteDropdown.js";


function CreateCombined1380({ route, navigation }) {
  const { colors } = useColorContext();
  const { showSnackbar } = useSnackbar();
  const isFocused = useIsFocused();
  const datePickerRef = useRef(null);

  const scale = useScale();
  const styles = StyleSheet.create({
    container: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      backgroundColor: colors.screen_background,
    },
    wrapper: {
      flexDirection: 'row',
      height: '100%',
      width: '100%',
    },
    scrollView: {
      height: '100%',
      width: '100%',
    },
    mainWrapper: {
      flexDirection: 'column',
      alignSelf: 'center',
    },
    pageTitle: {
      fontSize: scale(30),
      fontFamily: 'Trebuchet MS',
      flex: 1,
      color: colors.text,
      paddingLeft: '5%',
      paddingTop: 25,
    },
    description: {
      fontFamily: 'Trebuchet MS',
      fontSize: scale(18),
      color: colors.text,
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    signText: {
      fontWeight: 'bold',
      fontSize: scale(14),
      textAlign: 'center',
      margin: '1%',
      marginBottom: '0%',
      paddingLeft: '5%',
      paddingRight: '5%',
      color: colors.bow,
      alignSelf: 'center',
    },
    button: {
      color: colors.text,
      width: '13%',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.grey,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      textAlign: 'center',
      alignItems: 'center',
    },
    cancelButton: {
      color: colors.text,
      width: '13%',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.modal_background,
      margin: 8,
      marginBottom: 15,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      alignItems: 'center',
      textAlign: 'center',
    },
    signDocButton: {
      color: colors.text,
      width: '13%',
      borderRadius: 10,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: colors.action_button,
      borderColor: colors.border_color,
      borderWidth: 1,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      textAlign: 'center',
      alignItems: 'center',
      marginTop: '1%',
      marginBottom: '1%',
    },
    modal: {
      margin: 20,
      marginTop: '10%',
      backgroundColor: colors.modal_background,
      borderRadius: 20,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 10,
      paddingBottom: 10,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      width: '50%',
      justifyContent: 'center',
      height: 'fit-content',
      marginLeft: '20%',
    },
    modalText: {
      color: colors.text,
      width: '80%',
      fontSize: scale(20),
      fontWeight: 'bold',
      textAlign: 'center',
    },
    textStyle: {
      justifyContent: 'center',
      fontSize: 15,
      fontWeight: 'bold',
      color: colors.text,
    },
    textStyleNormal: {
      justifyContent: 'center',
      fontSize: 15,
      fontFamily: 'Trebuchet MS',
      color: colors.text,
    },
    dropDownStyle: {
      borderColor: colors.bow,
      borderWidth: 1,
      borderRadius: 5,
      width: '100%',
      marginTop: '1%',
      backgroundColor: colors.modal_background,
    },
    commentInput: {
      minHeight: 'fit-content',
      borderColor: colors.border_color,
      borderWidth: 1,
      padding: 3,
      borderRadius: 1,
      fontSize: 16,
      backgroundColor: colors.textfield_background,
      width: '100%',
      color: colors.text,
    },
    contentContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: 1,
    },
    checkboxContainer: {
      flexDirection: 'row',
      fontSize: 18,
      alignItems: 'center',
      flex: 1,
    },
    inputSplitLocked: {
      backgroundColor: colors.screen_background,
      flex: 1,
    },
    inputSplit: {
      backgroundColor: colors.screen_background,
      flex: 1,
    },
    entryBox: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: 5,
      paddingLeft: 5,
      paddingRight: 5,
      width: isColumn ? '100%' : '95%',
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
    },
    selectProps: {
      MenuProps: {
        sx: {
          '& .MuiMenu-paper': {
            background: colors.textfield_background,
            color: colors.inputted_text,
          },
        },
      }
    },
    textInputProps: {
      '& .MuiFormLabel-root, & label.Mui-focused, & .MuiInputBase-input': {
        color: colors.inputted_text,
      },
      '.MuiSvgIcon-root': {
        fill: colors.inputted_text,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.border_color,
        },
        '&:hover fieldset': {
          borderColor: colors.border_color,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.border_color,
        },
      },
      '&  .MuiFormHelperText-root': {
        backgroundColor: colors.screen_background,
        margin: 0,
        paddingLeft: 1,
      },
    },
    fileContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 10,
    },
    hoursPointsInput: {
      width: '100%',
      marginTop: '1%',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flex: 1,
      minHeight: 78,
    },
    inputSplitLocked: {
      backgroundColor: colors.textfield_background_locked,
      borderColor: colors.border_color,
    },
    inputSplit: {
      backgroundColor: colors.textfield_background,
      borderColor: colors.border_color,
    },
    tableDiv: {
      width: '95%',
      borderColor: colors.border_color,
      borderWidth: 1,
      borderRadius: 5,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      backgroundColor: colors.modal_background,
    },
    rowContainer: {
      flexDirection: 'row',
      marginTop: 15,
      marginBottom: 10,
      justifyContent: 'center',
    }
  });

  const [formID, setFormID] = useState(route.params?.form_id_1380 > 0 ? route.params?.form_id_1380 : 0)
  const [loaded, setLoaded] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const [officers, setOfficers] = useState([{ name: 'No Officers', user_id: '0' }]);

  const [reviewer, setReviewer] = useState('');
  const [reviewerEmptyError, setReviewerEmptyError] = useState(false);

  const [reviewerIds, setReviewerIds] = useState([])
  const [validatorIds, setValidatorIds] = useState(route.params?.validatorIds ?? []);

  const [validator, setValidator] = useState('');
  const [validatorOption, setValidatorOption] = useState(route.params?.validatorOption ?? []);
  const [showValidatorSig, setShowValidatorSig] = useState(false);
  const [validatorSigId, setValidatorSigId] = useState(null);
  const [validatorEmptyError, setValidatorEmptyError] = useState(false);

  const [validatorSelectedOption, setValidatorSelectedOption] = useState({ user_id: 0, unit_id: 0, name: '' });
  const [reviewerSelectedOption, setReviewerSelectedOption] = useState({ user_id: 0, unit_id: 0, name: '' })

  const [resetSearchQuery, setResetSearchQuery] = useState(false);
  const [selected, setSelected] = useState(0);
  const [entryExists, setEntryExists] = useState(false);
  const [events, setEvents] = useState([]);
  const [viewSig, setViewSig] = useState(false);
  const [eventDate, setEventDate] = useState(null);
  const [formattedEventDate, setFormattedEventDate] = useState(null);
  const [toBePerformedOnDatesEmptyError, settoBePerformedOnDatesEmptyError] = useState(false);
  const [alreadyUsedDateError, setAlreadyUsedDateError] = useState(false);
  const [importType, setImportType] = useState('');
  const [hours, setHours] = useState('');
  const [hoursEmptyError, sethoursEmptyError] = useState(false);
  const [points, setPoints] = useState('');
  const [pointsEmptyError, setpointsEmptyError] = useState(false);
  const [duties, setDuties] = useState('');
  const [dutiesEmptyError, setdutiesEmptyError] = useState(false);
  const [muta, setMUTA] = useState(0);
  const [MUTAsEmptyError, setMUTAsEmptyError] = useState(false);
  const [selectedOtherType, setSelectedOtherType] = useState();
  const [selectedType, setSelectedType] = useState('');
  const [pulled, setPulled] = useState(false);
  const [unitList, setUnitList] = useState([{ name: 'No Units', unit_id: '0' }]);
  const [unit, setUnit] = useState(global.user_data.unit_id);
  const [unitEmptyError, setUnitEmptyError] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [iloDate, setIloDate] = useState('');
  const [forPay, setForPay] = useState(true);
  const [checkbox4H, setCheckbox4H] = useState(true);
  const [checkbox8H, setCheckbox8H] = useState(false);
  const [locked, setLocked] = useState(false);
  const [modifying, setModifying] = useState(true);
  const [modifyingNthEntry, setModifyingNthEntry] = useState(true);
  const [fileSelectModal, setFileSelectModal] = useState(false);
  const [modifyingDoc, setModifyingDoc] = useState(false);
  const [modifyingID, setModifyingID] = useState(-1);
  const [pulledInfo, setPulledInfo] = useState({});
  const theme = useTheme();
  const [didUserSignDocument, setDidUserSignDocument] = useState(false);
  const [indexBeingEditedInSequence, setIndexBeingEditedInSequence] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [addedFormIDs, setAddedFormIDs] = useState([]);
  const [isColumn, setIsColumn] = useState(false);

  // If the user can see the HDIP/ACIP checkbox, this way it's checked by default. If not, it's false and will remain false.
  const userHasIncentivePay =global.user_data.incentive_pay !== 'None'
  const [HDIP_ACIP_Authorized, setHDIP_ACIP_Authorized] = useState(false);

  useEffect(() => {
    if (selectedType === 'RST') {
      setHDIP_ACIP_Authorized(userHasIncentivePay);
    }
  }, [selectedType])

  useEffect(() => {
    console.log(uploadFiles)
    console.log(route)
  }, [uploadFiles])

  const payTheme = createTheme({
    palette: {
      mode: colors.mode,
      primary: theme.palette.primary,
    },
  });
  const [DateTimeError, setDateTimeError] = useState(false);

  const [doesUserHaveSignature, setDoesUserHaveSignature] = useState(false);
  const [signatureName, setSignatureName] = useState('');
  const [failedToRetrieve, setFailedToRetrieve] = useState(false);

  const viewRef = useRef();

  function handleHDIP() {
    setHDIP_ACIP_Authorized(!HDIP_ACIP_Authorized);
  }

  async function loadUnits() {
    localStorage.clear();
    const all_units_test = await get_units_for_1380(JSON.stringify({ unit_id: global.user_data.unit_id }), global.token);
    if (all_units_test.status === 200) {
      setUnitList(all_units_test.data.body);
    } else {
      setUnitEmptyError(true);
      showSnackbar('error', 'Application Error: Failed to retrieve all units');
      setFailedToRetrieve(true);
    }
  };

  async function setUnitandReviewer(id) {
    if (!isNaN(id)) {
      setUnit(id);

      let getAllOfficers = [];

      if (selectedType === 'aftp') {
        getAllOfficers = await get_all_users_in_single_unit({ unit_id: id, permission: 32 }, global.token);
      } else {
        getAllOfficers = await get_all_users_in_single_unit({ unit_id: id, permission: 29 }, global.token);
      }

      if (getAllOfficers.status === 200) {
        const filterOfficers = getAllOfficers.data.body.filter(
          (user) => user.user_id != global.user_data.user_id,
        );
        setOfficers(filterOfficers);
      } else {
        showSnackbar('error', 'Application Error: Failed to retrieve Review Officers');
        setOfficers([]);
      }
    } else {
      setOfficers([]);
    }
  }

  useEffect(() => {
    // Save to localStorage whenever the state changes
    localStorage.setItem('validatorSigId', JSON.stringify(validatorSigId));
  }, [validatorSigId]);

  useEffect(() => {
    const handleResize = () => {
      setIsColumn(Dimensions.get('window').width <= 1350);
    };

    handleResize(); // Set initial value
    Dimensions.addEventListener('change', handleResize);

    return () => {
      Dimensions.removeEventListener('change', handleResize);
    };
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      // This effect is used to prevent navigation instantly if date picker is open
      const beforeRemoveListener = (e) => {
        if (!datePickerOpen) {
          return;
        }

        e.preventDefault();

        setDatePickerOpen(false);
        // navigation.dispatch(e.data.action);
        setTimeout(() => {
          navigation.dispatch(e.data.action);
        }, 200);
      };

      navigation.addListener('beforeRemove', beforeRemoveListener);

      return () => {
        navigation.removeListener('beforeRemove', beforeRemoveListener);
      };
    }, [datePickerOpen]),
  );

  useEffect(() => {
    setUnitandReviewer(global.user_data.unit_id);
  }, [selectedType]);

  async function loadPulledRSTInfo() {
    setLoaded(false);
    if (route.params !== undefined) {
      setPulled(true);

      const { form_id, form_type } = route.params;

      if (form_id !== undefined) {
        const data = { form_id, form_type };
        const validFormTypes = ['RST', 'dental', 'ATA', 'EBDL', 'MDR'];

        if (validFormTypes.includes(form_type)) {
          const fileData = await get_single_file(data, global.token);

          if (fileData !== undefined) {
            await preLoadInfo(fileData.body, form_type);
            setPulledInfo(fileData.body);
          } else {
            showSnackbar('error', 'Application Error: Failed to pull form data');
            navigation.navigate('RstTo1380');
          }
        } else {
          showSnackbar('error', 'Application Error: Invalid form type');
          return;
        }
      } else {
        showSnackbar('error', 'Application Error: Missing form ID');
        navigation.navigate('RstTo1380');
      }
    } else {
      showSnackbar('error', 'Could Not Open File');
      navigation.navigate('RstTo1380');
    }
    setLoaded(true);
  }


  async function fetchModifiedRstPull(modifiedRst) {
    if (modifiedRst !== undefined) {
      setPulled(true);

      if (modifiedRst.id !== undefined) {
        let data = {};
        if (modifiedRst.form_type === 'RST') {
          data = { form_id: modifiedRst.id, form_type: 'RST' };
        } else if (modifiedRst.form_type === 'dental') {
          data = { form_id: modifiedRst.id, form_type: 'dental' };
        } else if (modifiedRst.form_type === 'ATA') {
          data = { form_id: modifiedRst.id, form_type: 'ATA' };
        } else if (modifiedRst.form_type === 'EBDL') {
          data = { form_id: modifiedRst.id, form_type: 'EBDL' };
        } else if (modifiedRst.form_type === 'MDR') {
          data = { form_id: modifiedRst.id, form_type: 'MDR' };
        } else {
          showSnackbar('error', 'Application Error: Failed to pull form data');
          return;
        }
        const fileData = await get_single_file(data, global.token);
        if (fileData !== undefined) {
          await preLoadInfo(fileData.body, modifiedRst.form_type);
          setPulledInfo(fileData.body);
        } else {
          showSnackbar('error', 'Application Error: Failed to pull form data');
          navigation.navigate('RstTo1380');
        }
      }
    } else {
      showSnackbar('error', 'Application Error: Failed to retrieve the file');
      navigation.navigate('RstTo1380');
    }
    setLoaded(true);
  };

  const loadForms = async () => {
    if (route.params.draftFormID !== undefined && route.params.draftFormID != '') await loadDraftForm();
    await loadPulledRSTInfo();
    await loadUnits();
  }

  useEffect(() => {
    function checkSignature() {
      if (global.signature_uri == null || global.signature_uri === '') {
        setDoesUserHaveSignature(false);
      } else {
        setDoesUserHaveSignature(true);
      }
    }
    if (isFocused) {
      loadForms();
      setLoaded(false);
      checkSignature();
      setDidUserSignDocument(false);
      setLoaded(true);
    }
  }, [isFocused]);

  const captureTextAsImage = async () => {
    try {
      const fullname = global.user_data.first_name.toLowerCase() + ' ' + global.user_data.last_name.toLowerCase();
      if (signatureName.toLowerCase() === fullname) {
        const uri = await captureRef(viewRef, {
          format: 'png',
          quality: 0.8,
        });

        let ret = await create_new_user_signature({ user_id: global.user_data.user_id }, global.token);
        if (ret.status === 200) {
          ret = ret.data;
          await put_image_s3('signatures/' + ret.body.signature_id, uri, 'image/png', global.token);

          global.signature_uri = uri;

          global.user_data.signature_id = ret.body.signature_id;
          await AsyncStorage.setItem('signature', global.signature_uri);
          storeJsonData('user_data', global.user_data);

          setDoesUserHaveSignature(true);
        } else {
          showSnackbar('error', 'Application Error: Failed to create your signature');
        }
      } else {
        showSnackbar('error', 'The name inputted does not match your name we have on record!');
      }
    } catch (error) {
      console.error('Error capturing image:', error);
    }
  };

  function setValidatorStates(data) {
    const noValidators = validatorOption.length === 1 && (validatorOption[0]?.user_id === undefined || validatorOption[0]?.user_id === 0);
    if (noValidators) {
      setValidatorIds([data.reviewer_id])
      setValidatorOption([
        {
          user_id: data.reviewer_id,
          unit_id: data.reviewer_unit_id,
          name: data.reviewer_rank + ' ' + data.reviewer_name + ' - ' + data.reviewer_unit_name,
          form_id: data.form_id,
          status: data.status,
          sig_id: data.reviewer_signature_id,
        }
      ])
    } else {
      setValidatorIds([ ...validatorIds, data.reviewer_id])
      setValidatorOption([...validatorOption,
      {
        user_id: data.reviewer_id,
        unit_id: data.reviewer_unit_id,
        name: data.reviewer_rank + ' ' + data.reviewer_name + ' - ' + data.reviewer_unit_name,
        form_id: data.form_id,
        status: data.status,
        sig_id: data.reviewer_signature_id,
      }
      ])
    }
  }

  const preLoadInfo = async (pulledData, form_type) => {
    setModifyingDoc(route.params.modifyDoc ?? false);
    setModifyingID(route.params.modifyingID ?? '');
    setLoaded(false);

    setValidatorStates(pulledData)

    const eventsParam = route.params.events || [];
    let eventsTemp = events;
    const uploadFilesParam = route.params.uploadFiles || [];
    if (eventsParam.length > 0 && !modifyingDoc) {
      if (route.params.events !== undefined && route.params.events.length > 0 && (route.params.addedFormIDs === undefined || !route.params.addedFormIDs.includes(pulledData.form_id))) {
        for (const event of route.params.events) {
          const formattedDate = dayjs(event.date).format('MM/DD/YYYY');
          // Prevent duplicates; this prioritizes the existing events over those on the incoming form
          if (!eventsTemp.some((e) => dayjs(e.date).format('MM/DD/YYYY') === formattedDate)) {
            eventsTemp.push({
              id: eventsTemp.length + 1,
              date: new Date(event.date),
              formattedDate: formattedDate,
              hours: event.hours,
              points: event.points,
              duties: event.duties || '',
            });
          }
        }
      }
    }

    if (route.params.addedFormIDs == undefined || !route.params.addedFormIDs.includes(pulledData.form_id) && !uploadFilesParam.some((file) => file.rst_form_id == pulledData.form_id || file.dental_form_id == pulledData.form_id)) {
      if (form_type === 'RST') {
        setImportType('RST');
        const newUploadFiles = uploadFilesParam;
        if (modifyingDoc) {
          // Set uploadFiles to only this one
          const updatedUploadFiles = uploadFilesParam.map(file => {
            if (file.rst_form_id === route.params.modifyingID) {
              return {
                form_id: file.form_id,
                form_name: `Pulled RST ${pulledData.form_id}`,
                form_type: 'RST',
                is_image: false,
                rst_form_id: pulledData.form_id,
              };
            }
            return file;
          });
          setUploadFiles(updatedUploadFiles);
          const updatedAddedFormIDs = addedFormIDs.map(id =>
            id === route.params.modifyingID ? pulledData.form_id : id
          );
          setAddedFormIDs(updatedAddedFormIDs);
        } else {
          const form_name = `Pulled ${form_type} ${pulledData.form_id}`;
          const fileExists = newUploadFiles.some((file) => file.form_name === form_name);
          if (!fileExists) {
            newUploadFiles.push({
              form_id: newUploadFiles.length,
              form_name: `Pulled RST ${pulledData.form_id}`,
              form_type: 'RST',
              is_image: false,
              rst_form_id: pulledData.form_id,
              validator_ids: [pulledData.reviewer_id]
            });
            setUploadFiles(newUploadFiles);
          }
        }

        if (pulledData.rst) {
          setSelectedType('RST');
          setIloDate(pulledData.rst_ba_date ? pulledData.rst_ba_date : '');

          const rstDates = pulledData.rst_performed_on ?
            pulledData.rst_performed_on.trim().replace(/, $/, '').split(', ') :
            '';
          for (let i = 0; i < rstDates.length; i++) {
            let tempHours = '';
            let tempPoints = '';
            const tempDate = rstDates[i].split(' ');
            if (!tempDate[1] || tempDate[1] === '(1)' || tempDate[1] === '(1),') {
              tempHours = '4';
              tempPoints = forPay ? 'P1' : 'N1';
            } else if (tempDate[1] === '(2)' || tempDate[1] === '(2),') {
              tempHours = '8';
              tempPoints = forPay ? 'P2' : 'N2';
            }
            else {
              tempHours = 'ERR';
              tempPoints = 'ERR';
            }

            const MONTHTONUM = new Map([
              ['JAN', '01'],
              ['FEB', '02'],
              ['MAR', '03'],
              ['APR', '04'],
              ['MAY', '05'],
              ['JUN', '06'],
              ['JUL', '07'],
              ['AUG', '08'],
              ['SEP', '09'],
              ['OCT', '10'],
              ['NOV', '11'],
              ['DEC', '12'],
            ]);

            // const day = tempDate[0][0] + tempDate[0][1];
            // const month = tempDate[0][2] + tempDate[0][3] + tempDate[0][4];
            // const year = '20' + tempDate[0][5] + tempDate[0][6];
            // const formattedDate = MONTHTONUM.get(month) + '/' + day + '/' + year;

            const day = tempDate[0][0] + tempDate[0][1];
            const month = tempDate[0][2] + tempDate[0][3] + tempDate[0][4];
            let year;
            if (tempDate[0][7] === undefined) {
              year = '20' + tempDate[0][5] + tempDate[0][6]
            } else {
              year = tempDate[0][5] + tempDate[0][6] + tempDate[0][7] + tempDate[0][8];
            }

            const formattedDate = MONTHTONUM.get(month) + '/' + day + '/' + year;
            if (!eventsTemp.some((event) => event.formattedDate == formattedDate)) {
              eventsTemp.push({
                id: eventsTemp.length + 1,
                date: new Date(formattedDate),
                formattedDate: formattedDate,
                hours: tempHours,
                points: tempPoints,
                duties: '',
              });
            } else {
              showSnackbar('warning', `An event already exists on ${formattedDate}`);
              eventsTemp.push({
                id: eventsTemp.length + 1,
                date: new Date(null),
                formattedDate: 'TBD',
                hours: tempHours,
                points: tempPoints,
                duties: '',
              });
            }
            setEntryExists(true);
          }
        }
      } else if (form_type === 'dental') {
        const newUploadFiles = uploadFilesParam;
        const [year, month, day] = pulledData.date_of_examination.split('-');
        const formattedDate = `${month}/${day}/${year}`;
        if (modifyingDoc) {
          const updatedUploadFiles = uploadFilesParam.map(file => {
            if (file.rst_form_id === route.params.modifyingID) {
              return {
                form_id: file.form_id,
                form_name: `Pulled Dental ${pulledData.form_id}`,
                form_type: 'Dental',
                is_image: false,
                dental_form_id: pulledData.form_id,
              };
            }
            return file;
          });
          setUploadFiles(updatedUploadFiles);
          const updatedAddedFormIDs = addedFormIDs.map(id =>
            id === route.params.modifyingID ? pulledData.form_id : id
          );
          setAddedFormIDs(updatedAddedFormIDs);
        } else {
          const form_name = `Pulled ${form_type} ${pulledData.form_id}`;
          const fileExists = newUploadFiles.some((file) => file.form_name === form_name);
          if (!fileExists) {
            newUploadFiles.push({
              form_id: newUploadFiles.length,
              form_name: `Pulled Dental ${pulledData.form_id}`,
              form_type: 'Dental',
              is_image: false,
              dental_form_id: pulledData.form_id,
            });
            setUploadFiles(newUploadFiles);
          }
        }

        setImportType('MDR');
        setSelectedType('MDR');

        if (!events.some((event) => event.formattedDate == formattedDate)) {
          events.push({
            id: events.length,
            date: new Date(formattedDate),
            formattedDate: formattedDate,
            hours: '1',
            points: forPay ? 'P1' : 'N1',
            duties: '',
          });
        } else {
          showSnackbar('warning', `An event already exists on ${formattedDate}`);
          events.push({
            id: events.length,
            date: new Date(null),
            formattedDate: 'TBD',
            hours: tempHours,
            points: tempPoints,
            duties: '',
          });
        }

        setEntryExists(true);
      } else if (form_type === 'ATA') {
        setImportType('ATA');
        setSelectedType('ATA');
        const newUploadFiles = uploadFilesParam;
        if (modifyingDoc) {
          const updatedUploadFiles = uploadFilesParam.map(file => {
            if (file.rst_form_id === route.params.modifyingID) {
              return {
                form_id: file.form_id,
                form_name: `Pulled ATA ${pulledData.form_id}`,
                form_type: 'ATA',
                is_image: false,
                rst_form_id: pulledData.form_id,
              };
            }
            return file;
          });
          setUploadFiles(updatedUploadFiles);
          const updatedAddedFormIDs = addedFormIDs.map(id =>
            id === route.params.modifyingID ? pulledData.form_id : id
          );
          setAddedFormIDs(updatedAddedFormIDs);
        } else {
          const form_name = `Pulled ${form_type} ${pulledData.form_id}`;
          const fileExists = newUploadFiles.some((file) => file.form_name === form_name);
          if (!fileExists) {
            newUploadFiles.push({
              form_id: newUploadFiles.length,
              form_name: `Pulled ATA ${pulledData.form_id}`,
              form_type: 'ATA',
              is_image: false,
              rst_form_id: pulledData.form_id,
            });
            setUploadFiles(newUploadFiles);
          }
        }

        const ataDates = pulledData.dates_of_training ? pulledData.dates_of_training.split(', ') : '';
        const ataTrainings = pulledData.training_to_be_completed ? pulledData.training_to_be_completed.split(', ') : '';
        for (let i = 0; i < ataDates.length; i++) {
          const tempHours = '4';
          const tempPoints = 'P1';
          const tempDate = ataDates[i].split(' ');
          const MONTHTONUM = new Map([
            ['JAN', '01'],
            ['FEB', '02'],
            ['MAR', '03'],
            ['APR', '04'],
            ['MAY', '05'],
            ['JUN', '06'],
            ['JUL', '07'],
            ['AUG', '08'],
            ['SEP', '09'],
            ['OCT', '10'],
            ['NOV', '11'],
            ['DEC', '12'],
          ]);
          const day = tempDate[0].substring(0, 2);
          const month = tempDate[0].substring(2, 5);
          const year = tempDate[0].substring(5, 9);
          const formattedDate = `${MONTHTONUM.get(month)}/${day}/${year}`;
          if (!events.some((event) => event.formattedDate == formattedDate)) {
            events.push({
              id: events.length,
              date: new Date(formattedDate),
              formattedDate: formattedDate,
              hours: tempHours,
              points: tempPoints,
              duties: '',
            });
          } else {
            showSnackbar('warning', `An event already exists on ${formattedDate}`);
            events.push({
              id: events.length,
              date: new Date(null),
              formattedDate: 'TBD',
              hours: tempHours,
              points: tempPoints,
              duties: '',
            });
          }
        }
        setEntryExists(true);
      } else if (form_type === 'EBDL') {
        setImportType('EBDL');
        setSelectedType('EBDL');
        const newUploadFiles = uploadFilesParam;
        if (modifyingDoc) {
          const updatedUploadFiles = uploadFilesParam.map(file => {
            if (file.rst_form_id === route.params.modifyingID) {
              return {
                form_id: file.form_id,
                form_name: `Pulled EBDL ${pulledData.form_id}`,
                form_type: 'EBDL',
                is_image: false,
                rst_form_id: pulledData.form_id,
              };
            }
            return file;
          });
          setUploadFiles(updatedUploadFiles);
          const updatedAddedFormIDs = addedFormIDs.map(id =>
            id === route.params.modifyingID ? pulledData.form_id : id
          );
          setAddedFormIDs(updatedAddedFormIDs);
        } else {
          const form_name = `Pulled ${form_type} ${pulledData.form_id}`;
          const fileExists = newUploadFiles.some((file) => file.form_name === form_name);
          if (!fileExists) {
            newUploadFiles.push({
              form_id: newUploadFiles.length,
              form_name: `Pulled EBDL ${pulledData.form_id}`,
              form_type: 'EBDL',
              is_image: false,
              rst_form_id: pulledData.form_id,
            });
            setUploadFiles(newUploadFiles);
          }
        }
        const ebdlDates = pulledData.dates_of_training ? pulledData.dates_of_training.split(', ') : '';
        const ebdlTrainings = pulledData.training_to_be_completed ? pulledData.training_to_be_completed.split(', ') : '';
        for (let i = 0; i < ebdlDates.length; i++) {
          const tempHours = '4';
          const tempPoints = 'P1';
          const tempDate = ebdlDates[i].split(' ');
          const MONTHTONUM = new Map([
            ['JAN', '01'],
            ['FEB', '02'],
            ['MAR', '03'],
            ['APR', '04'],
            ['MAY', '05'],
            ['JUN', '06'],
            ['JUL', '07'],
            ['AUG', '08'],
            ['SEP', '09'],
            ['OCT', '10'],
            ['NOV', '11'],
            ['DEC', '12'],
          ]);
          const day = tempDate[0].substring(0, 2);
          const month = tempDate[0].substring(2, 5);
          const year = tempDate[0].substring(5, 9);
          const formattedDate = `${MONTHTONUM.get(month)}/${day}/${year}`;
          if (!events.some((event) => event.formattedDate == formattedDate)) {
            events.push({
              id: events.length,
              date: new Date(formattedDate),
              formattedDate: formattedDate,
              hours: tempHours,
              points: tempPoints,
              duties: '',
            });
          } else {
            showSnackbar('warning', `An event already exists on ${formattedDate}`);
            events.push({
              id: events.length,
              date: new Date(null),
              formattedDate: 'TBD',
              hours: tempHours,
              points: tempPoints,
              duties: '',
            });
          }
        }
        setEntryExists(true);
      } else if (form_type === 'MDR') {
        setImportType('MDR');
        setSelectedType('MDR');
        const newUploadFiles = uploadFilesParam;
        if (modifyingDoc) {
          const updatedUploadFiles = uploadFilesParam.map(file => {
            if (file.rst_form_id === route.params.modifyingID) {
              return {
                form_id: file.form_id,
                form_name: `Pulled MDR ${pulledData.form_id}`,
                form_type: 'MDR',
                is_image: false,
                rst_form_id: pulledData.form_id,
              };
            }
            return file;
          });
          setUploadFiles(updatedUploadFiles);
          const updatedAddedFormIDs = addedFormIDs.map(id =>
            id === route.params.modifyingID ? pulledData.form_id : id
          );
          setAddedFormIDs(updatedAddedFormIDs);
        } else {
          const form_name = `Pulled ${form_type} ${pulledData.form_id}`;
          const fileExists = newUploadFiles.some((file) => file.form_name === form_name);
          if (!fileExists) {
            newUploadFiles.push({
              form_id: newUploadFiles.length,
              form_name: `Pulled MDR ${pulledData.form_id}`,
              form_type: 'MDR',
              is_image: false,
              rst_form_id: pulledData.form_id,
            });
            setUploadFiles(newUploadFiles);
          }
        }

        const mdrDates = pulledData.dates_of_training ? pulledData.dates_of_training.split(', ') : '';
        const mdrTrainings = pulledData.training_to_be_completed ? pulledData.training_to_be_completed.split(', ') : '';
        for (let i = 0; i < mdrDates.length; i++) {
          const tempHours = '4';
          const tempPoints = 'P1';
          const tempDate = mdrDates[i].split(' ');
          const MONTHTONUM = new Map([
            ['JAN', '01'],
            ['FEB', '02'],
            ['MAR', '03'],
            ['APR', '04'],
            ['MAY', '05'],
            ['JUN', '06'],
            ['JUL', '07'],
            ['AUG', '08'],
            ['SEP', '09'],
            ['OCT', '10'],
            ['NOV', '11'],
            ['DEC', '12'],
          ]);
          const day = tempDate[0].substring(0, 2);
          const month = tempDate[0].substring(2, 5);
          const year = tempDate[0].substring(5, 9);
          const formattedDate = `${MONTHTONUM.get(month)}/${day}/${year}`;

          if (!events.some((event) => event.formattedDate == formattedDate)) {
            events.push({
              id: events.length,
              date: new Date(formattedDate),
              formattedDate: formattedDate,
              hours: tempHours,
              points: tempPoints,
              duties: '',
            });
          } else {
            showSnackbar('warning', `An event already exists on ${formattedDate}`);
            events.push({
              id: events.length,
              date: new Date(null),
              formattedDate: 'TBD',
              hours: tempHours,
              points: tempPoints,
              duties: '',
            });
          }
        }
        setEntryExists(true);
      }

      // If we're modifying, the incoming form is the only one
      if (modifyingDoc) {
        setAddedFormIDs([pulledData.form_id]);
        setModifyingDoc(false);
        setModifyingID(-1);
        setLoaded(true);
      } else {
        const newAddedFormIDs = route.params.addedFormIDs || [];
        newAddedFormIDs.push(pulledData.form_id);
        setAddedFormIDs(newAddedFormIDs);

        const formIdKey = form_type === 'dental' ? 'dental_form_id' : 'rst_form_id';
        const formIdValue = pulledData.form_id;
        const newUploadFiles = uploadFilesParam;
        const form_name = `Pulled ${form_type} ${pulledData.form_id}`;
        const fileExists = newUploadFiles.some((file) => file.form_name === form_name);
        if (!fileExists) {
          newUploadFiles.push({
            form_id: newUploadFiles.length,
            form_name: form_name,
            form_type: form_type,
            is_image: false,
            [formIdKey]: formIdValue,
          });
        }
        setUploadFiles(newUploadFiles);
        setLoaded(true);
      }
    } else {
      showSnackbar('error', `Form ${pulledData.form_id} has already been added`);
      setEvents(eventsParam);
      setUploadFiles(uploadFilesParam);
      setLoaded(true);
    }

    eventsTemp.sort((a, b) => a.date - b.date);
    if (eventsTemp.length > 0) {
      const index = eventsTemp.findIndex((event) => event.duties === '' || event.duties === undefined);
      if (index !== -1) {
        setEventDate(eventsTemp[index].formattedDate === 'TBD' ? null : dayjs(eventsTemp[index].date)); // grab this for date auto-input
        setFormattedEventDate(eventsTemp[index].formattedDate);
        setHours(eventsTemp[index].hours);
        setCheckbox4H(eventsTemp[index].hours == 4 ? true : false);
        setCheckbox8H(eventsTemp[index].hours == 8 ? true : false);
        setPoints(eventsTemp[index].points);
        setDuties(eventsTemp[index].duties);
        setModifyingNthEntry(true);
        setModifying(true);
        setSelected(index);
        setIndexBeingEditedInSequence(index);
      } else {
        setEventDate(null);
        setFormattedEventDate(null);
        setHours('4');
        setCheckbox4H(true);
        setCheckbox8H(false);
        setPoints('P1');
        setDuties('');
        setModifyingNthEntry(false);
        setModifying(false);
        setSelected(-1);
        setIndexBeingEditedInSequence(-1);
      }
    }
    setUploadFiles(uploadFilesParam.filter(file =>
      file.rst_form_id !== route.params.modifyingID)
    );
    setEvents(eventsTemp);
    setLoaded(true);
  };

  const checkEntries = () => {
    let check = true;

    for (const event in events) {
      if (
        events[event].hours === '0' ||
        events[event].points === '0' ||
        events[event].duties === ''
      ) {
        check = false;
        break;
      }
    }
    if (check || !viewSig) {
      onSubmit();
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
      showSnackbar('error', 'Please finish filling out all of your entries');
    }
  };

  // function toggleForPay() {
  //   setForPay(!forPay);
  //   setPoints(points === 'P1' ? 'N1' : points === 'P2' ? 'N2' : points === 'N1' ? 'P1' : 'P2');
  //   // Are points P1? Make them N1. Otherwise, are points P2? Make them N2. Othewrise, are points N1? Make them P1. Otherwise, they must be N2 so make them P2.
  // }

  function HoursAndPoints() {
    if (selectedType === 'MDR' || selectedType === 'ATA' || selectedType === 'EBDL' || selectedType === 'dental') {
      setHours(selectedType === 'dental' ? '1' : '4');
      setPoints(forPay ? 'P1' : 'N1');
      setLocked(true);
      return null;
    } else if (selectedType === 'RST' || selectedType === 'aftp') {
      setLocked(true);
      return (
        <>
          <View style={[styles.checkboxContainer, { flexDirection: 'column' }]}>
            <View style={{ flexDirection: 'row', alignItems: 'center', height: '65%' }}>
              <Text style={[styles.textStyle, { marginRight: '10%' }]}>MUTAs*:</Text>
              <CustomRadioWithBackground
                id="MUTA 1"
                checked={checkbox4H == true}
                onChange={() => {
                  if (checkbox4H) {
                    setHours('8');
                    setPoints(forPay ? 'P2' : 'N2');
                    setCheckbox8H(!checkbox8H);
                    setCheckbox4H(false);
                    sethoursEmptyError(false);
                    setpointsEmptyError(false);
                  } else {
                    setHours('4');
                    setPoints(forPay ? 'P1' : 'N1');
                    setCheckbox4H(true);
                    setCheckbox8H(false);
                    sethoursEmptyError(false);
                    setpointsEmptyError(false);
                  }
                  setMUTAsEmptyError(false);
                }}
                label="MUTA 1"
              />
              <CustomRadioWithBackground
                id="MUTA 2"
                checked={checkbox8H == true}
                onChange={() => {
                  if (checkbox8H) {
                    setHours('4');
                    setPoints(forPay ? 'P1' : 'N1');
                    setCheckbox8H(!checkbox8H);
                    setCheckbox4H(true);
                    sethoursEmptyError(false);
                    setpointsEmptyError(false);
                  } else {
                    setHours('8');
                    setPoints(forPay ? 'P2' : 'N2');
                    setCheckbox4H(false);
                    setCheckbox8H(true);
                    sethoursEmptyError(false);
                    setpointsEmptyError(false);
                  }
                  setMUTAsEmptyError(false);
                }}
                label="MUTA 2"
              />
            </View>
            <HelperText style={{ color: 'red', paddingHorizontal: 12, paddingVertical: 0, marginRight: '3%' }} visible={MUTAsEmptyError}>{'Enter number of MUTAs'}</HelperText>
          </View>
        </>
      );
    } else {
      setLocked(false);
      return null;
    }
  };

  const onCancel = () => {
    setEvents([]);
    setModifying(false);
    setSelected(-1);
    setModifyingNthEntry(-1);
    setSelectedType('');
    navigation.navigate('RstTo1380');
  };

  const modifyNthEntryEvent = async (index) => {
    const isInvalidDate = eventDate === null || !eventDate.isValid();
    const isAlreadyUsedDate = events.some((event, idx) => event.formattedDate == formattedEventDate && (!modifying || (modifying && idx !== selected)));

    if (selectedType === 'RST' && (duties === '' || isInvalidDate || isAlreadyUsedDate)) {
      showSnackbar('error', 'Please correct one or more invalid inputs');
      settoBePerformedOnDatesEmptyError(isInvalidDate);
      setAlreadyUsedDateError(isAlreadyUsedDate);
      setdutiesEmptyError(duties === '');
    } else if ((selectedType !== 'other' && selectedType !== 'RST') && (duties === '' || isInvalidDate || isAlreadyUsedDate)) {
      settoBePerformedOnDatesEmptyError(isInvalidDate);
      setAlreadyUsedDateError(isAlreadyUsedDate);
      setdutiesEmptyError(duties === '');
      showSnackbar('error', 'Please correct one or more invalid inputs');
    } else if (selectedType === 'other' && (duties === '' || points === '' || isInvalidDate || isAlreadyUsedDate)) {
      sethoursEmptyError(hours === '');
      setpointsEmptyError(points === '');
      settoBePerformedOnDatesEmptyError(isInvalidDate);
      setAlreadyUsedDateError(isAlreadyUsedDate);
      setdutiesEmptyError(duties === '');
      showSnackbar('error', 'Please correct one or more invalid inputs');
    } else {
      events[index] = {
        id: selected,
        date: eventDate,
        formattedDate: formattedEventDate,
        hours: (selected > -1 && events[selected] && events[selected].hours == '1') ? '1' : hours,
        points: points,
        duties: duties,
      };

      setCheckbox4H(hours == 4 ? true : false);
      setCheckbox8H(hours == 8 ? true : false);
      setHours(hours);
      setPoints(points);
      setDuties(duties);
      setdutiesEmptyError(false);

      if (!dutiesEmptyError) {
        setEventDate(null);
        setFormattedEventDate('');
        setHours('');
        setPoints('');
        setDuties('');
        setCheckbox4H(false);
        setCheckbox8H(false);
        setEntryExists(true);
      }
      events.sort((a, b) => a.date - b.date);
      if (events.length > index + 1) {
        const firstBlankIndex = events.findIndex((event) => event.duties === '' || event.duties === undefined);
        if (firstBlankIndex !== -1) {
          setEventDate(events[firstBlankIndex].formattedDate === 'TBD' ? null : dayjs(events[firstBlankIndex].date)); // grab this for date auto-input
          setFormattedEventDate(events[firstBlankIndex].formattedDate);
          setHours(events[firstBlankIndex].hours);
          setCheckbox4H(events[firstBlankIndex].hours == 4 ? true : false);
          setCheckbox8H(events[firstBlankIndex].hours == 8 ? true : false);
          setPoints(events[firstBlankIndex].points);
          setDuties(events[firstBlankIndex].duties);
          setModifyingNthEntry(true);
          setModifying(true);
          setSelected(firstBlankIndex);
          setIndexBeingEditedInSequence(firstBlankIndex);
        }
        else {
          setEventDate(null);
          setFormattedEventDate('');
          setHours('');
          setPoints('');
          setDuties('');
          setCheckbox4H(false);
          setCheckbox8H(false);
          setModifying(false);
          setModifyingNthEntry(false);
          setSelected(-1);
          setIndexBeingEditedInSequence(-1);
        }
      } else {
        setModifyingNthEntry(false);
        setModifying(false);
        setIndexBeingEditedInSequence(-1);
        setSelected(-1);
      }
    }
  };



  const addEvent = async () => {
    const isInvalidDate = eventDate === null || !eventDate.isValid();
    const isAlreadyUsedDate = events.some((event, idx) => event.formattedDate == formattedEventDate && (!modifying || (modifying && idx !== selected)));
    if (hours === '' || points === '' || duties === '' || isInvalidDate || isAlreadyUsedDate) {
      showSnackbar('error', 'Please correct one or more invalid inputs');
      setdutiesEmptyError(duties === '' ? true : false);
      sethoursEmptyError(hours === '' ? true : false);
      setpointsEmptyError(points === '' ? true : false);
      settoBePerformedOnDatesEmptyError(isInvalidDate);
      setAlreadyUsedDateError(isAlreadyUsedDate);
      if (selectedType === 'RST') {
        setMUTAsEmptyError(checkbox4H || checkbox8H ? false : true);
      }
    } else {
      if (modifying) {
        events[selected] = {
          id: selected,
          date: eventDate,
          formattedDate: formattedEventDate,
          hours: (selected > -1 && events[selected] && events[selected].hours == '1') ? '1' : hours,
          points: points,
          duties: duties,
        };
        setModifying(false);
        setCheckbox4H(false);
        setCheckbox8H(false);
        setHours('');
        setPoints('');
      } else {
        events.push({
          id: events.length,
          date: eventDate,
          formattedDate: formattedEventDate,
          hours: hours,
          points: points,
          duties: duties,
        });
      }
      setEventDate(null);
      setFormattedEventDate('');
      if (selected === 'other') {
        setHours('');
        setPoints('');
      }
      setDuties('');
      setEntryExists(true);
      setIndexBeingEditedInSequence(-1);
      setSelected(-1);
    }
  };

  const grabMonthFromDate = async (selectedDate) => {
    if (selectedDate) {
      const hold = selectedDate.split('/');
      const month = hold[0];
      const year = hold[2];

      const fullMonthMap = {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'December',
      };

      return fullMonthMap[month] + ' ' + year;
    }
  };

  const modifyDoc = (id) => {
    setModifyingID(id);
    setModifyingDoc(true);
    setFileSelectModal(true);
  };

  const resetValidatorStates = () => {
    setValidator('');
    setValidatorEmptyError(false);
    setValidatorSigId([]);
    setShowValidatorSig(false);
    setValidatorSelectedOption({ user_id: 0, unit_id: 0, name: '' });
    setValidatorOption([])
  }

  const deleteDoc = (file) => {
    const id = file.form_id;
    const filteredFormList = uploadFiles.filter((form) => form.form_id !== id);
    const removedForm = uploadFiles.find((form) => form.form_id === id);
    const newFormList = filteredFormList.map((form) => {
      form.form_id > id ? form.form_id-- : form.form_id;
      return form;
    });

    console.log("file", file);
    console.log("removedForm", removedForm);
    console.log("newFormList", newFormList);

    if(!checkForImage(removedForm.form_type)){
      let actualFormId;
      if (file.form_type == 'dental') actualFormId = Number(file.dental_form_id);
      else actualFormId = Number(file.rst_form_id);
  
      const filteredFormIDs = addedFormIDs.filter((idInList) => idInList !== actualFormId && idInList !== undefined);
      setAddedFormIDs(filteredFormIDs);
      setResetSearchQuery({ reset: true, form_ids: filteredFormIDs })
      if (filteredFormList.length < 1)
        resetValidatorStates();
    }
    setUploadFiles(newFormList);
  };

  const modifyEntry = (entryID) => {
    const id = (entryID == -1) ? 0 : entryID;
    setEventDate(dayjs(events[id].date)); // grab this for date auto-input
    setFormattedEventDate(events[id].formattedDate);
    setHours(events[id].hours);
    setPoints(events[id].points);
    setDuties(events[id].duties);

    if (selectedType !== 'other') {
      setCheckbox4H(events[id].hours == 4 ? true : false);
      setCheckbox8H(events[id].hours == 8 ? true : false);
    }
    setMUTAsEmptyError(false);
    sethoursEmptyError(false);
    setpointsEmptyError(false);
    settoBePerformedOnDatesEmptyError(false);
    setAlreadyUsedDateError(false);
    setSelect(id);
  };

  const handleDutiesChange = (event) => {
    setDuties(event);
    setdutiesEmptyError(event === '');
  };

  const deleteEntry = (index) => {
    const filteredEventList = events.filter((_, i) => i !== index);
    const newEventsList = filteredEventList.map((event, i) => {
      event.id = i; 
      return event;
    });
    setEvents(newEventsList);
    setSelected(-1);
    setIndexBeingEditedInSequence(-1);
    setModifying(false);
    setEventDate(null);
    setDuties('');
  };

  const loadDraftEvents = (fileDates, fileHours, filePoints, fileDescriptions) => {
    const newEvents = [];
    for (let i = 0; i < fileDates.length; i++) {
      newEvents.push({
        id: i,
        date: new Date(fileDates[i]),
        formattedDate: fileDates[i],
        hours: fileHours[i],
        points: filePoints[i],
        duties: fileDescriptions[i],
      });
    }

    // Merge the previous events with the new events
    const updatedEvents = [...events];

    // Ensure there are no duplicate events by using the date as the unique identifier
    const uniqueEvents = Array.from(new Set(updatedEvents.map((event) => event.date.getTime())))
      .map((time) => updatedEvents.find((event) => event.date.getTime() === time));

    // Sort the uniqueEvents in ascending date order
    uniqueEvents.sort((a, b) => a.date - b.date);

    return uniqueEvents;
  };

  // function to see if file is in the format of attached file in s3 bucket
  function checkForImage(fileName) {
    if(fileName.includes("png") || fileName.includes("pdf") || fileName.includes("jpg") || fileName.includes("jpg"))
      return true;
    else 
      return false;
  }

  async function loadDraftForm() {
    setLoaded(false);
    const formIDandType = { form_id: route.params.draftFormID, form_type: (route.params.is1380AFTP && route.params.is1380AFTP == true) ? '1380 aftp' : '1380' };
    try {
      const fileData = await get_single_file(formIDandType, global.token);
      if (fileData && fileData !== undefined) {
        setSelectedType(fileData.body.duty_performed_9.toUpperCase());
        if (fileData.body.duty_performed_9.toUpperCase() === 'RMA') setSelectedType('ATA');
        setHDIP_ACIP_Authorized(fileData.body.hdip_acip_authorized_9);

        if (fileData.body.reviewer_id !== undefined) {
          const reviewerOfficer = officers.find((officer) => officer.user_id == fileData.body.reviewer_id);
          if (reviewerOfficer) setReviewer(reviewerOfficer);
        }

        switch (fileData.body.duty_performed_9.toUpperCase()) {
          case 'RST':
            setEvents(loadDraftEvents(fileData.body.date_9a, fileData.body.hours_9b, fileData.body.retirement_points_9c, fileData.body.description_9d));
            break;
          // We will never encounter AFTP here because you can't have a Combined1380 of type AFTP (they can't have attachments)
          case 'EBDL':
            setEvents(loadDraftEvents(fileData.body.date_9a, fileData.body.hours_9b, fileData.body.retirement_points_9c, fileData.body.description_9d));
            break;
          case 'ATA':
            setEvents(loadDraftEvents(fileData.body.date_9a, fileData.body.hours_9b, fileData.body.retirement_points_9c, fileData.body.description_9d));
            break;
          case 'MDR':
            setEvents(loadDraftEvents(fileData.body.date_9a, fileData.body.hours_9b, fileData.body.retirement_points_9c, fileData.body.description_9d));
            break;
          default: // Other
            setEvents(loadDraftEvents(fileData.body.date_9a, fileData.body.hours_9b, fileData.body.retirement_points_9c, fileData.body.description_9d));
            const prefix = 'DA 1380-';
            const requestType = fileData.body.request_type;
            setSelectedOtherType(requestType.startsWith(prefix) ? requestType.substring(prefix.length) : null);
            break;
        }

        console.log(uploadFiles)
        if (fileData.body.attachments && fileData.body.attachments.length > 0) {
          const newUploadFiles = [];
          let index = uploadFiles.length;

          console.log("attachments", fileData.body.attachments)
          console.log("index", index)
          for (const currentAttachment of fileData.body.attachments) {
            console.log("newUploadFiles", newUploadFiles)
            if(checkForImage(currentAttachment)){
            // currentAttachment is an outside image or file
            const form_id = (route.params.draftFormID && route.params.draftFormID !== 'NONE') ? route.params.draftFormID : 0;
            const form_type = currentAttachment.split(".")[currentAttachment.split(".").length-1];
            const path = 'additional_documentation_1380s/' + global.user_data.user_id + '/' + form_id + '/' + currentAttachment;
            const content_type = currentAttachment.includes("pdf") ? `application/${form_type}` : `image/${form_type}`;
            const base64 = decodeBase64(await get_image_s3(path, content_type, global.token));

            console.log("form_id", form_id)
            console.log("form_type", form_type)
            console.log("path", path)
            console.log("content_type", content_type)
            console.log("base64", base64)

            newUploadFiles.push({
              form_id: index,
              form_name: currentAttachment,
              form_type: form_type,
              is_image: false,
              base64: base64,
            });
            index++;

            } else {
              // currentAttachment format is like "form_type XXXX"
              const [formType, formId] = currentAttachment.split(' ');
              const formIdKey = formType === 'dental' ? 'dental_form_id' : 'rst_form_id';

              console.log("formId", formId);
              console.log("formType", formType);
              newUploadFiles.push({
                form_id: index,
                form_name: `Pulled ${formType} ${formId}`,
                form_type: formType,
                is_image: false,
                [formIdKey]: formId, // Use dental_form_id for 'dental', rst_form_id otherwise
              });
              index++;
            }
          }
          setUploadFiles(newUploadFiles);
        }
      }
    } catch (error) {
      console.error('Error loading draft form:', error);
    }
    setLoaded(true);
  };

  // useEffect(() => {
  //   if (route.params.draftFormID != 'NONE' && route.params.draftFormID !== undefined) {
  //     loadDraftForm();
  //   }
  // }, [route.params.draftFormID]);

  function validate1380Input() {
    if (!viewSig) return true; // If !viewSig then we're saving a draft and ignoring error handling

    if (global.signature_uri == null || global.signature_uri === '') {
      showSnackbar('error', 'You do not have a signature...');
      return false;
    } else if (reviewer == null || reviewer === '') {
      showSnackbar('error', 'Please select a reviewer');
      setReviewerEmptyError(true);
      return false;
    } else if (validator == null || validator === '') {
      showSnackbar('error', 'Please select a validator');
      return false;
    } else if (events.length == 0) {
      showSnackbar('error', 'Enter at least one event');
      return false;
    } else if (events.length == 8) {
      showSnackbar('error', 'No more than 8 events per submission');
      return false;
    }
    return true;
  }

  const handleSignature = () => {
    setViewSig(!viewSig);
    setDidUserSignDocument(!didUserSignDocument);
  };

  const onSubmit = async () => {
    // Input Validation
    if (validate1380Input() == false) {
      return;
    }

    // Start Loading Circle
    setLoaded(false);

    // Seperate events into date/hours/retirement points/duties arrays
    const date = [];
    const hours = [];
    const retirementPoints = [];
    const duties = [];

    for (const event in events) {
      const currentDate = moment(new Date(events[event].date)).format('MM/DD/YYYY');
      date.push(currentDate);
      hours.push(events[event].hours.toString());
      retirementPoints.push(events[event].points.toString());
      duties.push(events[event].duties);
    }

    // set auth text and selction type
    let authorizationText = 'IAW Reserve Pay Message 22-03, USAR PAM 37-1, AR 140-1, AR 140-185';

    switch (selectedType) {
      case 'RST':
        authorizationText = authorizationText.concat('\nILO ' + await grabMonthFromDate(date[0]?.toString()) + ' Battle Assembly ', iloDate);
        setSelectedType('RST Code 11');
        break;
      case 'aftp':
        setSelectedType('AFTP Code 31');
        break;
      case 'MDR':
        setSelectedType('MDR Code 61');
        break;
      case 'ATA':
        setSelectedType('RMA Code 91');
        break;
      case 'EBDL':
        setSelectedType('EBDL Code 42');
        break;
      case 'other':
        break;
    }

    // Create attachments array using attached images/pdfs and pulled RST or dental form
    const attachments = [];
    const formTypes = ["RST", "EBDL", "RMA", "ATA", "MDR"];
    for (let i = 0; i < uploadFiles.length; i++) {
      if (uploadFiles[i].form_type === 'dental') attachments.push('dental ' + uploadFiles[i].dental_form_id);
      else if (formTypes.includes(uploadFiles[i].form_type)) attachments.push(uploadFiles[i].form_type + ' ' + uploadFiles[i].rst_form_id);
      else if (checkForImage(uploadFiles[i].form_type)) attachments.push(uploadFiles[i].form_name);
    }

    let data = '';

    let requestType;
    if (selectedType === 'other') {
      requestType = selectedOtherType.toUpperCase()
    } else {
      if (selectedType === 'ATA') {
        requestType = 'RMA'
      } else {
        requestType = selectedType.toUpperCase()
      }
    }

    const reviewerName = reviewerSelectedOption?.name && reviewerSelectedOption.name.trim() !== ''
      ? reviewerSelectedOption.name.split(' - ')[0]
      : ' ';

    const validatorNameTemp = validatorSelectedOption?.name && validatorSelectedOption.name.trim() !== ''
      ? validatorSelectedOption.name.split(' - ')[0]
      : ' ';

    data = {
      'creator_id': global.user_data.user_id,
      'comment': '',
      'form_name': global.user_data.rank + ' ' + global.user_data.first_name + ' ' + global.user_data.middle_name + ' ' + global.user_data.last_name,
      'form_type': '1380',
      'request_type': 'DA 1380-' + requestType,
      'reviewer_id': null,
      'reviewer_ids': processReviewers(Array.isArray(reviewer) ? reviewer : [reviewer]),
      'validator_id': showValidatorSig ? validatorSelectedOption?.user_id : null,
      'validator_ids': processReviewers(Array.isArray(validator) ? validator : [validator]),
      'signature_id_11': null,
      'signature_id_12': showValidatorSig && validatorSigId ? validatorSigId : null,
      'recommender_id': null,
      'status': viewSig ? '1' : '0',
      'creation_date_1': moment(new Date()).format('MM/DD/YYYY'),
      'from_2': global.user_data.unit_1380_from,
      'aniversary_year_3': null,
      'to_4': global.user_data.unit_1380_to,
      'name_5': global.user_data.last_name + ' ' + global.user_data.first_name + ' ' + global.user_data.middle_name,
      'grade_6': global.user_data.grade,
      'branch_7': global.user_data.branch,
      'individuals_assigned_organization_8': '',
      'duty_performed_9': requestType,
      'cite_Authorization_9': authorizationText,
      'date_9a': date,
      'hours_9b': hours,
      'retirement_points_9c': retirementPoints,
      'description_9d': duties,
      'aftp_number_9d': null,
      'name_10': reviewerName,
      'name_11': validatorNameTemp,
      'iperms_12': false,
      'pay_data_12': '',
      'attachments': attachments,
      'unit_id': global.user_data.unit_id,
      'hdip_acip_authorized_9': HDIP_ACIP_Authorized ?? false,
      'support_number_9d': [],
      'time_in_9d': [],
      'time_out_9d': [],
      'acft_9d': [],
      'flt_hrs_9d': [],
      'type_of_training_completed_9d': [],
      'bank_time': null,
    };

    // Uploads 1380 or 1380 AFTP to the database
    let res;
    const pulledFromDraft = (route.params.draftFormID && route.params.draftFormID !== 'NONE' && route.params.draftFormID !== undefined);

    if (pulledFromDraft) {
      const updatedFormData = { ...data, form_type: selectedType == 'aftp' ? '1380 aftp' : '1380', form_id: route.params.draftFormID };
      res = await update_file(updatedFormData, global.token); // They are now submitting a loaded draft
    } else res = await upload_file(data, global.token); // They created and submitted a new form in one go

    if ((!pulledFromDraft && res.status !== 200) || (pulledFromDraft && !res.body.includes('successfully updated'))) {
      showSnackbar('error', 'Application Error: Failed to upload the file');
      setLoaded(true);
      return;
    } else {
      if (viewSig && !pulledFromDraft) await sendNotifToReviewer(res.data.body.form_id,);
      // Once everything is updated we need to modify update_file to return the same  structure as upload_file so that we can get res.data.body.form_id and notify the approver when update_file is called too
      setLoaded(true);
      showSnackbar('success', `Form ${viewSig ? 'submitted' : 'drafted'} successfully.`);
      navigation.navigate('Home');
    }

    // Add all attachments: jpegs, pngs, or pdfs (no pulled RSTs/dental forms) to S3 bucket
    const form_id = pulledFromDraft ? route.params.draftFormID : res.data.body.form_id;

    for (let i = 0; i < uploadFiles.length; i++) {
      let contentType = '';
      if (uploadFiles[i].form_type === 'jpg' || uploadFiles[i].form_type === 'jpeg') {
        contentType = 'image/jpeg';
      } else if (uploadFiles[i].form_type === 'png') {
        contentType = 'image/png';
      } else if (uploadFiles[i].form_type === 'pdf') {
        contentType = 'application/pdf';
      } else {
        continue;
      }
      const path = 'users/additional_documentation_1380s/' + global.user_data.user_id + '/' + form_id + '/' + uploadFiles[i].form_name;
      const uri = uploadFiles[i].base64;
      await put_s3(path, uri, contentType, global.token);
    }

    setLoaded(true);
    navigation.navigate('Home');
  };

  const sendNotifToReviewer = async (form_id) => {
        // Send notif to reviewer
    for (let i = 0; i < reviewer?.length; i++) {
      const data = {
        title: '1380 Form',
        body: 'You have one new 1380 form to review',
        receiver_id: reviewer[i].user_id,
        sender_id: global.user_data.user_id,
        notification_type: 'file',
        object_type: 'review 1380 form',
        form_events_id: form_id,
      };
      const res = await create_notification(data, global.token);
      if (res.status !== 200) {
        showSnackbar('error', `Application Error: Failed to send notification to ${reviewer[i].name}`);
      }
    }
        // Send notif to validator
    if(!showValidatorSig){
      for (let i = 0; i < validator?.length; i++) {
        const data = {
          title: '1380 Form',
          body: 'You have one new 1380 form to validate',
          receiver_id: validator[i].user_id,
          sender_id: global.user_data.user_id,
          notification_type: 'file',
          object_type: 'review 1380 form',
          form_events_id: form_id,
        };
        const res = await create_notification(data, global.token);
        if (res.status !== 200) {
          showSnackbar('error', `Application Error: Failed to send notification to ${validator[i].name}`);
        }
      }
    }
    showSnackbar('success', '1380 Form successfully submitted', 'top', 'right', 6000, 400);
  };

  function processReviewers(group) {
    if (group.length > 1) {
      return group.filter(reviewer => typeof reviewer.user_id !== 'string').map(reviewer => reviewer.user_id)
    } else if (group.length == 1) {
      return [group[0].user_id]
    } else {
      return []
    }
  }

  const getSignature = async () => {
    if (global.signature_uri != null) {
      return global.signature_uri;
    }
    return '';
  };

  const viewSignPressed = async () => {
    if ((await getSignature()) != '') {
      setViewSig(true);
    } else {
      showSnackbar('error', 'You do not have a signature.');
    }
  };

  function parseDateString(dateString) {
    if (dateString === 'Invalid date' || dateString === null) {
      return null;
    }

    return dayjs(dateString, 'MM/DD/YYYY');
  }

  const handleAttachmentSelect = async (value) => {
    setFileSelectModal(false);
    const file = value.target.files[0];
    console.log(file)
    if (file) {
      // get the base 64
      const reader = new FileReader();
      reader.onload = async () => {
        const b64 = reader.result;

        const file_type = file.type.split('/')[1];
        const contentType = file.type.includes('jpg') || file.type.includes('jpeg') 
        ? 'image/jpeg' 
        : file.type.includes('png') 
        ? 'image/png' 
        : file.type.includes('pdf') 
        ? 'application/pdf' 
        : null;
        if (!contentType) {
          showSnackbar('error', 'Unsupported file type.');
          return;
        }

        const fileData = {
          form_id: uploadFiles.length,
          form_name: file.name,
          form_type: file_type,
          is_image: file.type.includes('image'),
          base64: b64,
        };

        // Define S3 path
        const path = `users/additional_documentation_1380s/${global.user_data.user_id}/${formID}/${file.name}`;

        // Upload to s3
        try{
          await put_s3(path, b64, contentType, global.token);
          console.log('File uploaded to S3 successfully');

          if (modifyingDoc) {
            const updated = [...uploadFiles];
            updated[modifyingID] = {
              form_id: modifyingID,
              form_name: fileData.form_name,
              form_type: fileData.form_type,
              is_image: fileData.is_image,
              base64: fileData.base64,
            };
            setUploadFiles(updated);
            if (modifyingID === 0) {
              setPulledInfo({});
            }
          } else {
            setUploadFiles((previousFiles) => [
              ...previousFiles,
              fileData,
            ]);
          }
        } catch (error) {
          showSnackbar('error', 'Failed to upload the file to S3.');
          console.error('S3 Upload Error:', error);
        }
      };
      reader.readAsDataURL(file);
    } else {
      showSnackbar('error', 'Something went wrong with file selection.');
    }
  };

  const generateFromAttachment = async (file) => {
    setLoaded(false);
    let formIDandType;
    if (file.form_type === 'dental') {
      formIDandType = { form_id: file.dental_form_id, form_type: file.form_type };
    } else {
      formIDandType = { form_id: file.rst_form_id, form_type: file.form_type };
    }

    try {
      const fileData = await get_single_file(formIDandType, global.token);

      if (file.form_type.toLowerCase() === 'rst' && (Object.keys(fileData.body).length > 0)) {
        await generate_rst(fileData.body.form_id, fileData.body, route.navigation, false, false);
      } else if (file.form_type.toLowerCase() === 'dental' && (Object.keys(fileData.body).length > 0)) {
        await generate_dental(fileData.body.form_id, fileData.body, route.navigation, false, false);
      } else if (file.form_type.toLowerCase() === 'ebdl' && (Object.keys(fileData.body).length > 0)) {
        await generate_ebdl(fileData.body.form_id, fileData.body, route.navigation, false, false);
      } else if (file.form_type.toLowerCase() === 'ata' && (Object.keys(fileData.body).length > 0)) {
        await generate_ata(fileData.body.form_id, fileData.body, route.navigation, false, false);
      } else if (file.form_type.toLowerCase() === 'mdr' && (Object.keys(fileData.body).length > 0)) {
        await generate_mdr(fileData.body.form_id, fileData.body, route.navigation, false, false);
      } else if (file.is_image) {
        const pdfWindow = window.open('');
        pdfWindow.document.write('<img src=\'data:image/' + file.form_type + ';base64,' + file.base64.split(',')[1] + '\'/>');
      }
    } catch (error) {
      console.error('Error generating from attachment:', error);
    }

    setLoaded(true);
  };


  const handleYourFilesSelect = async () => {
    setFileSelectModal(false);
    navigation.push('YourFiles', {
      from1380: true,
      formType: selectedType,
      events: events,
      addedFormIDs: addedFormIDs,
      uploadFiles: uploadFiles,
      modifyDoc: modifyingDoc,
      modifyingID: modifyingID,
      validatorOption: validatorOption,
    });
  };

  function setSelect(id) {
    setSelected(id);
  }

  const viewFilled = async () => {
    const attachments = [];
    for (let i = 0; i < uploadFiles.length; i++) {
      if (uploadFiles[i].form_type === 'RST') {
        attachments.push('RST ' + uploadFiles[i].rst_form_id);
      } else if (uploadFiles[i].form_type === 'dental') {
        attachments.push('dental ' + uploadFiles[i].dental_form_id);
      } else if (uploadFiles[i].form_type === 'EBDL') {
        attachments.push('EBDL ' + uploadFiles[i].rst_form_id);
      } else if (uploadFiles[i].form_type === 'ATA') {
        attachments.push('ATA ' + uploadFiles[i].rst_form_id);
      } else if (uploadFiles[i].form_type === 'MDR') {
        attachments.push('MDR ' + uploadFiles[i].rst_form_id);
      } else if (checkForImage(uploadFiles[i].form_type)){
        attachments.push(uploadFiles[i].form_name);
      }
    }
    let authorizationText =
      'IAW Reserve Pay Message 22-03, USAR PAM 37-1, AR 140-1, AR 140-185';

    const date = [];
    const hours = [];
    const retirementPoints = [];
    const duties = [];
    for (const event in events) {
      const currentDate = moment(new Date(events[event].date)).format(
        'MM/DD/YYYY',
      );
      date.push(currentDate);
      hours.push(events[event].hours.toString());
      retirementPoints.push(events[event].points.toString());
      duties.push(events[event].duties);
    }

    if (selectedType === 'RST') {
      authorizationText = authorizationText.concat(
        '\nILO ' +
        (await grabMonthFromDate(date[0]?.toString())) +
        ' Battle Assembly ',
        iloDate,
      );
    }

    const reviewerName = reviewerSelectedOption?.name && reviewerSelectedOption.name.trim() !== ''
      ? reviewerSelectedOption.name.split(' - ')[0]
      : ' ';

    const validatorNameTemp = validatorSelectedOption?.name && validatorSelectedOption.name.trim() !== ''
      ? validatorSelectedOption.name.split(' - ')[0]
      : ' ';

    const data = {
      creation_date_1: moment(new Date()).format('MM/DD/YYYY'),
      from_2: global.user_data.unit_1380_from,
      aniversary_year_3: null, // Yes, it's spelled with one N for some reason
      to_4: global.user_data.unit_1380_to,
      name_5: `${global.user_data.last_name} ${global.user_data.first_name} ${global.user_data.middle_name}`,
      grade_6: global.user_data.grade,
      branch_7: global.user_data.branch,
      dod: global.user_data.dod,
      mos: global.user_data.mos,
      rank: global.user_data.rank,
      individuals_assigned_organization_8: '',
      cite_Authorization_9: authorizationText,
      name_10: reviewerName,
      name_11: validatorNameTemp,
      signature_id_12: showValidatorSig && validatorSigId ? validatorSigId : '',
      form_id: (route.params.draftFormID && route.params.draftFormID !== 'NONE') ? route.params.draftFormID : '',
      date_9a: date,
      hours_9b: hours,
      retirement_points_9c: retirementPoints,
      description_9d: duties,
      hdip_acip_authorized_9: HDIP_ACIP_Authorized,
      incentive_pay: global.user_data.incentive_pay,
      duty_performed_9: selectedType,
      reviewer_ids: reviewer ? processReviewers(Array.isArray(reviewer) ? reviewer : [reviewer]) : '',
      validator_ids: validator ? processReviewers(Array.isArray(validator) ? validator : [validator]) : '',
    };

    await generate_1380(data.form_id, data, route.navigation, false, false, attachments ?? [], global.user_data.user_id);
  };

  const viewBlank = async () => {
    pdfIn(form_1380.pdf());
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${TableCell.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
      backgroundColor: colors.textfield_background_hover,

    },

    '& td, & th': {
      borderColor: colors.border_color,
    },
  }));

  const StyledTableHeaderRow = styled(TableRow)(({ theme }) => ({
    '& td, & th': {
      border: 'none',
    },
  }));

  const loadingStyles = {
    animation: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      paddingVertical: 20,
      background: colors.screen_background,
    },
  };

  return (
    <View style={styles.container}>
      {!loaded ? (
        <View style={loadingStyles.animation}>
          <Progress.CircleSnail
            color={colors.loading_circle}
            indeterminate={true}
            size={275}
            thickness={8}
          />
        </View>
      ) : (
        <Scrollbars style={styles.scrollView}>
          <View style={styles.mainWrapper}>
            <Text style={styles.pageTitle}>New 1380</Text>
            <Text style={styles.description}>
              Fill out a 1380 form. Some of the information has already been pulled from {importType} for your convenience.
            </Text>

            {pulled && (
              <View style={{ width: '100%', textAlign: 'center', marginTop: '2%', marginBottom: '2%' }}>
                <Text style={styles.textStyleNormal}>
                  Importing from: {importType.toUpperCase()} {'\n'}
                  {!(['ATA', 'EBDL', 'MDR', 'dental'].includes(importType)) && (
                    <>
                      In lieu of B.A. Date(s)(MUTAs):
                    </>
                  )}
                </Text>
                <Text style={styles.textStyle}>
                  {iloDate.split(', ').map((date) => (date + '\n'))}
                </Text>
              </View>
            )}
            <View style={{ flexDirection: isColumn ? 'column' : 'row', paddingLeft: '5%', paddingRight: '5%' }}>
              <View style={[styles.contentContainer, { justifyContent: 'center', marginBottom: '3%' }]}>
                <View style={styles.entryBox}>
                  <View style={{ alignItems: 'center', marginTop: '1%' }}>
                    <Text style={[styles.textStyle, { marginBottom: '3%' }]}>{'To be Performed On*:'}</Text>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <ThemeProvider theme={payTheme} style={{ borderColor: 'red' }}>
                        <ClickAwayListener
                          onClickAway={(event) => {
                            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                              setDatePickerOpen(false);
                            }
                          }}
                          mouseEvent="onMouseDown"
                        >
                          <DatePicker
                            label='To be Performed On'
                            ampm={false}
                            onChange={(date) => {
                              setEventDate(date);
                              setFormattedEventDate(date ? moment(new Date(date)).format('MM/DD/YYYY') : '');
                              settoBePerformedOnDatesEmptyError(date === null || !date.isValid());
                              setAlreadyUsedDateError(events.some((event, idx) => event.formattedDate === moment(new Date(date)).format('MM/DD/YYYY') && (!modifying || (modifying && idx !== selected))));
                            }}
                            value={eventDate}
                            error={toBePerformedOnDatesEmptyError || alreadyUsedDateError}
                            onClose={() => setDatePickerOpen(false)}
                            onOpen={() => setDatePickerOpen(true)}
                            open={datePickerOpen}
                            slotProps={{
                              textField: {
                                sx: {
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: colors.border_color,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: colors.border_color,
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: colors.border_color,
                                    },
                                  },
                                },
                                borderColor: 'red',
                                error: toBePerformedOnDatesEmptyError || alreadyUsedDateError,
                              },
                            }}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: 'eventListeners',
                                  options: {
                                    scroll: false,
                                    resize: true,
                                  },
                                },
                              ],
                              ref: (popperRef) => {
                                if (popperRef && popperRef.node) {
                                  popperRef.node.id = 'calendar-element';
                                  datePickerRef.current = popperRef.node.querySelector('.MuiCalendarPicker-root');
                                }
                              },
                            }}
                          />
                        </ClickAwayListener>
                      </ThemeProvider>
                    </LocalizationProvider>
                    <HelperText style={{ color: 'red' }} visible={toBePerformedOnDatesEmptyError || alreadyUsedDateError}>{toBePerformedOnDatesEmptyError ? 'Enter to be performed on date' : 'This date is already selected'}</HelperText>
                  </View>


                  <HoursAndPoints />
                  <View
                    style={[styles.hoursPointsInput]}
                  >
                    <TextInput
                      activeOutlineColor={colors.inputted_text}
                      outlineColor={colors.inputted_text}
                      theme={{
                        colors: {
                          text: colors.inputted_text,
                          accent: colors.textfield_background,
                          primary: colors.inputted_text,
                          placeholder: colors.placeholder_text,
                        },
                      }}
                      style={[styles.inputSplit, { marginRight: '2.5%' }]}
                      onChangeText={(text) => {
                        setHours(text.replace(/[^0-9]/g, ''));
                        sethoursEmptyError(false);
                      }}
                      error={hoursEmptyError}
                      value={(selected > -1 && events[selected] && events[selected].hours == '1') ? '1' : hours}
                      label="Number of hour(s)*"
                      mode="outlined"
                      disabled={locked}
                    />
                    <TextInput
                      activeOutlineColor={colors.inputted_text}
                      outlineColor={colors.inputted_text}
                      theme={{
                        colors: {
                          text: colors.inputted_text,
                          accent: colors.textfield_background,
                          primary: colors.inputted_text,
                          placeholder: colors.placeholder_text,
                        },
                      }}
                      style={[styles.inputSplit, { marginLeft: '2.5%' }]}
                      onChangeText={(text) => {
                        setPoints(text);
                        setpointsEmptyError(false);
                      }}
                      value={points}
                      error={pointsEmptyError}
                      label="Retirement points*"
                      mode="outlined"
                      disabled={locked}
                    />
                  </View>

                  {/* <HelperText style={{ color: 'red' }} visible={hoursEmptyError || pointsEmptyError}>{"Enter number of hours/retirement points"}</HelperText> */}
                  <View style={{ width: '95%', alignItems: 'center' }}>
                    <TextInput
                      style={styles.commentInput}
                      activeOutlineColor={colors.inputted_text}
                      theme={{
                        colors: {
                          text: colors.inputted_text,
                          accent: colors.textfield_background,
                          primary: colors.inputted_text,
                          placeholder: colors.placeholder_text,
                        },
                      }}
                      outlineColor={colors.inputted_text}
                      label="Location/Nature of duties, training, or instruction*"
                      placeholder=''
                      multiline={true}
                      value={duties}
                      onChangeText={handleDutiesChange}
                      onBlur={() => {
                        setdutiesEmptyError(duties === '');
                      }}
                      error={dutiesEmptyError}
                    />
                    <HelperText style={{ color: 'red', alignSelf: 'center', marginBottom: dutiesEmptyError ? '1.5%' : '0%' }} visible={dutiesEmptyError}>{'Enter location/nature of duties performed'}</HelperText>
                  </View>


                  <TouchableOpacity style={[styles.cancelButton, { backgroundColor: colors.content_box, marginBottom: '3%', width: '22%' }]}
                    onPress={() => {
                      if (modifyingNthEntry) {
                        modifyNthEntryEvent(selected);
                      } else if (modifying) {
                        addEvent();
                      } else {
                        addEvent();
                      }
                    }}>
                    <Text style={styles.textStyleNormal}>{(indexBeingEditedInSequence !== -1 || selected !== -1) ? 'Modify Entry' : 'Add Entry'}</Text>
                  </TouchableOpacity>
                </View>
              </View>

              <View style={styles.contentContainer}>
                <View style={{ width: selectedType === 'aftp' && hours === '8' ? '49%' : '100%', height: '47px', marginBottom: '2.5%', zIndex: 4000, marginTop: selectedType === 'aftp' && hours === '8' ? '2%' : '0%' }}>
                  <CustomAutocomplete
                    placeholderText={'Officer Knowledge Duties Performed*'}
                    permission={selectedType == 'AFTP' || selectedType === 'aftp' ? 32 : 29}
                    selectedOption={Array.isArray(reviewer) ? reviewer : [reviewer]}
                    setSelectedOption={setReviewer}
                    error={reviewerEmptyError}
                    setError={setReviewerEmptyError}
                    reviewerIds={reviewerIds}
                    validatorIds={[]}
                    manualOption={null}
                    setValidatorOption={null}
                    setSelectedValidator={null}
                    setReviewerOption={setReviewerSelectedOption}
                    isValidator={false}
                    resetSearchQuery={{ reset: false, form_ids: [] }}
                    from={"New1380"}
                    zIndex={3000}
                  />
                </View>
                <View style={{ backgroundColor: colors.screen_background, zIndex: 1000 }}>
                  <FormHelperText style={{
                    color: 'red',
                    marginTop: reviewerEmptyError ? '-15%' : '',
                    marginBottom: reviewerEmptyError ? '5%' : '',
                  }}>
                    {reviewerEmptyError ? "Select a Approver" : ""}</FormHelperText>
                </View>
                <View style={{ width: selectedType === 'aftp' && hours === '8' ? '49%' : '100%', height: '47px', marginBottom: '2.5%', zIndex: 2000, marginTop: selectedType === 'aftp' && hours === '8' ? '2%' : '0%' }}>
                  <CustomAutocomplete
                    placeholderText={`Validator of Approved Additional Training*`}
                    permission={41}
                    selectedOption={Array.isArray(validator) ? validator : [validator]}
                    setSelectedOption={setValidator}
                    error={validatorEmptyError}
                    setError={setValidatorEmptyError}
                    reviewerIds={[]}
                    validatorIds={validatorIds}
                    setManualOption={setValidatorOption}
                    manualOption={validatorOption?.length > 0 ? validatorOption : null}
                    setReviewerOption={setValidatorSelectedOption}
                    setValidatorSig={setValidatorSigId}
                    setShowValidatorSig={setShowValidatorSig}
                    isValidator={true}
                    resetSearchQuery={resetSearchQuery}
                    from={"New1380"}
                    zIndex={1000}
                  />
                </View>
                <View style={{ backgroundColor: colors.screen_background, zIndex: 1000 }}>
                  <FormHelperText style={{
                    color: 'red',
                    marginTop: validatorEmptyError ? '-15%' : '',
                    marginBottom: validatorEmptyError ? '5%' : '',
                  }}>
                    {validatorEmptyError ? "Select a validator" : ""}</FormHelperText>
                </View>

                <View style={{ backgroundColor: colors.screen_background, zIndex: 1000, width: '100%' }}>
                  <View style={{ width: '100%', alignItems: 'center' }}>
                    <View style={[styles.tableDiv]}>
                      <TableContainer
                        component={Paper}
                        style={{
                          height: 'fit-content',
                          paddingTop: '1%',
                          backgroundColor: colors.textfield_background,
                        }}
                        error={canSubmit}
                      >
                        <Table>
                          <TableHead>
                            <StyledTableHeaderRow style={{ color: colors.text }}>
                              <StyledTableCell
                                style={{
                                  color: colors.inputted_text,
                                  fontSize: '.8rem',
                                  borderColor: events.length !== 0 ? colors.border_color : '',
                                }}
                              >
                                Event Date
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  color: colors.inputted_text,
                                  fontSize: '.8rem',
                                  borderColor: events.length !== 0 ? colors.border_color : '',
                                }}
                              >
                                Number of Hours
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  color: colors.inputted_text,
                                  fontSize: '.8rem',
                                  borderColor: events.length !== 0 ? colors.border_color : '',
                                }}
                              >
                                Retirement Points
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  color: colors.inputted_text,
                                  fontSize: '.8rem',
                                  borderColor: events.length !== 0 ? colors.border_color : '',
                                }}
                              >
                                Description
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  color: colors.inputted_text,
                                  fontSize: '.8rem',
                                  borderColor: events.length !== 0 ? colors.border_color : '',
                                }}
                              ></StyledTableCell>
                            </StyledTableHeaderRow>
                          </TableHead>
                          <TableBody>
                            {events.map((event, index) => {
                              return (
                                <StyledTableRow key={event.id} style={{ backgroundColor: (indexBeingEditedInSequence == index || selected == index) ? colors.textfield_background_hover : 'transparent' }}>
                                  <StyledTableCell style={{ color: colors.text }}>
                                    {event.formattedDate}
                                  </StyledTableCell>
                                  <StyledTableCell style={{ color: colors.text }}>
                                    {event.hours}
                                  </StyledTableCell>
                                  <StyledTableCell style={{ color: colors.text }}>
                                    {event.points}
                                  </StyledTableCell>
                                  <StyledTableCell style={{ color: colors.text }}>
                                    {event.duties}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <View style={{ flexDirection: 'row' }}>
                                      <TouchableOpacity
                                        onPress={() => {
                                          if (indexBeingEditedInSequence == index || selected == index) {
                                            setIndexBeingEditedInSequence(-1);
                                            setEventDate(null);
                                            setFormattedEventDate('');
                                            setHours('');
                                            setPoints('');
                                            setDuties('');
                                            setCheckbox4H(false);
                                            setCheckbox8H(false);
                                            setHDIP_ACIP_Authorized(userHasIncentivePay);
                                            setEntryExists(true);
                                            setModifying(false);
                                            setModifyingNthEntry(false);
                                            setSelected(-1);
                                          } else {
                                            setModifyingNthEntry(false);
                                            setModifying(true);
                                            setEventDate(event.formattedDate === 'TBD' ? null : event.date);
                                            modifyEntry(index);
                                            setSelect(index);
                                            setIndexBeingEditedInSequence(-1);
                                          }
                                        }}
                                        style={{ marginRight: 30 }}
                                      >
                                        <Text style={{ color: colors.text }}>Edit</Text>
                                      </TouchableOpacity>
                                      <TouchableOpacity onPress={() => {
                                        deleteEntry(index);
                                      }
                                      }>
                                        <Text style={{ color: colors.delete }}>Delete</Text>
                                      </TouchableOpacity>
                                    </View>
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </View>
                    <HelperText style={{ color: 'red', borderWidth: 0 }} visible={!canSubmit}>{'Finish filling out all entries'}</HelperText>
                  </View>
                </View>
              </View>
            </View>


            <Text style={[styles.signText, { width: '60%', marginTop: '2%', marginBottom: '2%' }]}>
              By clicking on the "Submit" button below, you consent to your
              digital signature being affixed to the document. Your signature can
              be viewed below after clicking the "Sign Document" button. If you
              don't have a signature, please add one below.
            </Text>

            <View style={{ width: '100%', textAlign: 'center', marginTop: '1%', alignItems: 'center' }}>
              {doesUserHaveSignature == true ? (
                <>
                  <TouchableOpacity
                    style={[styles.signDocButton, { backgroundColor: colors.content_box }]}
                    onPress={handleSignature}
                  >
                    <Text style={{ justifyContent: 'center', fontSize: 15, color: colors.text, fontWeight: 'bold' }}>
                      {viewSig ? 'Document Signed' : 'Sign Document'}
                    </Text>
                  </TouchableOpacity>
                  {viewSig && (
                    <>
                      <Image
                        resizeMode={'contain'}
                        style={{ width: 200, height: 100, backgroundColor: 'white', marginRight: '.9%', marginTop: '1%' }}
                        source={{ uri: global.signature_uri }}
                      />
                    </>
                  )}
                </>
              ) : (
                <View style={{ width: '25%', justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={[styles.textStyle, { padding: 15 }]}>No signature found</Text>

                  <TextInput
                    style={[styles.commentInput, { width: '95%' }]}
                    theme={{
                      colors: {
                        text: colors.inputted_text,
                        accent: colors.textfield_background,
                        primary: colors.inputted_text,
                        placeholder: colors.placeholder_text,
                      },
                    }}
                    label="Enter your first and last name"
                    placeholder=""
                    value={signatureName}
                    onChange={(data) => setSignatureName(data.target.value)}
                  />

                  <ViewShot ref={viewRef} style={{ padding: 10, marginVertical: 10, backgroundColor: 'white' }} options={{ format: 'png', quality: 1 }}>
                    <Text style={{ color: 'black' }}>{signatureName}</Text>
                  </ViewShot>

                  <TouchableOpacity style={[styles.cancelButton, { backgroundColor: colors.modal_background, marginBottom: '3%', width: '25%' }]} onPress={captureTextAsImage}>
                    <Text style={styles.textStyle}>Create Signature</Text>
                  </TouchableOpacity>
                </View>
              )}
              {importType == 'RST' && userHasIncentivePay && (<View style={[styles.checkboxContainer, { marginTop: '15px', marginBottom: '15px', justifyContent: 'center', textAlign: 'center'}]}>
                <CustomRadioWithBackground
                  id="HDIP/ACIP Button"
                  checked={HDIP_ACIP_Authorized == true}
                  onChange={handleHDIP}
                  onClick={handleHDIP}
                  label="HDIP/ACIP"
                />
              </View>)}
              <Text style={styles.textStyle} onClick={() => console.log(uploadFiles)}>Attachments (Click to view): {'\n'}</Text>
              <View>
                {uploadFiles.map((file) => (
                  <View key={file.form_id} style={styles.fileContainer}>
                    <View style={styles.fileInfo}>
                      <TouchableOpacity onPress={() => generateFromAttachment(file)}>
                        <Text style={[styles.textStyleNormal, { marginRight: 10 }]}>
                          File Name: {file.form_name}{'\n'}
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      {(uploadFiles.length < 2) &&
                        <TouchableOpacity onPress={() => modifyDoc(file.form_id)}>
                          <Text style={[styles.textStyleNormal, { marginRight: 10 }]}>Modify </Text>
                        </TouchableOpacity>
                      }
                      <TouchableOpacity onPress={() => deleteDoc(file)}>
                        <Text style={[styles.textStyleNormal, { marginRight: 10, color: colors.delete }]}>Delete</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                ))}
              </View>
            </View>

            <View style={[styles.rowContainer]}>
              <TouchableOpacity style={[styles.cancelButton, { backgroundColor: colors.button_cancel, marginLeft: '2%', marginRight: '2%' }]} onPress={onCancel}>
                <Text style={styles.textStyle}>Cancel</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.cancelButton, !isColumn && { marginLeft: '2%', marginRight: '2%' }]}
                onPress={viewBlank}
              >
                <Text style={styles.textStyle}>View Blank 1380</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={[styles.cancelButton, !isColumn && { marginLeft: '2%', marginRight: '2%' }]}
                onPress={viewFilled}
              >
                <Text style={styles.textStyle}>View Filled 1380</Text>
              </TouchableOpacity>

              {true && (
                <TouchableOpacity
                  style={[styles.cancelButton, !isColumn && { marginLeft: '2%', marginRight: '2%' }]}
                  onPress={() => setFileSelectModal(true)}
                >
                  <Text style={styles.textStyle}>
                    Add Document
                  </Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                style={[styles.button, { backgroundColor: viewSig ? colors.button_submit : colors.button_draft, marginLeft: '2%', marginRight: '2%' }]}
                onPress={checkEntries}
              >
                <Text style={styles.textStyle}>{viewSig ? 'Submit' : 'Save Draft'}</Text>
              </TouchableOpacity>

              {/* Modal for adding an attachment */}
              <View>
                <Modal
                  animationType="fade"
                  transparent={true}
                  visible={fileSelectModal}
                  onRequestClose={() => {
                    setFileSelectModal(false);
                  }}
                >
                  <View style={{ height: '100%', width: '100%', paddingLeft: '14%', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <View
                      style={{
                        position: 'absolute',
                        backgroundColor: colors.modal_background,
                        borderColor: colors.border_color,
                        borderWidth: 1,
                        borderRadius: 20,
                        alignItems: 'center',
                        shadowColor: '#000',
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.25,
                        shadowRadius: 4,
                        elevation: 5,
                        width: '30%',
                        justifyContent: 'center',
                        alignSelf: 'center',
                        height: '40%',
                        paddingHorizontal: 15,
                        paddingVertical: 10,
                      }}
                    >
                      <Text style={styles.modalText}>
                        Select A File{'\n'}
                      </Text>
                      <Text
                        style={[styles.textStyleNormal, { marginBottom: 20 }]}
                      >
                        File types can be .jpg, .jpeg, .png and .pdf
                      </Text>

                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={handleAttachmentSelect}
                        style={{ color: colors.text, marginBottom: 5 }}
                      />

                      {selectedType !== 'aftp' && (<TouchableOpacity
                        style={[styles.button, { backgroundColor: colors.content_box, width: '12vw' }]}
                        onPress={handleYourFilesSelect}
                      >
                        <Text style={styles.textStyleNormal}>
                          {' '}
                          Select from Your Files{' '}
                        </Text>
                      </TouchableOpacity>)}

                      <TouchableOpacity
                        style={[styles.cancelButton, { backgroundColor: colors.modal_background, width: '12vw' }]}
                        onPress={() => {
                          if (modifyingID !== -1) {
                            setModifyingID(-1);
                            setModifyingDoc(false);
                          }

                          setFileSelectModal(!fileSelectModal);
                        }}
                      >
                        <Text style={styles.textStyleNormal}>Cancel</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
              </View>
            </View>
          </View>
        </Scrollbars>
      )}


    </View>
  );
}

export default CreateCombined1380;
